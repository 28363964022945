import type { UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import { IconTokenAiri, IconTokenOrai, IconTokenUsdt } from '@/assets';

export enum TokenId {
  ORAI = 'oraichain-token',
  AIRI = 'airight',
  USDT = 'tether',
}

export enum TokenDenom {
  ORAI = 'ORAI',
  AIRI = 'AIRI',
  USDT = 'USDT',
  CUPI = 'CUPI',
}

export const oraichainTokens = [
  {
    denom: TokenDenom.ORAI,
    tokenId: TokenId.ORAI,
    icon: IconTokenOrai,
  },
  {
    denom: TokenDenom.AIRI,
    tokenId: TokenId.AIRI,
    icon: IconTokenAiri,
  },
  {
    denom: TokenDenom.USDT,
    tokenId: TokenId.USDT,
    icon: IconTokenUsdt,
  },
];

export type TokenPrices<T extends string> = {
  [C in T]: number | null;
};

export const buildToekenPricesURL = (tokenIds: readonly string[]): string =>
  `https://price.market.orai.io/simple/price?ids=${tokenIds.join('%2C')}&vs_currencies=usd`;

export const useTokenPrices = <T extends string>(
  options: Omit<
    UseQueryOptions<TokenPrices<T>, unknown, TokenPrices<T>, string[]>,
    'queryKey' | 'queryFn'
  > = {},
): UseQueryResult<TokenPrices<T>, unknown> => {
  const tokens = oraichainTokens.map((t) => t.tokenId);

  return useQuery({
    ...options,
    queryKey: ['tokenPrices', ...tokens],
    queryFn: async ({ signal }) => {
      const tokenPricesURL = buildToekenPricesURL(tokens);

      const prices: any = {};

      try {
        const resp = await fetch(tokenPricesURL, { signal });
        const rawData: any = (await resp.json()) as {
          [C in T]?: {
            usd: number;
          };
        };
        // update cached
        for (const key in rawData) {
          prices[key] = rawData[key].usd;
        }
      } catch (error) {
        console.log('ERR get token prices: ', error);
      }

      return Object.fromEntries(tokens.map((token) => [token, prices[token]])) as TokenPrices<T>;
    },
  });
};
