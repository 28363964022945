import moment from 'moment';

export const getFormatDateDisplay = (dateString: string) => {
  const date = new Date(dateString);
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  return `${date.getDate()} ${monthNames[date.getMonth()]} ${date.getFullYear()}`;
};

export const formattedDate = (date: string) => {
  return moment(date).format('DD MMM YYYY');
};

export const getDurationAsTimeFromNow = (startDate: any) => {
  const now = moment(new Date()); // todays date
  const end = moment(startDate); // another date
  const duration = moment.duration(now.diff(end)).asSeconds();
  const days = Math.floor(duration / (3600 * 24));

  const hours = Math.floor((duration % (60 * 60 * 24)) / (60 * 60));

  const minutes = Math.floor((duration % (60 * 60)) / 60);

  const seconds = Math.floor(duration % 60);

  if (days > 0) {
    return `${days} days ago`;
  }

  if (hours > 0) {
    return `${hours} hours ago`;
  }
  if (minutes > 0) {
    return `${minutes} minutes ago`;
  }
  if (seconds > 0) {
    return `${seconds} seconds ago`;
  }

  if (seconds === 0) {
    return `Just now`;
  }

  return null;
};

export const formatMMDD_YYYYHM = (dateString: string, displayMidday = true) => {
  const date = new Date(dateString);
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const formattedDate = `${
    monthNames[date.getMonth()]
  } ${date.getDate()}, ${date.getFullYear()} ${date.getHours()}:${date
    .getMinutes()
    .toString()
    .padStart(2, '0')}  ${displayMidday ? (date.getHours() >= 12 ? 'PM' : 'AM') : ''}`.trim();
  return formattedDate;
};
