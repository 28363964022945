'use client';

import type { ImageProps } from '@nextui-org/react';
import { Image, Skeleton } from '@nextui-org/react';
import Lottie from 'lottie-react';
import React, { useEffect, useState } from 'react';
import LazyLoad from 'react-lazyload';

import {
  error_fallback_base,
  loading_fallback_base,
  LogoImage1,
  not_found,
} from '@/assets';
import ViewFullMedia from './view-media';

interface ImageSProps extends ImageProps {
  alt: string;
  src: any;
  isFallbackLoading?: boolean;
  className?: string;
  imageWrapper?: string;
  showLottieSearch?: boolean;
  overlay?: boolean;
  zoom?: boolean;
  onClick?: () => void;
  skeleton?: boolean;
  skeletonClassName?: string;
}

const ImageS: React.FC<ImageSProps> = ({
  alt,
  src,
  className = 'h-full w-full',
  isFallbackLoading = false,
  imageWrapper = '',
  showLottieSearch = false,
  overlay,
  zoom = false,
  onClick,
  skeleton = false,
  skeletonClassName = '',
  ...props
}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [jsonFile, setJsonFile] = useState();
  const [isOpen, setIsOpen] = useState(false);

  // const screenWidth = useGetWidth();

  const readUrlJson = async () => {
    const json = await fetch(src).then((response) => response.json());
    return setJsonFile(json);
  };

  useEffect(() => {
    if (src && src.includes('json')) {
      readUrlJson();
    }
    setLoading(true);
    setError(false);
  }, [src]);

  return (
    <div
      aria-expanded={(loading && isFallbackLoading) || error || !src}
      className={`h-full w-full aria-expanded:relative ${imageWrapper}`}
    >
      {!src &&
        (showLottieSearch ? (
          <Lottie
            className="absolute left-1/2 top-1/2 h-full min-h-[200px] w-full -translate-x-1/2 -translate-y-1/2"
            animationData={not_found}
            loop
          />
        ) : (
          <LogoImage1 className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />
        ))}

      {isFallbackLoading &&
        src &&
        (skeleton ? (
          <Skeleton
            aria-hidden={loading}
            className={`mb-3 hidden h-10 min-h-[250px] w-full rounded-[10px] aria-hidden:block ${skeletonClassName}`}
          />
        ) : (
          <Lottie
            aria-hidden={loading}
            className="absolute left-1/2 top-1/2 hidden h-[calc(100%/2)] min-h-[200px] -translate-x-1/2 -translate-y-1/2 aria-hidden:block"
            animationData={loading_fallback_base}
            loop
          />
        ))}

      {error && (
        <Lottie
          aria-hidden={error}
          className="absolute left-1/2 top-1/2 hidden h-full min-h-[200px] w-full -translate-x-1/2 -translate-y-1/2 aria-hidden:block"
          animationData={jsonFile ?? error_fallback_base}
          loop
        />
      )}

      {src &&
        (props.loading === 'lazy' ? (
          <LazyLoad height={200} className="h-full w-full" offset={200} once>
            <Image
              onClick={() => {
                if (zoom) {
                  setIsOpen(true);
                }
                if (onClick) {
                  onClick();
                }
              }}
              aria-disabled={overlay}
              disableSkeleton
              classNames={{ wrapper: 'h-full w-full !rounded-none !max-w-full static' }}
              aria-hidden={(loading && isFallbackLoading) || error}
              alt={alt}
              className={`h-full w-full rounded-none aria-hidden:!opacity-0 ${className} aria-disabled:blur-[25px]`}
              src={src}
              onError={() => {
                setError(true);
              }}
              onLoad={() => {
                setLoading(false);
              }}
              {...props}
            />
          </LazyLoad>
        ) : (
          <Image
            onClick={() => {
              if (zoom) {
                setIsOpen(true);
              }
              if (onClick) {
                onClick();
              }
            }}
            aria-disabled={overlay}
            disableSkeleton
            classNames={{ wrapper: 'h-full w-full !rounded-none !max-w-full static' }}
            aria-hidden={(loading && isFallbackLoading) || error}
            alt={alt}
            className={`h-full w-full rounded-none aria-hidden:!opacity-0 ${className} aria-disabled:blur-[25px]`}
            src={src}
            onError={() => {
              setError(true);
            }}
            onLoad={() => {
              setLoading(false);
            }}
            {...props}
          />
        ))}
      {isOpen && (
        <ViewFullMedia 
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          url={src}
        />
      )}
    </div>
  );
};

export default ImageS;
