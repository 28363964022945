import React from 'react';

interface TagProps {
  text: string;
}

const Tag: React.FC<
  TagProps &
    React.DetailedHTMLProps<React.HTMLAttributes<HTMLParagraphElement>, HTMLParagraphElement>
> = ({ text }) => {
  return (
    <p className="m-0 mb-2 mr-2 rounded-full bg-blue-50 px-3 py-1 text-14 font-normal text-blue-500">
      #{text}
    </p>
  );
};

export default Tag;
