import type { ButtonProps } from '@nextui-org/react';
import type { ReactNode } from 'react';
import React from 'react';

import Buttons from '@/components/button/Button';

type Types =
  | 'primary'
  | 'secondary'
  | 'default'
  | 'success'
  | 'warning'
  | 'danger'
  | 'ghost'
  | 'disabled'
  | 'cancel'
  | 'white';
interface ButtonsProps {
  children: ReactNode;
  className?: string;
  typeBtn?: Types;
}

const ButtonIcon: React.FC<ButtonsProps & ButtonProps> = ({
  children,
  typeBtn = 'cancel',
  className = '',
  ...props
}) => {
  return (
    <Buttons className={`z-30 m-0 h-fit min-w-0 p-0 ${className}`} typeBtn={typeBtn} {...props}>
      {children}
    </Buttons>
  );
};

export default ButtonIcon;
