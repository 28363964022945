import type { StdFee } from '@cosmjs/stargate';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { configs } from '@/configs';
import { network } from '@/configs/network';
import type { InputExecuteSSO } from '@/libs/ssoWallet';
import { ActionSSO, ssoExecute } from '@/libs/ssoWallet';
import { usePrompt } from '@/provider';
import { useSocket } from '@/provider/SocketProvider';
import { useAuth } from '@/provider/UserProvider';
import { getRoyaltyFromInput } from '@/utils';
import {
  getMessageExecuteSellingTransaction721WithCw20Token,
  getMessageExecuteSellingTransaction1155WithCw20Token,
} from '@/utils/transactionMessage';
import { handleExecute, handleExecuteGrant } from '@/utils/wasm';

const MESS_FINISH = 'Sell artwork successfully!';

const useSellArtwork = ({
  processId,
  onClose,
  callback,
}: {
  processId?: number;
  onClose: () => void;
  callback?: () => void;
}) => {
  const { auth } = useAuth();
  const { socket } = useSocket();
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState('');
  const [finish, setFinish] = useState('');

  const { setIsSold, listSold } = usePrompt();

  const handleExecuteWithOwallet = async ({
    marketplace,
    message,
    additionalInfor = {},
    grant,
  }: {
    marketplace: string;
    message: string;
    additionalInfor?: object;
    grant?: StdFee;
  }) => {
    setLoading(true);
    if (grant) await handleExecuteGrant({ contract: marketplace, grant, msg: message });
    else await handleExecute(marketplace, auth?.user?.publicAddress, message, additionalInfor);
  };

  const handleExecuteWithSSO = async (marketplace: string, message: string, fee?: StdFee) => {
    const input: InputExecuteSSO = {
      contractAddress: marketplace,
      chainId: network?.chainId || 'Oraichain',
      params: message,
    };
    if (fee) input.fee = fee;
    setLoading(true);
    await ssoExecute(input, ActionSSO.sellArtwork);
  };

  const checkGas = (gas: number) => {
    if (gas > 4000000 || gas < 400000) {
      toast.error('Gas number must be between 400,000 and 4,000,000');
    }
  };

  const convertAmount = (amount: string) => (parseFloat(amount) * 10 ** 6).toString();

  const handleSellArtwork = async ({
    assetId,
    dataMessage,
    is1155,
  }: {
    assetId: string;
    dataMessage: {
      amount: string;
      royaltyAmount: string;
      gas: number;
      fees: number;
      quantity: number;
    };
    is1155?: boolean;
  }) => {
    const { amount, royaltyAmount, gas, fees, quantity } = dataMessage;
    listenBuyingEvent(assetId);

    checkGas(gas);

    const { ow721, marketplace, marketplace1155, ow1155 } = window.Wasm.contractAddresses;
    const currentAmount = convertAmount(amount);
    const inputRoyalty = getRoyaltyFromInput(royaltyAmount);

    const message = is1155
      ? getMessageExecuteSellingTransaction1155WithCw20Token({
          owner: ow1155 || '',
          amount: currentAmount,
          tokenId: assetId,
          tokenContract: configs.airiTokenContract,
          quantity: quantity.toString(),
        })
      : getMessageExecuteSellingTransaction721WithCw20Token({
          owner: ow721 || '',
          amount: currentAmount,
          royalty: inputRoyalty,
          tokenId: assetId,
          tokenContract: configs.airiTokenContract,
        });
    const contract = is1155 ? marketplace1155 : marketplace;
    const additionalInfor = {
      gas,
      fees,
      mode: 'BROADCAST_MODE_BLOCK',
    };

    try {
      if (auth.isOwallet) {
        await handleExecuteWithOwallet({
          marketplace: contract || '',
          message,
          additionalInfor,
        });
      } else {
        await handleExecuteWithSSO(contract || '', message);
      }
    } catch (error: any) {
      console.log('error selling: ', error);
      setLoading(false);
      toast.error(error?.message);
    }
  };

  const handleSellArtworkWithGrant = async (
    assetId: string,
    dataMessage: {
      amount: string;
      royaltyAmount: string;
      gas: number;
    },
    fee: StdFee,
  ) => {
    const { amount, royaltyAmount, gas } = dataMessage;
    listenBuyingEvent(assetId);

    checkGas(gas);

    const { ow721, marketplace } = window.Wasm.contractAddresses;
    const currentAmount = convertAmount(amount);
    const inputRoyalty = getRoyaltyFromInput(royaltyAmount);

    const message = getMessageExecuteSellingTransaction721WithCw20Token({
      owner: ow721 || '',
      amount: currentAmount,
      royalty: inputRoyalty,
      tokenId: assetId,
      tokenContract: configs.airiTokenContract,
    });

    try {
      if (auth.isOwallet) {
        await handleExecuteWithOwallet({
          marketplace: marketplace || '',
          message,
          grant: fee,
        });
      } else {
        await handleExecuteWithSSO(marketplace || '', message, fee);
      }
    } catch (error: any) {
      console.log('error selling: ', error);
      setLoading(false);
      toast.error(error?.message);
    }
  };

  const listenBuyingEvent = (assetId: string) => {
    if (!socket) return;
    const event = `sell-${assetId}`;
    socket.on(event, () => {
      socket.removeListener(event);
      setLoading(false);
      if (callback) callback();
      setFinish(MESS_FINISH);
      setTimeout(() => {
        onClose();
      }, 100);
    });
  };

  // for sso
  useEffect(() => {
    if (typeof window !== 'undefined' && !auth.isOwallet) {
      window.addEventListener(
        'message',
        (event) => {
          if (event.origin !== configs.ssoUrl) return;
          const data = JSON.parse(event?.data || '');

          if (data.action !== ActionSSO.sellArtwork) return;
          if (data.status !== 'success') {
            console.log('err sell: ', data.response);
            setErr(data.response);
          }
        },
        false,
      );
    }
    return window.removeEventListener('message', () => {});
  }, []);

  useEffect(() => {
    if (err) {
      toast.error(err);
    }
  }, [err]);

  useEffect(() => {
    if (finish) {
      if (processId) setIsSold([...listSold, processId]);
      toast.success(finish);
    }
  }, [finish]);

  return { handleSellArtwork, handleSellArtworkWithGrant, loading };
};

export default useSellArtwork;
