import React from 'react';

import { IconCloseButton } from '@/assets';

const ViewFullMedia: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  url: string;
}> = ({ isOpen, onClose, url }) => {
  if (!isOpen) return null;
  return (
    <div className="fixed left-0 top-0 z-999 flex h-full w-full items-center">
      <div onClick={onClose} className="absolute left-0 top-0 h-full w-full bg-[rgba(0,0,0,0.7)]" />
      <img
        className="relative m-auto h-[100%] max-h-[calc(100vh-40px)] max-w-[calc(100vw-40px)] object-contain"
        alt="view"
        src={url}
      />
      <button
        type="button"
        className="absolute right-5 top-5 sm:right-2 sm:top-2"
        onClick={onClose}
      >
        <IconCloseButton />
      </button>
    </div>
  );
};

export default ViewFullMedia;
