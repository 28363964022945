import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useSocket } from '@/provider/SocketProvider';
import { useAuth } from '@/provider/UserProvider';
import { checkGrant, requestGrant } from '@/services/grant';
import { isEnoughFee } from '@/utils';

import useBalance from './useBalance';
import { TokenDenom } from './useTokenPrices';

const useGrant = (
  handleWithDefault?: () => void,
  handleWithGranted?: () => void,
  handleWithRequest?: () => void,
) => {
  const { orai } = useBalance([TokenDenom.ORAI]);
  const { socket } = useSocket();
  const { auth } = useAuth();
  const [status, setStatus] = useState<'granted' | 'request' | 'default' | null>(null);

  const checkingGrant = async () => {
    setStatus(null);
    setTimeout(async () => {
      if (!isEnoughFee(orai)) {
        const res = await checkGrant();
        if (res.granted) {
          setStatus('granted');
          return;
        }
        await requestGrant();
        setStatus('request');
      } else setStatus('default');
    }, 1);
  };

  useEffect(() => {
    if (status === 'default' && handleWithDefault) handleWithDefault();
    if (status === 'granted' && handleWithGranted) handleWithGranted();
  }, [status]);

  useEffect(() => {
    if (status !== 'request') return;

    if (socket) {
      socket.on(`request-granted-${auth.user.publicAddress}`, async (message: any) => {
        const result = JSON.parse(message);
        if (result.status === 200) {
          if (handleWithRequest) handleWithRequest();
        } else {
          toast.error(result?.message || 'Request granted error!');
        }
      });
    }
    return () => {
      socket && socket.off(`request-granted-${auth.user.publicAddress}`);
    };
  }, [status, socket]);

  return { onStart: checkingGrant };
};

export default useGrant;
