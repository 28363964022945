import { Divider, Modal, ModalBody, ModalContent, ModalHeader } from '@nextui-org/react';
import React, { useState } from 'react';
import { toast } from 'react-toastify';

import { IconClose } from '@/assets';
import { Buttons, Images } from '@/components';
import { TokenDenom } from '@/hooks/useTokenPrices';
import { useAuth } from '@/provider/UserProvider';
import type { IAssetDetail, OfferAsk } from '@/types/asset';
import { getUnitCurrencyWithDenom } from '@/utils';
import { currencyFormatter } from '@/utils/currency';

import InputQuantity from './InputQuantity';

const BuyArtwork: React.FC<{
  onOpenChange: () => void;
  isOpen: boolean;
  onOpenCheckout: () => void;
  data: IAssetDetail;
  dataAsk?: OfferAsk;
  quantity?: number;
  setQuantity?: (val: number) => void;
}> = ({ isOpen, onOpenChange, onOpenCheckout, data, dataAsk, setQuantity, quantity = 1 }) => {
  const { isLogin } = useAuth();
  const [err, setErr] = useState('');
  const assetPrice = dataAsk ? dataAsk.currentPrice : data?.offer?.amount || 0;
  const denom = (dataAsk ? dataAsk.denom : data?.offer?.denom || TokenDenom.USDT) as TokenDenom;

  const handleContinue = (onClose: () => void) => {
    if (!isLogin) {
      toast.error('Please connect wallet to continue.');
      return;
    }
    if (err) return;
    onClose();
    onOpenCheckout();
  };

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={() => {
        onOpenChange();
        setErr('');
      }}
      hideCloseButton
      scrollBehavior="inside"
      placement="center"
    >
      <ModalContent className="min-w-[500px] rounded-[20px] sm:min-w-0">
        {(onClose) => (
          <>
            <ModalHeader className="flex items-center justify-between p-8 pb-0 sm:p-5">
              <span className="text-[24px] font-[600] sm:text-[18px]">Buy artwork</span>
              <div
                onClick={() => {
                  setErr('');
                  onClose();
                }}
                className="flex h-[32px] w-[32px] cursor-pointer items-center justify-center rounded-[8px] bg-gray-100"
              >
                <IconClose />
              </div>
            </ModalHeader>
            <ModalBody className="gap-0 px-[32px] py-0 sm:px-5">
              <div className="mt-7 flex items-center">
                <div className="h-[80px] w-[80px] overflow-hidden rounded-[4px] sm:h-[60px] sm:w-[60px]">
                  <Images className="object-cover" src={data?.url} alt="thumbnail" />
                </div>
                <div className="w-[calc(100%-200px)] pl-5 sm:w-[calc(100%-140px)] sm:pl-2">
                  <p className="line-clamp-2 text-[20px] font-semibold text-black sm:text-[16px]">
                    {data?.name}
                  </p>
                  <span className="line-clamp-1 text-[16px] text-gray-500 sm:text-[13px]">
                    {data?.provider?.username || 'Unname'}
                  </span>
                </div>
                <div className="w-[120px] text-right sm:w-[80px]">
                  <p className="text-[20px] font-semibold text-black sm:text-[16px]">
                    {currencyFormatter(assetPrice)} {getUnitCurrencyWithDenom(denom)}
                  </p>
                </div>
              </div>
              {dataAsk && setQuantity && (
                <>
                  <Divider className="my-10 bg-gray-100 sm:my-6" />
                  <InputQuantity
                    err={err}
                    setErr={setErr}
                    setQuantity={setQuantity}
                    total={dataAsk.quantity}
                    defaultValue={quantity}
                  />
                </>
              )}
              <Divider className="my-10 bg-gray-100 sm:my-6" />
              <div className="flex justify-between">
                <p className="text-[20px] font-semibold text-black sm:text-[16px]">Total price</p>
                <p className="text-[20px] font-semibold text-black sm:text-[16px]">
                  {currencyFormatter(assetPrice * Number(quantity))}{' '}
                  {getUnitCurrencyWithDenom(denom)}
                </p>
              </div>
              <Divider className="my-10 bg-gray-100 sm:my-6" />
              <Buttons onClick={() => handleContinue(onClose)} className="mb-10 sm:mb-6">
                Continue to payment
              </Buttons>
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default BuyArtwork;
