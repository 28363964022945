export const getMessageExecuteSellingTransaction721WithCw20Token = ({
  owner,
  amount,
  royalty,
  tokenId,
  tokenContract,
}: {
  owner: string;
  amount: string;
  royalty: number;
  tokenId: string;
  tokenContract: string;
}) => {
  return JSON.stringify({
    sell_nft: {
      contract_addr: owner,
      off_price: amount,
      royalty: royalty || null,
      token_id: btoa(
        JSON.stringify({
          token_info: {
            token_id: tokenId,
            data: btoa(
              JSON.stringify({
                asset_info: {
                  token: { contract_addr: tokenContract },
                },
              }),
            ),
          },
        }),
      ),
    },
  });
};

export const getMessageExecuteBuyingTransaction721WithCw20Token = (
  offering_id: number,
  price: string,
  marketplace: string,
) => {
  return JSON.stringify({
    send: {
      msg: btoa(
        JSON.stringify({
          buy_nft: {
            offering_id,
          },
        }),
      ),
      amount: price,
      contract: marketplace,
    },
  });
};

export const getMessageExecuteBuyingTransaction721WithNativeToken = (offering_id: number) => {
  return JSON.stringify({
    buy_nft: {
      offering_id,
    },
  });
};

export const getMessageExecuteBuyingTransaction1155WithCw20Token = ({
  offeringId,
  quantity,
  price,
  marketplace,
}: {
  offeringId: number;
  quantity: string;
  price: string;
  marketplace: string;
}) => {
  return JSON.stringify({
    send: {
      msg: btoa(
        JSON.stringify({
          buy_nft: {
            offering_id: offeringId,
            amount: quantity,
          },
        }),
      ),
      amount: price,
      contract: marketplace,
    },
  });
};

export const getMessageExecuteTransferTransaction721WithCw20Token = ({
  contractAddr,
  addressRecipient,
  tokenID,
}: {
  contractAddr: string;
  addressRecipient: string;
  tokenID: string;
}) => {
  return JSON.stringify({
    transfer_nft_directly: {
      contract_addr: contractAddr,
      recipient: addressRecipient,
      token_id: tokenID,
    },
  });
};

export const getMessageExecuteTransferTransaction1155WithCw20Token = ({
  contractAddr,
  addressRecipient,
  tokenId,
  amount,
}: {
  contractAddr: string;
  addressRecipient: string;
  tokenId: string;
  amount: string;
}) => {
  return JSON.stringify({
    transfer_nft_directly: {
      contract_addr: contractAddr,
      amount,
      to: addressRecipient,
      token_id: tokenId,
    },
  });
};

export const getMessageExecuteBuyingTransaction1155WithNativeToken = (
  offeringId: number,
  quantity: string,
) => {
  return JSON.stringify({
    buy_nft: {
      offering_id: offeringId,
      amount: quantity,
    },
  });
};

export const getMessageExecuteSellingTransaction1155WithCw20Token = ({
  owner,
  amount,
  quantity,
  tokenId,
  tokenContract,
}: {
  owner: string;
  amount: string;
  tokenId: string;
  tokenContract: string;
  quantity: string;
}) => {
  return JSON.stringify({
    sell_nft: {
      contract_addr: owner,
      per_price: amount,
      amount: quantity,
      token_id: btoa(
        JSON.stringify({
          token_info: {
            token_id: tokenId,
            data: btoa(
              JSON.stringify({
                asset_info: {
                  token: { contract_addr: tokenContract },
                },
              }),
            ),
          },
        }),
      ),
    },
  });
};

export const getMessageExecuteLockTokenWithCw20Token = ({
  userId,
  poolId,
  amount,
  contract,
}: {
  userId: number;
  poolId: number;
  amount: string;
  contract: string;
}) => {
  return JSON.stringify({
    send: {
      msg: btoa(
        JSON.stringify({
          lock_airi: {
            user_id: userId,
            pool_id: poolId,
          },
        }),
      ),
      amount,
      contract,
    },
  });
};

export const getMessageExecuteClaimWithCw20Token = (nonce: number) => {
  return JSON.stringify({
    claim: {
      nonce,
    },
  });
};

export const getMessageExecuteWithdrawWithCw20Token = (nonce: number) => {
  return JSON.stringify({
    withdraw: {
      nonce,
    },
  });
};

// export const getMessageExecuteSellingTransaction721WithNativeToken = (owner, amount, royalty, tokenId) => {
//   return JSON.stringify({
//       sell_nft: {
//           contract_addr: owner,
//           off_price: amount,
//           royalty: royalty || null,
//           token_id: btoa(
//               JSON.stringify({
//                   token_info: {
//                       token_id: tokenId,
//                       data: btoa(
//                           JSON.stringify({
//                               asset_info: {
//                                   native_token: { denom: "orai" },
//                               },
//                           })
//                       ),
//                   },
//               })
//           ),
//       },
//   });
// };

// export const getMessageExecuteSellingTransaction1155WithNativeToken = (owner, amount, quantity, tokenId) => {
//   return JSON.stringify({
//       sell_nft: {
//           contract_addr: owner,
//           per_price: amount,
//           amount: quantity,
//           token_id: btoa(
//               JSON.stringify({
//                   token_info: {
//                       token_id: tokenId,
//                       data: btoa(
//                           JSON.stringify({
//                               asset_info: {
//                                   native_token: { denom: "orai" },
//                               },
//                           })
//                       ),
//                   },
//               })
//           ),
//       },
//   });
// };

// export const getMessageExecuteBidTransaction1155WithCw20Token = (inforData, bidAmount, marketplace1155) => {
//   return JSON.stringify({
//       send: {
//           msg: btoa(
//               JSON.stringify({
//                   bid_nft: {
//                       auction_id: inforData.auctionId,
//                       per_price: bidAmount.toString(),
//                   },
//               })
//           ),
//           amount: (bidAmount * inforData.quantity).toString(),
//           contract: marketplace1155,
//       },
//   });
// };

// export const getMessageExecuteBidTransaction1155WithNativeToken = (inforData, bidAmount) => {
//   return JSON.stringify({
//       bid_nft: {
//           auction_id: inforData.auctionId,
//           per_price: bidAmount.toString(),
//       },
//   });
// };

// export const getMessageExecuteBidTransaction721WithCw20Token = (nftDetail, bidAmount, marketplace) => {
//   return JSON.stringify({
//       send: {
//           msg: btoa(
//               JSON.stringify({
//                   bid_nft: {
//                       auction_id: nftDetail.offer.auctionId,
//                   },
//               })
//           ),
//           amount: bidAmount.toString(),
//           contract: marketplace,
//       },
//   });
// };

// export const getMessageExecuteBidTransaction721WithNativeToken = (nftDetail) => {
//   return JSON.stringify({
//       bid_nft: {
//           auction_id: nftDetail.offer.auctionId,
//       },
//   });
// };
