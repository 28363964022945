import { Button } from '@nextui-org/button';
import {
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Textarea,
  useDisclosure,
} from '@nextui-org/react';
import type { ReactNode } from 'react';
import React from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { IconClose, IconPlusWhite } from '@/assets';
import { Buttons, Images, SelectMultipleWithSearch } from '@/components';
import MaxLengthInput from '@/components/max-length-input';
import useLoadTagCollection from '@/hooks/useLoadTagCollection';
import { ProfileSocial } from '@/types';

import useCreateCollection from './useCreateCollection';

interface AddNewCollectionProps {
  customTrigger?: ReactNode;
  setRefreshGetCollection?: React.Dispatch<React.SetStateAction<boolean>>;
  setCollectionId?: React.Dispatch<React.SetStateAction<string>>;
  setIsAddNew?: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddNewCollection: React.FC<AddNewCollectionProps> = ({
  customTrigger,
  setRefreshGetCollection,
  setCollectionId,
  setIsAddNew,
}) => {
  const { isOpen, onOpenChange, onOpen, onClose } = useDisclosure();
  const {
    register,
    setValue,
    watch,
    formState: { errors },
    handleSubmit: handleSubmitForm,
  } = useForm();
  const {
    loading,
    handleCreateCollection,
    handleChangeFile,
    handleRemoveFile,
    previewThumbnail,
    previewCover,
    getLinks,
  } = useCreateCollection();
  const { loadTagOptions } = useLoadTagCollection();
  const nameWatch = watch('name');
  const tagWatch = watch('tags');
  const descriptionWatch = watch('description');

  const listSocial = [
    {
      key: ProfileSocial.facebook,
      title: 'Facebook',
      placeholder: '',
    },
    {
      key: ProfileSocial.twitter,
      title: 'Twitter',
      placeholder: '',
    },
    {
      key: ProfileSocial.telegram,
      title: 'Telegram',
      placeholder: '',
    },
    {
      key: ProfileSocial.instagram,
      title: 'Instagram',
      placeholder: '',
    },
    {
      key: ProfileSocial.discord,
      title: 'Discord',
      placeholder: '',
    },
  ];

  const onSubmit = async (data: any) => {
    if (tagWatch?.length > 5) {
      return toast.error('Max 5 tags!');
    }
    if (tagWatch && tagWatch?.some((tag: any) => tag?.content?.length >= 20)) {
      return toast.error('Tag must be less than 20 characters!');
    }
    if (!data.tags || Object.keys(data.tags).length < 1) {
      return toast.error('Tag must be chosen!');
    }
    const links = getLinks(data);
    const collectionId = await handleCreateCollection(
      data.name,
      links,
      data.description,
      data.tags,
      Boolean(setRefreshGetCollection),
    );
    if (setRefreshGetCollection && setCollectionId && setIsAddNew) {
      setCollectionId(collectionId.toString());
      setRefreshGetCollection((prev) => !prev);

      setIsAddNew(true);
    }
    onClose();
  };

  return (
    <div className="flex justify-end lg:w-full">
      {customTrigger ? (
        <div onClick={onOpen}>{customTrigger}</div>
      ) : (
        <Button
          onClick={onOpen}
          className="ml-2 flex items-center rounded-[100px] bg-primary py-[10px] pl-[10px] pr-[16px] text-[15px] font-semibold text-white lg:mt-2 sm:text-[14px]"
        >
          <IconPlusWhite />
          New collection
        </Button>
      )}

      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        hideCloseButton
        scrollBehavior="inside"
        placement="center"
      >
        <ModalContent className="min-w-[550px] rounded-[32px] sm:min-w-[0] sm:rounded-[12px]">
          {(onClose) => (
            <>
              <ModalHeader className="flex items-center justify-between p-10 pb-8 sm:p-4">
                <span className="text-[24px] font-[600] sm:text-[16px]">Create new collection</span>
                <div
                  onClick={onClose}
                  className="flex h-[32px] w-[32px] cursor-pointer items-center justify-center rounded-[8px] bg-gray-100"
                >
                  <IconClose />
                </div>
              </ModalHeader>

              <ModalBody className="max-h-[350px] min-h-[186px] px-[40px] sm:min-h-0 sm:px-4">
                <div className="mb-4">
                  <div className="mb-2 text-[14px] font-[600] text-black sm:text-[13px]">
                    Thumbnail
                  </div>

                  <div>
                    <div className="h-[186px] w-[40%] overflow-hidden rounded-[20px] bg-[#F0F2FA]">
                      {previewThumbnail ? (
                        <Images
                          className="h-full w-full object-cover"
                          src={previewThumbnail}
                          alt="banner"
                        />
                      ) : (
                        <FileUploader
                          types={['png', 'jpg', 'jpeg']}
                          name="thumbnail"
                          classes="[&>input]:opacity-0 [&>input]:absolute sm:leading-[1] sm:-mt-[2px]"
                          handleChange={(file: File) => handleChangeFile(file, 'thumbnail')}
                        >
                          <div className="h-[186px] w-[100%] cursor-pointer items-center pt-[47%] text-center text-[14px] text-black">
                            default thumbnail
                          </div>
                          <input type="file" accept=".png, .jpg" />
                        </FileUploader>
                      )}
                    </div>
                    <div className="mt-3 flex min-w-[400px] items-center sm:mt-2 sm:min-w-0">
                      {previewThumbnail && (
                        <span
                          onClick={() => handleRemoveFile('thumbnail')}
                          className="mr-3 cursor-pointer text-[14px] font-semibold text-gray-600 hover:opacity-70 sm:text-[12px]"
                        >
                          Remove
                        </span>
                      )}
                      <FileUploader
                        types={['png', 'jpg', 'jpeg']}
                        name="thumbnail"
                        classes="[&>input]:opacity-0 [&>input]:absolute sm:leading-[1] sm:-mt-[2px]"
                        handleChange={(file: File) => handleChangeFile(file, 'thumbnail')}
                      >
                        <span className="cursor-pointer text-[14px] font-semibold text-gray-600 hover:opacity-70 sm:text-[12px]">
                          Update
                        </span>
                      </FileUploader>
                    </div>
                  </div>
                </div>

                <div className="mb-4">
                  <div className="mb-2 text-[14px] font-[600] text-black sm:text-[13px]">
                    Wallpaper
                  </div>

                  <div>
                    <div className="h-[186px] w-full overflow-hidden rounded-[20px] bg-[#F0F2FA]">
                      {previewCover ? (
                        <Images
                          className="h-full w-full object-cover"
                          src={previewCover}
                          alt="banner"
                        />
                      ) : (
                        <FileUploader
                          types={['png', 'jpg', 'jpeg']}
                          name="cover"
                          classes="[&>input]:opacity-0 [&>input]:absolute sm:leading-[1] sm:-mt-[2px]"
                          handleChange={(file: File) => handleChangeFile(file, 'cover')}
                        >
                          <div className="h-[186px] w-[100%] cursor-pointer items-center pt-[47%] text-center text-[14px] text-black">
                            default thumbnail
                          </div>
                          <input type="file" accept=".png, .jpg" />
                        </FileUploader>
                      )}
                    </div>
                    <div className="mt-3 flex min-w-[400px] items-center sm:mt-2 sm:min-w-0">
                      {previewCover && (
                        <span
                          onClick={() => handleRemoveFile('cover')}
                          className="mr-3 cursor-pointer text-[14px] font-semibold text-gray-600 hover:opacity-70 sm:text-[12px]"
                        >
                          Remove
                        </span>
                      )}
                      <FileUploader
                        types={['png', 'jpg', 'jpeg']}
                        name="cover"
                        classes="[&>input]:opacity-0 [&>input]:absolute sm:leading-[1] sm:-mt-[2px]"
                        handleChange={(file: File) => handleChangeFile(file, 'cover')}
                      >
                        <span className="cursor-pointer text-[14px] font-semibold text-gray-600 hover:opacity-70 sm:text-[12px]">
                          Update
                        </span>
                      </FileUploader>
                    </div>
                  </div>
                </div>

                <div className="mb-2">
                  <div className="mb-2 text-[14px] font-[600] text-black sm:text-[13px]">
                    Collection name <span className="text-red-600">*</span>
                  </div>

                  <div>
                    <Input
                      classNames={{
                        inputWrapper: 'rounded-[100px] bg-gray-50 px-[14px] sm:text-[13px]',
                      }}
                      required
                      maxLength={60}
                      placeholder="e.g. my first AI artwork"
                      {...register('name', {
                        required: 'Name is required',
                      })}
                    />
                    <div
                      className={`mt-1 flex h-[20px] ${
                        errors.name ? 'justify-between' : 'justify-end'
                      }`}
                    >
                      {errors.name && (
                        <div className="text-[13px] text-red-500">
                          {errors.name?.message as string}
                        </div>
                      )}
                      <MaxLengthInput max={60} length={nameWatch?.length || 0} />
                    </div>
                  </div>
                </div>

                <div className="mb-2">
                  <div className="mb-2 text-[14px] font-[600] text-black sm:text-[13px]">
                    Description <span className="text-red-600">*</span>
                  </div>
                  <div>
                    <Textarea
                      classNames={{
                        inputWrapper: 'rounded-[16px] sm:text-[13px] bg-gray-50 px-[14px] py-[2px]',
                        label: 'hidden',
                      }}
                      {...register('description')}
                      maxLength={500}
                      placeholder="Write an introduction for your collection"
                      minRows={7}
                    />
                    <div
                      className={`mt-1 flex h-[20px] ${
                        errors.description ? 'justify-between' : 'justify-end'
                      }`}
                    >
                      {errors.description && (
                        <div className="text-[13px] text-red-500">
                          {errors.description?.message as string}
                        </div>
                      )}
                      <MaxLengthInput max={500} length={descriptionWatch?.length || 0} />
                    </div>
                  </div>
                </div>

                <div className="mb-2">
                  <div className="mb-2 text-[14px] font-[600] text-black sm:text-[13px]">
                    Tag <span className="text-red-600">*</span>
                    <div className="text-[13px] font-[400] text-gray-500 sm:text-[12px]">
                      Max 5 tags to be chosen and not to be changed afterward.
                    </div>
                  </div>
                  <div>
                    <SelectMultipleWithSearch
                      onChangeValue={(val) =>
                        setValue(
                          'tags',
                          val.map((v) => v.label),
                        )
                      }
                      isValidNewOption={() => false}
                      defaultValue={[]}
                      loadOptions={loadTagOptions}
                      placeholder="Search option"
                      menuPlacement="top"
                    />
                  </div>
                </div>

                <hr className="mb-[20px]" />

                <div className="mb-2">
                  {listSocial.map((item) => (
                    <div className="pb-[20px]">
                      <div
                        className="mb-[8px] text-[14px] font-[600] text-black sm:text-[13px]"
                        key={item.key}
                      >
                        {item.title}
                      </div>
                      <Input
                        labelPlacement="inside"
                        startContent={
                          <div className="m-auto flex font-normal text-[#CCD1E0]">
                            <span className="pl-[6px]">https://</span>
                            <div className="ml-[6px] mr-[3px] h-auto w-[1px] bg-[#CCD1E0]" />
                          </div>
                        }
                        {...register(item.key)}
                        placeholder={item.placeholder}
                        classNames={{
                          inputWrapper: 'rounded-[100px] bg-gray-50 sm:text-[13px] shadow-none ',
                        }}
                      />
                    </div>
                  ))}
                </div>
              </ModalBody>
              <ModalFooter className="mt-5 block p-0 sm:mt-3">
                <div className="flex items-center justify-end border-t-1 border-gray-100 px-[40px] py-[30px] sm:p-4">
                  <Buttons onClick={onClose} typeBtn="cancel">
                    Cancel
                  </Buttons>
                  <Buttons
                    isLoading={loading}
                    onClick={handleSubmitForm(onSubmit)}
                    className="ml-3"
                  >
                    Create
                  </Buttons>
                </div>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
};

export default AddNewCollection;
