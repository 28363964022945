import axios from 'axios';

import { api } from '@/services';

export const getModels = async (size: number, offset: number, sort?: object, filter?: object) => {
  const res = await api.get('/models', {
    params: { size, offset, sort, filter },
  });
  return res;
};

export const getLoras = async (size: number, offset: number, sort?: string, filter?: string) => {
  try {
    const res = await api.get('/lora', {
      params: { size, offset, sort, filter },
    });
    return res;
  } catch (error) {
    console.error('Unexpected error:', error);
    throw error;
  }
};

export const getModelDetail = async (id: number) => {
  const res = await api.get(`/models/${id}`);
  return res;
};
export const getModelLoraDetail = async (id: number) => {
  const res = await api.get(`/lora/${id}`);
  return res;
};

export const generateText2Image = async (formData: FormData) => {
  const res = await api.post(`/models/text2image/generate`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return res;
};

export const generateSdxl = async (formData: FormData) => {
  const res = await api.post(`/models/sdxl/generate`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return res;
};

export const upscaleText2Image = async (id: string) => {
  const res = await api.post(`/models/text2image/upscale/${id}`);
  return res;
};

// export const refinerText2Image = async (formData: FormData) => {
//   const res = await api.post(`/models/text2image/refiner`, formData, {
//     headers: {
//       'Content-Type': 'multipart/form-data',
//     },
//   });
//   return res;
// };

export const generateInpainting = async (formData: FormData) => {
  const res = await api.post(`/models/text2image/inpainting`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return res;
};

export const generateSdxl3 = async (formData: FormData) => {
  const res = await api.post(`/models/sdxl3/generate`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return res;
};

export const generateImgIpAdapter = async (formData: FormData) => {
  const res = await api.post(`/models/instant-style/generate`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return res;
};

export const generateVideoLivePortrait = async (formData: FormData) => {
  const res = await api.post(`/models/live-portrait/generate`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return res;
};

export const generateFlux = async (formData: object) => {
  const res = await api.post('/models/flux/generate', formData);
  return res;
};

export const generateSticker = async (formData: FormData) => {
  const res = await api.post(`/models/sticker/generate`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return res;
};

export const generateDalle = async (formData: FormData) => {
  const res = await api.post(`/models/prompt/dall-e`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return res;
};

export const generateControlNet = async (formData: FormData) => {
  const res = await api.post(`/models/controlNet/generate`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return res;
};

export const generateModelQRCode = async (formData: FormData) => {
  const res = await api.post(`/models/controlNet/qr-code`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return res;
};

export const generateModelAnime = async (formData: FormData) => {
  const res = await api.post(`/models/controlNet/anime`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return res;
};

export const getGenerateText2Image = async (id: string) => {
  try {
    const res = await api.get(`/processing-assets/${id}`);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getListModelSdxl = async () => {
  try {
    const res = await api.get('/models/prompt/model-sdxl');
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getListMode = async () => {
  try {
    const res = await api.get('/models/prompt/model15');
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getListModelInpaint = async () => {
  try {
    const res = await api.get('/models/prompt/model-inpaint');
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getListLoras = async () => {
  try {
    const res = await api.get('/models/prompt/lora15');
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getListLorasSdxl = async () => {
  try {
    const res = await api.get('/models/prompt/lora-sdxl');
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getSuggestPrompt = async (content: string) => {
  try {
    const res = await api.get(`/models/prompt/suggested-prompt?content=${content}`);
    return res;
  } catch (error) {
    throw error;
  }
};

export const calculateCreditInModel = async (taskType: string, baseModel: any) => {
  try {
    const res = await api.post('calculate-credits', {
      taskType,
      baseModel,
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const likeModel = async (id: number) => {
  const res = await api.post(`/reactions/${id}/like-model`);
  return res.data;
};

export const unLikeModel = async (id: number) => {
  const res = await api.post(`/reactions/${id}/unlike-model`);
  return res.data;
};
export const increasingDownloadModel = async (id: number) => {
  const res = await api.patch(`/lora/${id}/download`);
  return res.data;
};

export const getUrlUploadModel = async (size: number) => {
  return api.post('/storage/request-upload-model', { size });
};

export const uploadModel = async (imageUrl: string) => {
  return api.post('/upload-model', { imageUrl });
};

export const getMyModels = async () => {
  return api.get('/upload-model');
};

export const uploadModelWithUrl = async (url: string, file: File) => {
  return axios.put(url, file, { headers: { 'Content-Type': file.type } });
};
