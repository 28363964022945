import { useEffect, useState } from 'react';

import { useAuth } from '@/provider/UserProvider';
import {
  getWalletAIRIBalance,
  getWalletBalance,
  getWalletCupiBalance,
  getWalletUsdBalance,
} from '@/utils';

import { TokenDenom } from './useTokenPrices';

const useBalance = (denomList: Array<TokenDenom>, isGetNow = true) => {
  const { auth } = useAuth();
  const address = auth.user?.publicAddress;
  const isLogin = address && address !== '0x';
  const [usd, setUsd] = useState(0);
  const [airi, setAiri] = useState(0);
  const [orai, setOrai] = useState(0);
  const [cupi, setCupi] = useState(0);

  const getUsdBalance = async () => {
    if (!isLogin) return;
    const usdBalance = await getWalletUsdBalance(address);
    const balance = usdBalance?.data?.balance ? Number(usdBalance?.data?.balance) : 0;
    setUsd(balance);
  };

  const getAiriBalance = async () => {
    if (!isLogin) return;
    const usdBalance = await getWalletAIRIBalance(address);
    const balance = usdBalance?.data?.balance ? Number(usdBalance?.data?.balance) : 0;
    setAiri(balance);
  };

  const getCupiBalance = async () => {
    if (!isLogin) return;
    const usdBalance = await getWalletCupiBalance(address);
    const balance = usdBalance?.data?.balance ? Number(usdBalance?.data?.balance) : 0;
    setCupi(balance);
  };

  const getOraiBalance = async () => {
    if (!isLogin) return;
    const oraiBalance = await getWalletBalance(address);
    const balance = oraiBalance?.balance?.amount ? Number(oraiBalance?.balance?.amount) : 0;
    setOrai(balance);
  };

  useEffect(() => {
    if (typeof window === 'undefined' || !isGetNow) return;
    if (denomList.includes(TokenDenom.USDT)) getUsdBalance();
    if (denomList.includes(TokenDenom.AIRI)) getAiriBalance();
    if (denomList.includes(TokenDenom.ORAI)) getOraiBalance();
    if (denomList.includes(TokenDenom.CUPI)) getCupiBalance();
  }, [address]);

  return { getUsdBalance, getAiriBalance, usd, airi, orai, cupi };
};

export default useBalance;
