import { Accordion, AccordionItem } from '@nextui-org/react';
import type { ReactNode } from 'react';
import React from 'react';

import { DownIndicatorAccordionIcon, UpIndicatorAccordionIcon } from '@/components/assets';

interface AccordionTransparentProps {
  children: ReactNode;
  title: ReactNode;
  openDefault?: boolean;
  indicator?: boolean;
}

const AccordionTransparent: React.FC<AccordionTransparentProps> = ({
  children,
  title,
  openDefault,
  indicator = true,
}) => {
  const key = '1';
  return (
    <div className="bg-transparent">
      <Accordion
        defaultExpandedKeys={openDefault ? key : '0'}
        motionProps={{
          variants: {
            enter: {
              y: 0,
              opacity: 1,
              height: 'auto',
              transition: {
                height: {
                  type: 'spring',
                  stiffness: 500,
                  damping: 30,
                  duration: 1,
                },
                opacity: {
                  easings: 'ease',
                  duration: 1,
                },
              },
            },
            exit: {
              y: -10,
              opacity: 0,
              height: 0,
              transition: {
                height: {
                  easings: 'ease',
                  duration: 0.25,
                },
                opacity: {
                  easings: 'ease',
                  duration: 0.3,
                },
              },
            },
          },
        }}
        selectionMode="single"
        itemClasses={{ trigger: 'py-0' }}
        className="px-0"
      >
        <AccordionItem
          textValue=" "
          key={key}
          title={title}
          classNames={{
            content: ['p-0'],
          }}
          indicator={
            indicator ? (
              ({ isOpen }) => {
                return isOpen ? (
                  <UpIndicatorAccordionIcon color="#181818" className="rotate-90" />
                ) : (
                  <DownIndicatorAccordionIcon color="#181818" />
                );
              }
            ) : (
              <></>
            )
          }
        >
          {children}
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default AccordionTransparent;
