export const bannedWords = [
  'hot slut',
  'blood',
  'twerk',
  'making love',
  'voluptuous',
  'naughty',
  'wincest',
  'orgy',
  'no clothes',
  'au naturel',
  'no shirt',
  'decapitate',
  'bare',
  'nude',
  'barely dressed',
  'risque',
  'scantily clad',
  'cleavage',
  'stripped',
  'infested',
  'full frontal',
  'unclothed',
  'invisible clothes',
  'wearing nothing',
  'lingerie',
  'with no shirt',
  'naked',
  'without clothes on',
  'negligee',
  'zero clothes',
  'gruesome',
  'fascist',
  'nazi',
  'prophet mohammed',
  'slave',
  'coon',
  'honkey',
  'cocaine',
  'heroin',
  'meth',
  'crack',
  'kill',
  'belle delphine',
  'hitler',
  'jinping',
  'lolita',
  'president xi',
  'torture',
  'disturbing',
  'farts',
  'fart',
  'poop',
  'infected',
  'warts',
  'shit',
  'brown pudding',
  'bunghole',
  'vomit',
  'seductive',
  'sperm',
  'sexy',
  'sadist',
  'sensored',
  'censored',
  'silenced',
  'deepfake',
  'inappropriate',
  'waifu',
  'succubus',
  'slaughter',
  'surgery',
  'reproduce',
  'crucified',
  'seductively',
  'explicit',
  'large bust',
  'wang',
  'teratoma',
  'intimate',
  'see through',
  'tryphophobia',
  'bloodbath',
  'wound',
  'cronenberg',
  'khorne',
  'cannibal',
  'cannibalism',
  'visceral',
  'guts',
  'bloodshot',
  'gory',
  'killing',
  'crucifixion',
  'vivisection',
  'massacre',
  'hemoglobin',
  'suicide',
  'arse',
  'labia',
  'ass',
  'mammaries',
  'badonkers',
  'bloody',
  'minge',
  'big ass',
  'mommy milker',
  'booba',
  'nipple',
  'oppai',
  'booty',
  'organs',
  'bosom',
  'ovaries',
  'flesh',
  'breasts',
  'penis',
  'busty',
  'phallus',
  'clunge',
  'sexy female',
  'crotch',
  'skimpy',
  'dick',
  'thick',
  'bruises',
  'girth',
  'titty',
  'honkers',
  'vagina',
  'hooters',
  'veiny',
  'knob',
  'ahegao',
  'pinup',
  'ballgag',
  'car crash',
  'playboy',
  'bimbo',
  'pleasure',
  'bodily fluids',
  'pleasures',
  'boudoir',
  'rule34',
  'brothel',
  'seducing',
  'dominatrix',
  'corpse',
  'erotic',
  'fuck',
  'sensual',
  'hardcore',
  'hentai',
  'shag',
  'horny',
  'shibari',
  'incest',
  'smut',
  'jav',
  'jerk off king at pic',
  'thot',
  'kinbaku',
  'legs spread',
  'sensuality',
  'belly button',
  'porn',
  'patriotic',
  'bleed',
  'excrement',
  'petite',
  'seduction',
  'mccurry',
  'provocative',
  'sultry',
  'erected',
  'camisole',
  'tight white',
  'arrest',
  'see-through',
  'feces',
  'anus',
  'revealing clothing',
  'vein',
  'loli',
  '-edge',
  'boobs',
  '-backed',
  'tied up',
  'zedong',
  'bathing',
  'jail',
  'reticulum',
  'rear end',
  'sakimichan',
  'behind bars',
  'shirtless',
  'sexi',
  'sexualiz',
  'sexual',
  'cutting',
  'female body parts',
  'drugs',
  'speedo',
  'au naturale',
  'bare chest',
  'risqué',
  'clear',
  'scantily',
  'clad',
  'full frontal unclothed',
  'lingerie with no shirt',
  'erotic seductive',
  'transparent',
  'xxx',
  'bondage',
  'bdsm',
  'dog collar',
  'slavegirl',
  'transparent and translucent',
  'taboo',
  'arrested',
  'handcuffs',
  'human centipede',
  'massive chests',
  'xi jinping',
  'errect',
  'big black',
  'hot',
  'pus',
  'mp5',
  '1488',
  'arsehead',
  'arsehole',
  'asshole',
  'bastard',
  'bitch',
  'bollocks',
  'brotherfucker',
  'bugger',
  'bullshit',
  'child-fucker',
  'christ on a bike',
  'christ on a cracker',
  'cock',
  'cocksucker',
  'crap',
  'cunt',
  'damn',
  'damn it',
  'dickhead',
  'dyke',
  'fatherfucker',
  'frigger',
  'goddamn',
  'godsdamn',
  'hell',
  'holy shit',
  'horseshit',
  'in shit',
  'jesus christ',
  'jesus fuck',
  'jesus h. christ',
  'jesus harold christ',
  'jesus, mary and joseph',
  'jesus wept',
  'kike',
  'motherfucker',
  'nigga',
  'nigra',
  'pigfucker',
  'piss',
  'prick',
  'pussy',
  'shit ass',
  'shite',
  'sisterfucker',
  'slut',
  'son of a whore',
  'son of a bitch',
  'spastic',
  'sweet jesus',
  'turd',
  'twat',
  'wanker',
];
