import { api } from '../axios';

export const registerSubscription = async (subscriptionName: string) => {
  const res = await api.post(`/subscription/register`, { plan: subscriptionName });
  return res.data;
};
export const stopCancelSubscription = async () => {
  const res = await api.post(`/subscription/stop-cancel-sub`);
  return res.data;
};

export const getPlan = async () => {
  try {
    const res = await api.get(`/subscription/user`);
    return res?.data;
  } catch (error) {
    return {};
  }
};

export const postCancel = async () => {
  const res = await api.post(`/subscription/cancel`);
  return res.data;
};
export const postBuyRequest = async (value: any) => {
  const res = await api.post(`/subscription/buy-request`, { amountUsd: value });
  return res.data;
};
