import { Divider, Modal, ModalBody, ModalContent, ModalHeader } from '@nextui-org/react';
import { useRouter } from 'next/navigation';
import React from 'react';

import { IconClose } from '@/assets';
import { Buttons, Images } from '@/components';
import RenderWhen from '@/components/RenderWhen';
import { TokenDenom } from '@/hooks/useTokenPrices';
import type { IAssetDetail, OfferAsk } from '@/types/asset';
import { getUnitCurrencyWithDenom } from '@/utils';
import { currencyFormatter } from '@/utils/currency';

import useCheckout from './useCheckout';

const Checkout: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  data: IAssetDetail;
  onOpenPayment: () => void;
  quantity?: number;
  dataAsk?: OfferAsk;
  callback?: () => void;
}> = ({ isOpen, onClose, data, onOpenPayment, quantity = 1, dataAsk, callback }) => {
  const denom = (dataAsk ? dataAsk.denom : data?.offer?.denom || TokenDenom.USDT) as TokenDenom;
  const assetPrice = (dataAsk ? dataAsk.currentPrice : data?.offer?.amount || 0) * quantity;
  const router = useRouter();

  const purchaseCallback = () => {
    dataAsk && callback ? callback() : router.refresh();
    setTimeout(() => {
      onClose();
    }, 100);
  };

  const { isEnough, onPurchase, loading, balance } = useCheckout({
    price: assetPrice.toString(),
    callback: purchaseCallback,
    quantity: quantity.toString(),
    asset: data,
    dataAsk,
  });

  const handleGoback = () => {
    onClose();
    onOpenPayment();
  };

  return (
    <Modal isOpen={isOpen} hideCloseButton scrollBehavior="inside" placement="center">
      <ModalContent className="min-w-[550px] rounded-[20px] sm:min-w-0">
        <ModalHeader className="flex items-center justify-between p-8 pb-0 sm:p-5">
          <span className="text-[24px] font-[600] sm:text-[18px]">Checkout</span>
          <div
            onClick={() => {
              onClose();
            }}
            className="flex h-[32px] w-[32px] cursor-pointer items-center justify-center rounded-[8px] bg-gray-100"
          >
            <IconClose />
          </div>
        </ModalHeader>
        <ModalBody className="gap-0 px-[32px] py-0 sm:px-5">
          <div className="mt-7 flex items-center sm:mt-4">
            <div className="h-[80px] w-[80px] overflow-hidden rounded-[4px] sm:h-[60px] sm:w-[60px]">
              <Images className="object-cover" src={data?.url} alt="thumbnail" />
            </div>
            <div className="w-[calc(100%-80px)] pl-5 sm:pl-2">
              <p className="line-clamp-2 text-[20px] font-semibold text-black sm:text-[16px]">
                {data?.name}
              </p>
              <span className="line-clamp-1 text-[16px] text-gray-500 sm:text-[14px]">
                {data?.provider?.username || 'Unname'}
              </span>
            </div>
          </div>
          <Divider className="my-10 bg-gray-100 sm:my-6" />
          <div className="flex justify-between">
            <p className="text-[20px] font-semibold text-black sm:text-[16px]">Price</p>
            <p className="text-[20px] font-semibold text-black sm:text-[16px]">
              {currencyFormatter(assetPrice)} {getUnitCurrencyWithDenom(denom)}
            </p>
          </div>
          <Divider className="my-10 bg-gray-100 sm:my-6" />
          <div className="flex justify-between">
            <p className="text-[20px] font-semibold text-black sm:text-[16px]">Your balance</p>
            <p className="text-[20px] font-semibold text-black sm:text-[16px]">
              {currencyFormatter(balance)} {getUnitCurrencyWithDenom(denom)}
            </p>
          </div>
          {/* <RenderWhen>
            <RenderWhen.If isTrue={!isEnough}>
              <div className="mt-5 flex items-center rounded-[20px] bg-yellow-50 p-5 sm:flex-wrap sm:p-4">
                <div className="text-[14px] leading-[1.5] sm:mb-4">
                  Your balance is not enough to complete this transaction. Top-up your wallet to
                  continue.
                </div>
                <Link
                  className="block whitespace-nowrap rounded-[100px] bg-primary px-4 py-2 text-[13px] font-semibold text-white"
                  href="https://www.kucoin.com/trade/ORAI-USDT"
                  target="_blank"
                >
                  Top up wallet
                </Link>
              </div>
            </RenderWhen.If>
          </RenderWhen> */}
          <Divider className="mb-7 mt-10 bg-gray-100" />
          <div className="mb-7 flex items-center justify-between">
            <Buttons typeBtn="cancel" onClick={handleGoback} className="w-[calc(50%-6px)]">
              Go back
            </Buttons>
            <RenderWhen>
              <RenderWhen.If isTrue={isEnough}>
                <Buttons onClick={onPurchase} isLoading={loading} className="w-[calc(50%-6px)]">
                  Pay now
                </Buttons>
              </RenderWhen.If>
              <RenderWhen.If isTrue={!isEnough}>
                <Buttons typeBtn="disabled" disabled className="w-[calc(50%-6px)]">
                  Pay now
                </Buttons>
              </RenderWhen.If>
            </RenderWhen>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default Checkout;
