'use client';

import { useDisclosure } from '@nextui-org/react';
import type { UseDisclosureReturn } from '@nextui-org/use-disclosure';
import React, { createContext, useContext, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { useAuth } from '@/provider/UserProvider';
import { getPlan } from '@/services/pricing';
import { deleteTemplate, publishTemplate } from '@/services/usecase';
import { useSQuery, ValidateFieldError } from '@/utils';
import { transferUserPlan } from '@/utils/serviceHelper';
import { PromptProviderState, useHistoryState } from '@/provider/HistoryProvider';

type PromptProviderProps = {
  children: React.ReactNode;
  isRemix?: boolean;
};

type PromptProviderContextType = {
  state: PromptProviderState[];
  setState: React.Dispatch<React.SetStateAction<PromptProviderState[]>>;
  isMintNow: boolean;
  setIsMintNow: React.Dispatch<React.SetStateAction<boolean>>;
  listSold: number[];
  setIsSold: React.Dispatch<React.SetStateAction<number[]>>;
  openCampaign: boolean;
  setOpenCampaign: React.Dispatch<React.SetStateAction<boolean>>;
  modelConfigRetry: any;
  useModalValidate: UseDisclosureReturn;
  validateFieldError: ValidateFieldError;
  isRemix?: boolean;
  setTab: React.Dispatch<React.SetStateAction<'create' | 'result'>>;
  tab: 'create' | 'result';
  resetIsSelect: boolean;
  idAssetIsSelected: number[];
  setIdAssetIsSelected: React.Dispatch<React.SetStateAction<number[]>>;
  publishAsset: (ids: number[]) => Promise<void>;
  deleteAsset: (ids: number[]) => Promise<void>;
  useModalControl: UseDisclosureReturn;
  dataTotalCredit: any;
  refetchGetPlan: any;
  userPlan: number;
  setIsDelJob: React.Dispatch<React.SetStateAction<boolean>>;
  isDelJob: boolean;
};

const PromptProviderContext = createContext<PromptProviderContextType>({
  isMintNow: true,
  setIsMintNow: () => {},
  listSold: [],
  setIsSold: () => {},
  openCampaign: false,
  setOpenCampaign: () => {},
  modelConfigRetry: null,
  // @ts-ignore
  useModalValidate: null,
  validateFieldError: new ValidateFieldError(),
});

export const PromptProvider: React.FC<PromptProviderProps> = ({ isRemix, children }) => {
  const { isLogin } = useAuth();
  const { setState, state } = useHistoryState();
  const [isMintNow, setIsMintNow] = useState<boolean>(true);
  const [listSold, setIsSold] = useState<number[]>([]);
  const [tab, setTab] = useState<'create' | 'result'>('create');
  const [openCampaign, setOpenCampaign] = useState<boolean>(false);
  const validateFieldError = useRef(new ValidateFieldError());
  const useModalValidate = useDisclosure({
    onClose() {
      validateFieldError.current.cleanErrors();
    },
  });

  const { data: dataTotalCredit, refetch: refetchGetPlan } = useSQuery({
    queryKey: ['dataTotalCredit'],
    queryFn: () => getPlan(),
    keepPreviousData: true,
    enabled: isLogin,
  });
  const { data: modelConfigRetry } = useSQuery({
    queryKey: ['model-config-retry'],
  });

  const userPlan = transferUserPlan(dataTotalCredit);
  const [idAssetIsSelected, setIdAssetIsSelected] = useState<number[]>([]);
  const [resetIsSelect, setResetIsSelect] = useState(false);
  const [isDelJob, setIsDelJob] = useState(false);
  const useModalControl = useDisclosure({
    onClose() {
      if (isDelJob) {
        setIdAssetIsSelected([]);
        setIsDelJob(false);
      }
    },
  });

  const publishAsset = async (ids: number[]) => {
    const res = await publishTemplate(ids);
    if (res) {
      setResetIsSelect(true);
      toast.success('Published successfully');
      setIdAssetIsSelected([]);
      setIsDelJob(false);
      setTimeout(() => {
        setResetIsSelect(false);
      }, 2000);
    } else {
      setIsDelJob(false);
      toast.error(`Published error`);
    }
  };

  const deleteAsset = async (ids: number[]) => {
    try {
      await deleteTemplate(ids);
      setResetIsSelect(true);
      toast.success(`Deleted successfully`);
      setState(state.filter((item) => item?.assetId && !idAssetIsSelected.includes(item.assetId)));
      setIdAssetIsSelected([]);
      setTimeout(() => {
        setResetIsSelect(false);
      }, 2000);
    } catch (error) {
      toast.error(`Deleted error`);
    }
  };

  const contextValue: PromptProviderContextType = useMemo(() => {
    return {
      state,
      setState,
      isMintNow,
      setIsMintNow,
      listSold,
      setIsSold,
      openCampaign,
      setOpenCampaign,
      modelConfigRetry,
      useModalValidate,
      validateFieldError: validateFieldError.current,
      isRemix,
      setTab,
      tab,
      resetIsSelect,
      idAssetIsSelected,
      setIdAssetIsSelected,
      publishAsset,
      deleteAsset,
      useModalControl,
      dataTotalCredit,
      refetchGetPlan,
      userPlan,
      isDelJob,
      setIsDelJob,
    };
  }, [
    state,
    setState,
    isMintNow,
    setIsMintNow,
    listSold,
    setIsSold,
    openCampaign,
    setOpenCampaign,
    modelConfigRetry,
    useModalValidate,
    validateFieldError,
    isRemix,
    setTab,
    tab,
    resetIsSelect,
    idAssetIsSelected,
    setIdAssetIsSelected,
    publishAsset,
    deleteAsset,
    useModalControl,
    dataTotalCredit,
    refetchGetPlan,
    userPlan,
    isDelJob,
    setIsDelJob,
  ]);

  return (
    <PromptProviderContext.Provider value={contextValue}>{children}</PromptProviderContext.Provider>
  );
};

export const usePrompt = () => useContext(PromptProviderContext);
