import { api } from '../axios';

export interface IGetListPrompt {
  size: number;
  offset: number;
  sort?: string;
  filter?: string;
}
export const getListPromptByUserId = async (userId: number | string, arg: IGetListPrompt) => {
  return await api.get(`/prompts/${userId}`, {
    params: arg,
  });
};

export const getPromptById = async (id: number | string) => {
  return await api.get(`/prompts/details/${id}`);
};

export interface IDataUpdatePrompt {
  name: string;
  description: string;
  royalty: number;
}
export const updatePromptById = async (id: number | string, arg: IDataUpdatePrompt) => {
  return await api.post(`/prompts/details/${id}`, arg);
};

export const updateAvatarPrompt = async (id: number | string, data: FormData) => {
  return await api.post(`/prompts/update-avatar/${id}`, data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export interface IGetListArtworkFromPrompt {
  size: number;
  offset: number;
  sort?: string;
  filter?: string;
}
export const getListArtworkByPromptId = async (
  id: string | number,
  arg: IGetListArtworkFromPrompt,
) => {
  return await api.get(`/prompts/${id}/assets`, {
    params: arg,
  });
};

export const numOfUserRequest = async (modelId: number, isPlus = false) => {
  return await api.get(`/models/prompt/requests/${modelId}?isPlus=${isPlus}`);
};
