import { Button, useDisclosure } from '@nextui-org/react';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import React, { useState } from 'react';

import {
  HeartBuyPromptActiveIcon,
  HeartBuyPromptIcon,
  IconEditBlack,
  IconFireGenCampaign,
  IconInfoDetail,
  IconPlus,
  IconVerify16,
} from '@/assets';
import { Buttons, ShareButton } from '@/components';
import AvatarPlaceholder from '@/components/avatar-default';
import BuyArtwork from '@/contents/art-work/detail/BuyArtwork';
import Checkout from '@/contents/art-work/detail/Checkout';
import UpdateArtwork from '@/contents/art-work/detail/Update';
import ModelVoteCampaign from '@/contents/campaign/card/ModelVoteCampaign';
import SellArtwork from '@/contents/my-artwork/sell-artwork';
import StopSellArtwork from '@/contents/my-artwork/stop-sell';
import TransferNFT from '@/contents/my-artwork/transfer-nft';
import { useLayout } from '@/provider/LayoutProvider';
import { useRouter } from '@/provider/RouterProvider';
import type { Collection, IAssetDetail } from '@/types/asset';
import { formatNumberSocial, formattedDate, isAsset1155 } from '@/utils';
import { currencyFormatter } from '@/utils/currency';

import AddCollectionModal from '../add-collection';
import ClientOnly from '../client-only';
import RenderMedia from '../render-media';
import RenderWhen from '../RenderWhen';
import TooltipCustom from '../tooltip-custom';

const DynamicAsset1155 = dynamic(() => import('@/contents/art-work/detail/Asset1155'));

interface BannerArtworkDetailProps {
  numView: number;
  isView?: boolean;
  isLike: boolean;
  handleUnlike: () => void;
  handleLike: () => void;
  numLike: number;
  dataUrl: string;
  mimeType: string;
  hasShare?: boolean;
  hasLike?: boolean;
  titleShare?: string;
  isBlur?: boolean;
  isPlus: boolean;
  data: IAssetDetail;
  collection: Collection;
  isOwner: boolean;
  onOpen?: () => void;
  getAssetDetail: () => void;
  isAddCollection?: boolean;
  isCanEdit: boolean;
}

const BannerArtworkDetail: React.FC<BannerArtworkDetailProps> = ({
  isPlus,
  numView = 0,
  handleUnlike,
  handleLike,
  hasShare = true,
  hasLike = true,
  isLike,
  titleShare = '',
  isBlur = false,
  data,
  collection,
  isOwner,
  getAssetDetail,
  isCanEdit,
  ...props
}) => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [displayVote, setDisplayVote] = useState(data?.tickets ?? 0);
  const [vote, setVote] = useState(0);
  const {
    isOpen: isOpenCheckout,
    onClose: onCloseCheckout,
    onOpen: onOpenCheckout,
  } = useDisclosure();
  const {
    isOpen: isOpenAddCollection,
    onClose: onCloseAddCollection,
    onOpen: onOpenAddCollection,
    onOpenChange: onOpenChangeAddCollection,
  } = useDisclosure();
  const {
    isOpen: isOpenUpdate,
    onClose: onCloseUpdate,
    onOpen: onOpenUpdate,
    onOpenChange: onOpenChangeUpdate,
  } = useDisclosure();

  const { onOpen: onOpenModelCampaign, ...useModel } = useDisclosure({
    onClose: () => {
      setVote(0);
    },
  });

  const hasOffer = Boolean(data?.offer);
  const [quantity, setQuantity] = useState(1);
  const pathname = usePathname();
  const { setIsLoadingBar } = useLayout();
  const { dataUrl, mimeType, numLike } = props;

  const router = useRouter();

  return (
    <div className="md:mb-12">
      {data?.campaign?.status === 2 && (
        <ModelVoteCampaign
          campaign={data?.campaign}
          nft={data}
          setVote={setVote}
          vote={vote}
          useModel={useModel}
          setDisplayVote={setDisplayVote}
        />
      )}
      <div className="main-container relative flex p-[40px] lg:block md:p-0 md:pt-5">
        <div className="items-center md:min-h-fit">
          <RenderMedia isBlur={isBlur} dataUrl={dataUrl} mimeType={mimeType} />
        </div>
        <ClientOnly>
          <div className="absolute right-[3%] top-20 h-full flex-col items-center justify-between lg:relative lg:left-1 lg:top-0 lg:pl-3 lg:pt-[15px]">
            <div className="lg:pt-auto lg:bottom-[300px] lg:left-3 lg:flex">
              <RenderWhen>
                <RenderWhen.If isTrue={hasLike}>
                  <div className="relative mb-3 lg:mr-3 md:mb-0">
                    <Buttons
                      onClick={isLike ? handleUnlike : handleLike}
                      typeBtn={isLike ? 'secondary' : 'cancel'}
                      className={`relative h-[55px] w-[55px] min-w-0 rounded-[100px] p-0 ${
                        !isLike ? 'bg-gray-50' : ''
                      }`}
                    >
                      {isLike ? <HeartBuyPromptActiveIcon /> : <HeartBuyPromptIcon />}
                    </Buttons>
                    <span className="absolute left-10 top-0 hidden rounded-[100px] bg-white p-0 px-1 text-12 font-semibold leading-4 text-gray-700 lg:block">
                      {formatNumberSocial(numLike)}
                    </span>
                  </div>
                </RenderWhen.If>
                <RenderWhen.If isTrue={hasShare}>
                  <ShareButton pathname={pathname} titleShare={titleShare} />
                </RenderWhen.If>
                <RenderWhen.If isTrue={isCanEdit}>
                  <Buttons
                    onClick={onOpenUpdate}
                    className="relative flex h-[55px] w-[55px] min-w-0 rounded-[100px] bg-slate-50 p-0 lg:ml-3"
                  >
                    <IconEditBlack />
                  </Buttons>
                </RenderWhen.If>
              </RenderWhen>
            </div>
          </div>
        </ClientOnly>
        <ClientOnly>
          <div className="w-full max-w-[690px] pl-[40px] pt-[20px] lg:px-3 lg:pt-0">
            <div className="">
              <div className="m-0 lg:pb-3">
                <span className="text-[40px] font-bold [overflow-wrap:anywhere] lg:text-[28px]">
                  {data.name}
                </span>
                <span className="pl-2 text-[40px] font-bold text-gray-500 lg:text-[28px]">
                  #{data.id}
                </span>
              </div>
              <div className="mt-[16px] flex items-center text-14 font-normal text-gray-500 lg:mt-0 lg:pb-5 sm:flex-col sm:items-start">
                <div className="flex items-center justify-center lg:pb-2">
                  <span className="text-14 font-normal text-gray-500">Created by</span>
                  <Link
                    href={`/profile/${
                      data.creatorProvider?.publicAddress || data.provider.publicAddress
                    }`}
                    className="flex items-center"
                    onClick={() => setIsLoadingBar(true)}
                  >
                    <div className="mx-2">
                      <AvatarPlaceholder
                        address={data.creatorProvider?.publicAddress || data.provider.publicAddress}
                        alt="AvatarPlaceholder"
                        src={data.creatorProvider?.picture || data.provider.picture}
                        className="h-6 w-6 rounded-full"
                      />
                    </div>
                    <span className="line-clamp-1 max-w-[80px] text-16 font-semibold text-orange-500">
                      {data.creatorProvider?.username || data.provider.username || 'Un Name'}
                    </span>
                    {data.provider?.sVerify === 'success' && (
                      <span className="pl-[1px]">
                        <IconVerify16 />
                      </span>
                    )}
                  </Link>
                </div>
                {!isAsset1155(data.version) && (
                  <>
                    <div className="mx-3 h-1 w-1 rounded-full bg-gray-500 sm:hidden" />
                    <div className="flex items-center justify-center">
                      <span className="text-14 font-normal text-gray-500">Last owned by</span>
                      <Link
                        href={`/profile/${data.provider.publicAddress}`}
                        className="flex items-center"
                        onClick={() => setIsLoadingBar(true)}
                      >
                        <div className="mx-2">
                          <AvatarPlaceholder
                            address={data.provider?.publicAddress}
                            alt="AvatarPlaceholder"
                            src={data.provider?.picture}
                            className="h-6 w-6 rounded-full"
                          />
                        </div>
                        <span className="line-clamp-1 max-w-[80px] text-16 font-semibold text-orange-500">
                          {data.provider.username || 'Un Name'}
                        </span>
                        {data.provider?.sVerify === 'success' && (
                          <span className="pl-[1px]">
                            <IconVerify16 />
                          </span>
                        )}
                      </Link>
                    </div>
                  </>
                )}
              </div>
              <div>
                <div className="flex items-center rounded-[20px] bg-white py-[24px] lg:hidden">
                  <div className="mr-10 flex flex-col">
                    <span className="mb-1 text-20 font-bold">{formatNumberSocial(numView)}</span>
                    <span className="text-14 font-normal text-gray-500">Views</span>
                  </div>
                  <div className="flex flex-col">
                    <span className="mb-1 text-20 font-bold">{formatNumberSocial(numLike)}</span>
                    <span className="text-14 font-normal text-gray-500">Likes</span>
                  </div>
                </div>
              </div>
            </div>
            {isAsset1155(data.version) ? (
              <DynamicAsset1155 data={data} refresh={getAssetDetail} />
            ) : (
              <div>
                {isOwner === true ? (
                  isPlus ? (
                    data.offer?.amount ? (
                      <RenderWhen.If isTrue={hasOffer}>
                        <StopSellArtwork asset={data} refresh={() => router.refresh()} />
                      </RenderWhen.If>
                    ) : (
                      <div className="grid max-w-[80%]  gap-y-[12px] lg:max-w-[80%]">
                        <div className="w-full">
                          <SellArtwork asset={data} refresh={() => router.refresh()} />
                        </div>
                      </div>
                    )
                  ) : (
                    <div className=" max-w-[80%] rounded-[24px] border-1 border-solid border-[#F0F2FA] p-[20px] lg:max-w-[80%]">
                      <div className="text-[16px] font-semibold leading-6 text-[#777E90]">
                        Current price
                      </div>
                      <div className=" pt-[16px] text-[40px] font-bold leading-9">Free</div>
                      <div
                        className=" rounded-[28px]"
                        style={{
                          backgroundImage: `url('/images/bg-upgrade-plan.png')`,
                          backgroundRepeat: 'no-repeat',
                          backgroundSize: '100% 100%',
                        }}
                      />
                    </div>
                  )
                ) : (
                  <div className="w-fit rounded-[24px] border-1 border-solid border-[#F0F2FA] p-[20px]">
                    {data.offer?.amount !== undefined && data.offer?.amount ? (
                      <div className="flex text-[16px] font-semibold leading-6 text-[#777E90] lg:text-[13px]">
                        <span className="pr-2">Outright purchase price</span>
                        <TooltipCustom
                          className=""
                          content="By buying this artwork, all the rights over it will be transferred from the original creator to you"
                        >
                          <div>
                            <IconInfoDetail className=" cursor-pointer" />
                          </div>
                        </TooltipCustom>
                      </div>
                    ) : (
                      <div className="flex text-[16px] font-semibold leading-6 text-[#777E90] lg:text-[13px]">
                        <span className="pr-2">NFT is not for sale.</span>
                      </div>
                    )}
                    {data.offer?.amount !== undefined && data.offer?.amount > 0 && (
                      <div className="pt-[12px] text-[40px] font-bold lg:text-[28px]">
                        {currencyFormatter(data.offer?.amount || 0, { significantDigits: 3 })}
                        {data.offer?.denom === 'AIRI' ? <span> AIRI</span> : <span>$</span>}
                      </div>
                    )}
                    {data.offer?.amount ? (
                      <div className="pt-[20px]">
                        {isOwner ? (
                          <TooltipCustom className="" content="You are owner!">
                            <Button size="lg" className="rounded-[100px]" disabled>
                              <span className="text-[18px] font-semibold text-white lg:text-[14px] sm:!text-[13px]">
                                Buy artwork
                              </span>
                            </Button>
                          </TooltipCustom>
                        ) : (
                          <Button
                            onClick={onOpen}
                            size="lg"
                            className="w-full rounded-[100px] bg-primary "
                          >
                            <span className="text-[18px] font-semibold text-white lg:text-[16px] sm:!text-[15px]">
                              Buy artwork
                            </span>
                          </Button>
                        )}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                )}
                {isOwner === true && !data.offer?.amount && (
                  <div className="grid max-w-[80%]  pt-[12px] lg:max-w-[80%]">
                    <div className="w-full">
                      <TransferNFT
                        asset={data}
                        refresh={getAssetDetail}
                        publicAddress={data.creatorProvider?.publicAddress || ''}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
            {data?.campaign?.status === 2 && (
              <div className="mt-4 flex w-full items-center justify-between rounded-[16px] border-1 border-gray-100 px-6 py-4">
                <div className="flex max-w-[70%] items-center">
                  <div className="w-[28px]">
                    <IconFireGenCampaign className="mr-2 scale-150" />
                  </div>
                  <div className="flex flex-col">
                    <p className="line-clamp-1 break-words text-16 font-semibold lg:text-14">
                      {data?.campaign?.name}
                    </p>
                    <p className="break-words text-14 font-normal lg:text-12">
                      Current vote: {Number(displayVote).toLocaleString()}
                    </p>
                  </div>
                </div>
                <div
                  onClick={onOpenModelCampaign}
                  className="ml-10 flex h-[46px] w-[125px] cursor-pointer items-center justify-center rounded-[100px] bg-orange-50 md:ml-5"
                >
                  <p className="text-[15px] font-semibold text-orange-500">Vote</p>
                </div>
              </div>
            )}
            <div className="mt-[24px] flex flex-wrap items-center gap-x-8 gap-y-4 lg:mt-0">
              {isOwner ? (
                <ClientOnly>
                  <div className="lg:mt-4">
                    <div className="mb-3 text-[14px] font-[600] text-gray-600">Collection</div>
                    <RenderWhen>
                      <RenderWhen.If isTrue={Boolean(data?.collectionId)}>
                        <Button
                          onClick={() => router.push(`/collection/${data?.collectionId}`)}
                          className="h-auto cursor-pointer rounded-[100px] bg-gray-50 px-[14px] py-[10px] text-[16px] font-[400] text-black sm:text-[13px]"
                        >
                          {collection?.name}
                        </Button>
                      </RenderWhen.If>
                      <RenderWhen.If isTrue={!data.collectionId && isOwner}>
                        <Button
                          onClick={onOpenAddCollection}
                          className="h-[45px] w-[45px] min-w-0 rounded-[50%] bg-gray-50 p-0"
                        >
                          <IconPlus />
                        </Button>
                      </RenderWhen.If>
                    </RenderWhen>
                  </div>
                </ClientOnly>
              ) : (
                <RenderWhen>
                  <RenderWhen.If isTrue={Boolean(collection?.name)}>
                    <div className="lg:mt-4">
                      <div className="mb-3 text-[14px] font-[600] text-gray-600">Collection</div>
                      <Button
                        onClick={() => router.push(`/collection/${collection.id}`)}
                        className="h-auto rounded-[100px] bg-gray-50 px-[14px] py-[10px] text-[16px] font-[400] text-black sm:text-[13px]"
                      >
                        {collection?.name}
                      </Button>
                    </div>
                  </RenderWhen.If>
                </RenderWhen>
              )}

              <RenderWhen>
                <RenderWhen.If isTrue={Boolean(data.modelId)}>
                  <div className="lg:mt-4">
                    <div className="mb-3 text-[14px] font-[600] text-gray-600">Model used</div>
                    <Button
                      onClick={() => router.push(`/ai-model/${data.model.id}`)}
                      className="h-auto rounded-[100px] bg-gray-50 px-[14px] py-[10px] text-[16px] font-[400] text-black sm:text-[13px]"
                    >
                      {data.model?.name}
                    </Button>
                  </div>
                </RenderWhen.If>
              </RenderWhen>
              <div>
                <div className="mb-3 text-[14px] font-[600] text-gray-600 lg:mt-4">
                  Date created
                </div>
                <p className="h-auto rounded-[100px] bg-gray-50 px-[14px] py-[10px] text-[16px] font-[400] text-black sm:text-[13px]">
                  {formattedDate(data.createdAt)}
                </p>
              </div>
            </div>
          </div>
        </ClientOnly>

        <BuyArtwork
          data={data}
          isOpen={isOpen}
          onOpenChange={onOpenChange}
          onOpenCheckout={onOpenCheckout}
          quantity={quantity}
          setQuantity={setQuantity}
        />
        {isOpenCheckout && (
          <Checkout
            data={data}
            isOpen={isOpenCheckout}
            onClose={onCloseCheckout}
            onOpenPayment={onOpen}
            quantity={quantity}
          />
        )}
        {isOpenAddCollection && (
          <AddCollectionModal
            onClose={onCloseAddCollection}
            assetId={data.id}
            isOpen={isOpenAddCollection}
            onOpenChange={onOpenChangeAddCollection}
          />
        )}
        {isCanEdit && (
          <UpdateArtwork
            isOpen={isOpenUpdate}
            onClose={onCloseUpdate}
            onOpenChange={onOpenChangeUpdate}
            callback={getAssetDetail}
            data={{
              id: data.id,
              name: data.name,
              description: data.description,
              tags: data.tags,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default BannerArtworkDetail;
