import { Images } from "..";

const AvatarDecor = ({
  url,
  scale = 0
}:{
  url: string,
  scale?: number,
}) => {
  return (
    <div style={{ width: `calc(100% + ${scale}px)`, height: `calc(100% + ${scale}px)` }} className={`absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]`}>
      <Images className="w-full h-full" alt="img" src={url} />
    </div>
  );
}

export default AvatarDecor;