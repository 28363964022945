export const parametersOfMidjourney = [
  {
    name: 'aspect',
    key: '--ar',
    example: '1:1',
    defaultValue: ['16:9', '4:3', '1:1', '3:2', '2:1', '5:4', '9:16', '3:4'],
    customValue: false,
    type: 'rangeInput',
    max: null,
    min: null,
    description:
      'The --aspect or --ar parameter changes the aspect ratio of the generated image. An aspect ratio is the width-to-height ratio of an image. It is typically expressed as two numbers separated by a colon, such as 7:4 or 4:3.',
  },
  {
    name: 'chaos',
    key: '--c',
    example: 0,
    defaultValue: [0, 20, 40, 60, 80, 100],
    customValue: false,
    type: 'numberArray',
    max: 100,
    min: 0,
    description:
      'The --chaos or --c parameter influences how varied the initial image grids are. High --chaos values will produce more unusual and unexpected results and compositions. Lower --chaos values have more reliable, repeatable results.',
  },
  {
    name: 'no item',
    key: '--no',
    example: '--no fruit',
    defaultValue: null,
    customValue: true,
    type: 'stringInput',
    max: null,
    min: null,
    description: 'The No parameter tells the Midjourney Bot what not to include in your image.',
    placeholder: 'fruit',
  },
  {
    name: 'quality',
    key: '--q',
    example: 1,
    defaultValue: ['.25', '.5', '1'],
    customValue: false,
    type: 'numberArray',
    max: 1,
    min: 0,
    description:
      'The --quality or --q parameter changes how much time is spent generating an image. Higher-quality settings take longer to process and produce more details. Higher values also mean more GPU minutes are used per job. The quality setting does not impact resolution, only accepts the values: .25, .5, and 1 for the current model. Larger values are rounded down to 1.',
  },
  {
    name: 'seed',
    key: '--seed',
    example: 0,
    defaultValue: 0,
    customValue: true,
    type: 'numberInput',
    max: 4294967295,
    min: 0,
    description:
      'The Midjourney bot uses a seed number to create a field of visual noise, like television static, as a starting point to generate the initial image grids. Seed numbers are generated randomly for each image but can be specified with the --seed parameter. If you use the same seed number and prompt, you will get similar final images. Seed accepts whole numbers 0–4294967295',
    placeholder: 'accepts whole numbers 0–4,29^9',
  },
  {
    name: 'stop',
    key: '--stop',
    example: 100,
    defaultValue: [0, 20, 40, 60, 80, 100],
    customValue: false,
    type: 'numberArray',
    max: 100,
    min: 0,
    description:
      'Use the --stop parameter to finish a Job partway through the process. Stopping a Job at an earlier percentage can create blurrier, less detailed results.',
  },
  {
    name: 'style',
    key: '--style',
    example: 'raw',
    defaultValue: [
      '--style raw',
      '--niji 5',
      '--niji 5 --style original',
      '--niji 5 --style cute',
      '--niji 5 --style expressive',
      '--niji 5 --style scenic',
    ],
    customValue: false,
    type: 'stringArray',
    max: null,
    min: null,
    description:
      'The --style parameter replaces the default aesthetic of some Midjourney Model Versions. Adding a style parameter can help you create more photo-realistic images, cinematic scenes, or cuter characters.',
  },
  {
    name: 'stylize',
    key: '--s',
    example: 100,
    defaultValue: [0, 50, 100, 250, 500, 750, 1000],
    customValue: false,
    type: 'numberArray',
    max: 0,
    min: 1000,
    description:
      'The Midjourney Bot has been trained to produce images that favor artistic color, composition, and forms. The --stylize or --s parameter influences how strongly this training is applied. Low stylization values produce images that closely match the prompt but are less artistic. High stylization values create images that are very artistic but less connected to the prompt.',
  },
  {
    name: 'tile',
    key: '--tile',
    example: null,
    defaultValue: null,
    customValue: false,
    type: 'stringInput',
    max: null,
    min: null,
    description:
      'The --tile parameter generates images that can be used as repeating tiles to create seamless patterns for fabrics, wallpapers and textures.',
    disable: true,
    placeholder: '--tile',
  },
  {
    name: 'weird',
    key: '--weird',
    example: 0,
    defaultValue: [0, 250, 500, 1000, 2000, 3000],
    customValue: false,
    type: 'numberArray',
    max: 3000,
    min: 0,
    description:
      'Explore unconventional aesthetics with the experimental --weird or --w parameter. This parameter introduces quirky and offbeat qualities to your generated images, resulting in unique and unexpected outcomes.',
  },
];

export const paramExpectMidjourney = [
  '--ar',
  '--aspect',
  '--c',
  '--chaos',
  '--no',
  '--q',
  '--quality',
  '--seed',
  '--stop',
  '--style',
  '--s',
  '--stylize',
  '--tile',
  '--weird',
  '--niji',
];
