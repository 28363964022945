import axios from 'axios';

import { configs } from '@/configs';
import { api } from '@/services';

interface SignatureAuthenPostData {
  signed: any;
  chain_id: any;
  nonce: string;
  keplr: boolean;
  username: string;
}

const withBaseURL = (url: string): string => `${configs.baseURL}/${url}`;

export const getNonce = async (addressKey: string) => {
  const response = await axios.get(withBaseURL(`providers/nonce/${addressKey}`));
  return response.data?.nonce;
};

export const signatureAuthen = async (
  data: SignatureAuthenPostData,
  signature: string,
  pubKey: string,
) => {
  const response = await axios.post(withBaseURL('auth/signature-authentication'), data, {
    headers: {
      Authorization: `Bearer ${signature} ${pubKey} true`,
    },
  });
  return response.data;
};

export const signInWithSso = async (accessToken: string) => {
  const response = await axios.post(withBaseURL('auth/sso-authentication'), { accessToken });
  return response.data;
};

export const refreshTokenApi = async (refreshToken: string) => {
  const response = await api.post('/auth/refreshToken', { refreshToken });
  return response.data;
};

export const getInfoOwner = async () => {
  const response = await api.get('/providers/me/profile');
  return response.data;
};

export const generateHeaderAuthByPrivateKey = async ({
  privateKey,
  data,
}: {
  privateKey: string;
  data: any;
}) => {
  const body = {
    privateKey,
    data,
  };
  const response = await api.post(withBaseURL('auth/generate-header-auth-by-privateKey'), body);
  return response.data;
};
