import type { ReactNode } from 'react';
import React from 'react';

interface SChipProps {
  children: ReactNode;
  className?: string;
}

const SChip: React.FC<
  SChipProps & React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>
> = ({
  children,
  className = 'font-semibold text-14 px-[6px] py-[10px] rounded-[100px]',
  ...props
}) => {
  return (
    <span className={`bg-gray-50 leading-[150%] text-gray-500 ${className}`} {...props}>
      {children}
    </span>
  );
};

export default SChip;
