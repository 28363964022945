import { configs } from '@/configs';
import { api } from '@/services';
import type { LinkSocialProfile } from '@/types/user';

export const getUserProfile = async () => {
  return await api.get('/providers/me/profile');
};

export const getUserProfileByAddress = async (address: string) => {
  return await api.get(`/providers/address/${address}`);
};

export interface DataUpdateProfile {
  username?: string;
  picture?: string;
  description?: string;
  job?: string;
  tags?: Array<string>;
  links?: LinkSocialProfile[];
}

export const updateInfoProfile = async (data: DataUpdateProfile) => {
  return await api.patch('/providers/me/profile', data);
};

export const updateCoverProfile = async (data: FormData) => {
  return await api.patch('/providers/me/profile/cover', data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const updateAvatarProfile = async (data: FormData) => {
  return await api.patch('/providers/me/profile/picture', data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const updateVerifyEmail = async (email: string) => {
  return await api.post('/providers/verify-email', { email });
};

export const updateConfirmEmail = async (code: string) => {
  return await api.post('/providers/confirm-email', { verificationCode: code });
};

export const checkAppliedReferral = async (address: string) => {
  return await api.get(`/providers/address/${address}/attached-referral`);
};

export const setCodeReferral = async (code: any) => {
  return await api.post(`/providers/referral/${code}`);
};

export const getReferralList = async (address: string) => {
  const res = await api.get(`/providers/referral/${address}`);
  return res.data;
};

export const setVerifyTwitter = async (code: string) => {
  const redirectUri = `${configs.ssoCallback}/update-profile`;
  return await api.post(`/providers/me/verify-twitter`, { code, redirectUri });
};

export const setVerifyDiscord = async (code: string) => {
  return await api.post(`/providers/update-discord`, { code });
};

export const getTwitterOauthUrl = () => {
  const redirectUri = `${configs.ssoCallback}/update-profile`;
  return `https://twitter.com/i/oauth2/authorize?response_type=code&client_id=${configs.twitterClientID}&redirect_uri=${redirectUri}&scope=tweet.read users.read follows.read follows.write&state=state&code_challenge=challenge&code_challenge_method=plain`;
};

export const getDiscordauthUrl = () => {
  const redirectUri = `${configs.ssoCallback}/update-profile`;
  return `https://discord.com/oauth2/authorize?client_id=${configs.discordClientID}&response_type=code&redirect_uri=${redirectUri}&scope=identify`;
};

export const getDiscordAuthUrlForCampaign = (taskID: string, userId: any) => {
  const url: any = configs.baseURL;
  const redirect_uri = `${encodeURIComponent(`${url}/campaigns/verify-discord`)}`;
  return `https://discord.com/oauth2/authorize?response_type=code&client_id=${configs.discordClientIdCampaign}&redirect_uri=${redirect_uri}&scope=identify+guilds+guilds.members.read&state=[${taskID},${userId}]`;
};

export const getTwitterTaskForCampaign = (taskId: number, userId: any, urlCallBack: string) => {
  const redirectUri = `${configs.baseURL}/campaigns/verify-task-twitter`;
  const data = `[${userId}, ${taskId}, "${urlCallBack}"]`;
  const decodedData = btoa(data);

  return `https://twitter.com/i/oauth2/authorize?response_type=code&client_id=${configs.twitterClientID}&redirect_uri=${redirectUri}&scope=follows.read+follows.write+offline.access+users.read+tweet.read+tweet.write+like.read+like.write&code_challenge=challenge&code_challenge_method=plain&state=${decodedData}`;
};

export const postDisconnectDiscord = async () => {
  return await api.post(`/providers/disconnect-discord`);
};

export const postSubmitVerify = async () => {
  return await api.post(`/providers/submit-verify`);
};
