import type { StdFee } from '@cosmjs/stargate';
import { toast } from 'react-toastify';

import { configs } from '@/configs';
import { ActionSSO, ssoExecuteMultiple } from '@/libs/ssoWallet';

interface InputRaws {
  data: { contractAddress: any; msg: any }[];
  fee?: StdFee;
}
// Check Approved
export const checkApproved = async (sender: string) => {
  const { marketplace1155, ow1155, marketplace, ow721 } = window.Wasm.contractAddresses;
  const result = {
    isApproved721: false,
    isApproved1155: false,
  };

  // 1155
  const isApprovedResult1155 = await window.Wasm.query(
    ow1155 || '',
    JSON.stringify({
      is_approved_for_all: {
        owner: sender,
        operator: marketplace1155,
      },
    }),
  );
  result.isApproved1155 = !!isApprovedResult1155?.data?.approved;

  // 721
  const isApprovedResult721 = await window.Wasm.query(
    ow721 || '',
    JSON.stringify({
      approved_for_all: {
        owner: sender,
      },
    }),
  );
  isApprovedResult721?.data?.operators?.forEach((item: any) => {
    if (item?.spender === marketplace) {
      result.isApproved721 = true;
    }
  });

  return result;
};

// Is Approved
export const isApproved = async (address: string, contractType: '1155' | '721' | 'all') => {
  const result = await checkApproved(address);
  switch (contractType) {
    case '1155':
      return result?.isApproved1155;
    case '721':
      return result?.isApproved721;
    case 'all':
      return !(!result?.isApproved721 || !result?.isApproved1155);
    default:
      return false;
  }
};

// Approve Account
export const approveAccount = async (sender: string, isGranted?: boolean) => {
  let resultApprove = true;
  try {
    const { marketplace1155, ow1155, marketplace, ow721 } = window.Wasm.contractAddresses;
    const result = await checkApproved(sender);

    if (!result?.isApproved721 || !result?.isApproved1155) {
      const inputRaws = [];
      const input721: { contractAddress: any; msg: any } = {
        contractAddress: ow721 || '',
        msg: {
          approve_all: { operator: marketplace || '' },
        },
      };
      const input1155: { contractAddress: any; msg: any } = {
        contractAddress: ow1155 || '',
        msg: {
          approve_all: { operator: marketplace1155 || '' },
        },
      };
      const fee = {
        granter: configs.grantAddress,
        amount: [
          {
            amount: '10000',
            denom: 'orai',
          },
        ],
        gas: '2000000',
      };
      inputRaws.push(input721, input1155);

      if (isGranted) {
        await window.Wasm.executeMultipleTransactionGrant(inputRaws, fee);
      } else {
        await window.Wasm.executeMultipleTransaction(inputRaws);
      }
    }
    return resultApprove;
  } catch (error: any) {
    resultApprove = false;
    toast.warn(error?.message);
    throw new Error('Cannot approve permission');
  }
};

// Approve Account SSO
export const approveAccountSso = async (sender: string, isGranted?: boolean) => {
  try {
    const { marketplace1155, ow1155, marketplace, ow721 } = window.Wasm.contractAddresses;
    const result = await checkApproved(sender);

    if (!result?.isApproved721 || !result?.isApproved1155) {
      const input721: { contractAddress: any; msg: any } = {
        contractAddress: ow721 || '',
        msg: {
          approve_all: { operator: marketplace },
        },
      };

      const input1155: { contractAddress: any; msg: any } = {
        contractAddress: ow1155 || '',
        msg: {
          approve_all: { operator: marketplace1155 },
        },
      };
      const fee: StdFee = {
        granter: configs.grantAddress,
        amount: [
          {
            amount: '10000',
            denom: 'orai',
          },
        ],
        gas: '2000000',
      };
      const inputRaws: InputRaws = {
        data: [input1155, input721],
      };
      if (isGranted) {
        inputRaws.fee = fee;
      }
      await ssoExecuteMultiple(inputRaws, ActionSSO.approveContractMarket);
    }
  } catch (error: any) {
    toast.warn(error?.message);
    throw new Error('Cannot approve permission');
  }
};
