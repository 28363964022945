import Lottie from 'lottie-react';
import mixpanel from 'mixpanel-browser';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

import {
  BgUpdatePlan,
  create_ai_model,
  CreditYellowHeaderIcon,
  IconFooterFixedBlog,
  IconFooterFixedDiscord,
  IconFooterFixedDocs,
  IconFooterFixedMedium,
  IconFooterFixedTelegram,
  IconFooterFixedX,
  IconRocket,
} from '@/assets';
import { MyLink } from '@/components';
import {
  BotTeleIcon,
  ChatIcon,
  DecorationIcon,
  ExploreIcon,
  FarmingIcon,
  HomeIcon,
  LeaderboardIcon,
  MarketplaceIcon,
  ModelIcon,
  PricingIcon,
  WorkflowIcon,
} from '@/components/assets';
import { MIXPANEL_TRACK } from '@/configs/constant';
import { useLayout } from '@/provider/LayoutProvider';
import useActiveMenu from './useActiveMenu';
import { useAuth } from '@/provider/UserProvider';
import { getMarketplaceURL } from './helpers';

const isProduction = process.env.NEXT_PUBLIC_NODE_ENV === 'production';
export const MARKETPLACE_SUB_NAME = isProduction ? 'marketplace' : 'marketplace-staging';

export enum SIDEBAR_MENU_KEY {
  Home = 'home',
  Chat = 'chat',
  Explore = 'explore',
  AiModel = 'ai-model',
  Workflow = 'workflow',
  BotTele = 'bot-tele',
  Farming = 'farming',
  Pricing = 'pricing',
  Leaderboard = 'leaderboard',
  DailyEarn = 'daily-earn',
  Marketplace = 'marketplace',
  Shop = 'shop',
}

export const SIDEBAR_MENU_LINK = {
  home: '/',
  chat: '/chat',
  explore: '/template',
  aiModel: '/ai-model',
  workflow: '/showcase',
  botTele: 'https://t.me/aiRightAI_prod_bot',
  farming: '/farming',
  pricing: '/pricing',
  leaderboard: '/leaderboard',
  dailyearn: '/attendance',
  marketplace: `https://${MARKETPLACE_SUB_NAME}.airight.io`,
  shop: '/shop',
};

export const SIDEBAR_MENU = [
  {
    key: SIDEBAR_MENU_KEY.Home,
    link: SIDEBAR_MENU_LINK.home,
    text: 'Home',
    icon: <HomeIcon />,
    iconActive: <HomeIcon color="#DA613A" />,
    target: '_self',
  },
  {
    key: SIDEBAR_MENU_KEY.Chat,
    link: SIDEBAR_MENU_LINK.chat,
    text: 'Chat',
    icon: <ChatIcon />,
    iconActive: <ChatIcon color="#DA613A" />,
    target: '_self',
  },
  {
    key: SIDEBAR_MENU_KEY.Explore,
    link: SIDEBAR_MENU_LINK.explore,
    text: 'Explore',
    icon: <ExploreIcon />,
    iconActive: <ExploreIcon color="#DA613A" />,
    target: '_self',
  },
  {
    key: SIDEBAR_MENU_KEY.Workflow,
    link: SIDEBAR_MENU_LINK.workflow,
    text: 'AI Tools',
    icon: <WorkflowIcon />,
    iconActive: <WorkflowIcon color="#DA613A" />,
    target: '_self',
  },
  {
    key: SIDEBAR_MENU_KEY.AiModel,
    link: SIDEBAR_MENU_LINK.aiModel,
    text: 'Models',
    icon: <ModelIcon />,
    iconActive: <ModelIcon color="#DA613A" />,
    target: '_self',
  },
  {
    key: SIDEBAR_MENU_KEY.BotTele,
    link: SIDEBAR_MENU_LINK.botTele,
    text: 'Telegram Bot',
    icon: <BotTeleIcon />,
    iconActive: <BotTeleIcon color="#DA613A" />,
    target: '_blank',
  },
  {
    key: SIDEBAR_MENU_KEY.Marketplace,
    link: SIDEBAR_MENU_LINK.marketplace,
    text: 'Marketplace',
    icon: <MarketplaceIcon />,
    iconActive: <MarketplaceIcon color="#DA613A" />,
    target: '_blank',
  },
  {
    key: SIDEBAR_MENU_KEY.Shop,
    link: SIDEBAR_MENU_LINK.shop,
    text: 'Shop',
    icon: <DecorationIcon />,
    iconActive: <DecorationIcon color="#DA613A" />,
    target: '_self',
  },
  {
    key: SIDEBAR_MENU_KEY.Farming,
    link: SIDEBAR_MENU_LINK.farming,
    text: 'Farming',
    icon: <FarmingIcon />,
    iconActive: <FarmingIcon color="#DA613A" />,
    target: '_self',
  },
  {
    key: SIDEBAR_MENU_KEY.Pricing,
    link: SIDEBAR_MENU_LINK.pricing,
    text: 'Pricing',
    icon: <PricingIcon />,
    iconActive: <PricingIcon color="#DA613A" />,
    target: '_self',
  },
  {
    key: SIDEBAR_MENU_KEY.Leaderboard,
    link: SIDEBAR_MENU_LINK.leaderboard,
    text: 'Leaderboard',
    icon: <LeaderboardIcon />,
    iconActive: <LeaderboardIcon color="#DA613A" />,
    target: '_self',
  },
  {
    key: SIDEBAR_MENU_KEY.DailyEarn,
    link: SIDEBAR_MENU_LINK.dailyearn,
    text: 'Daily Earn',
    icon: <CreditYellowHeaderIcon className="scale-75" />,
    iconActive: <CreditYellowHeaderIcon className="scale-75" />,
    target: '_self',
  },
];

const Sidebar: React.FC<{
  isOpen: boolean;
}> = ({ isOpen }) => {
  const { setIsLoadingBar } = useLayout();
  const { isLoginClient, auth } = useAuth();
  const { isActive } = useActiveMenu();

  const handleClickMenu = (e: any, key: SIDEBAR_MENU_KEY, link: string, target: string) => {
    if (key === SIDEBAR_MENU_KEY.Marketplace) {
      e.preventDefault();
      window.open(isLoginClient ? getMarketplaceURL(auth.isOwallet || false) : link, '_blank');
    } else if (target !== '_blank') {
      setIsLoadingBar(true);
    }
  };

  return (
    <div
      aria-checked={isOpen}
      className="fixed -left-[210px] bottom-0 top-[60px] z-[49] w-[210px] border-r-1 border-gray-200 bg-white px-4 py-5 transition-all aria-checked:left-0"
    >
      <div className="flex h-full flex-col justify-between">
        <ul className="max-h-[100%] flex-1 overflow-y-auto overflow-x-hidden scrollbar-hide">
          {SIDEBAR_MENU.filter((item) => item.key !== SIDEBAR_MENU_KEY.Pricing).map((menu) => (
            <li key={menu.key} className="my-1">
              <Link
                aria-checked={isActive(menu.link)}
                target={menu.target}
                onClick={(e) => handleClickMenu(e, menu.key, menu.link, menu.target)}
                className="flex items-center rounded-[6px] px-[10px] py-3 text-[16px] font-semibold text-gray-800 aria-checked:bg-orange-50 aria-checked:text-orange-500"
                href={menu.link}
              >
                <span className="w-[25px]">
                  {isActive(menu.link) ? menu.iconActive : menu.icon}
                </span>{' '}
                <span className="ml-3">{menu.text}</span>
              </Link>
            </li>
          ))}
        </ul>
        <div className="mt-10">
          <div className="relative rounded-lg p-5 pb-3 pt-[44px] text-center">
            <Image
              className="absolute left-0 top-0 h-full w-full rounded-lg object-cover"
              src={BgUpdatePlan}
              alt="background"
            />
            <IconRocket className="absolute -top-[44%] left-[50%] -translate-x-[50%] scale-75" />
            <Lottie
              className="absolute -top-[44%] left-[50%] -translate-x-[50%] scale-75"
              animationData={create_ai_model}
              loop
            />
            <div className="relative">
              <p className="mb-1 whitespace-nowrap text-[10px] text-black">
                Get unlimited generations
              </p>
              <Link
                style={{
                  background:
                    'linear-gradient(267deg, #DB8FFF -8.45%, #FF62AB 49.67%, #FFB090 102.02%)',
                }}
                className="block w-full rounded-full px-2 py-[6px] text-[14px] font-semibold text-white"
                onClick={() => {
                  mixpanel.track(MIXPANEL_TRACK.UPGRADE_PRICING);
                  setIsLoadingBar(true);
                }}
                href={`${SIDEBAR_MENU_LINK.pricing}`}
              >
                Upgrade
              </Link>
            </div>
          </div>
          <div className="mt-5 flex justify-around gap-3">
            <MyLink href="https://blog.airight.io/">
              <IconFooterFixedBlog />
            </MyLink>
            <MyLink href="https://medium.com/airight">
              <IconFooterFixedMedium />
            </MyLink>
            <MyLink href="https://t.me/airight_io">
              <IconFooterFixedTelegram />
            </MyLink>
            <MyLink href="https://twitter.com/aiRight_io">
              <IconFooterFixedX />
            </MyLink>
            <MyLink href="https://discord.gg/3cct9Dbv">
              <IconFooterFixedDiscord />
            </MyLink>
            <MyLink href="https://docs.airight.io/">
              <IconFooterFixedDocs />
            </MyLink>
          </div>
          <p className="mt-4 text-[10px] leading-[16px] text-gray-500">
            ©2020 - 2023 Oraichain Labs. <br /> All rights reserved. Privacy Policy
          </p>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
