import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from '@nextui-org/react';
import Rive from '@rive-app/react-canvas';
import dynamic from 'next/dynamic';
import { usePathname } from 'next/navigation';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import {
  HeartBuyPromptActiveIcon,
  HeartBuyPromptIcon,
  IconMore,
  LogoImage,
} from '@/assets';
import { Images } from '@/components';
import { Buttons, ShareButton } from '@/components/button';
import ClientOnly from '@/components/client-only';
import RenderWhen from '@/components/RenderWhen';
import { useAuth } from '@/provider/UserProvider';
import { getAssetWithId, likeAsset, unLikeAsset } from '@/services';
import type { Prompt } from '@/types/prompt';
import { formatNumberSocial, getURL, isRivFile, useSMutation } from '@/utils';
import ViewFullMedia from '../view-media';

const DynamicNsfwMark = dynamic(() => import('../NsfwMark'));
interface BannerPromptDetailProps {
  prompt: Prompt;
  isSelling?: boolean;
  isOwner?: boolean;
  onOpenStopSell?: () => void;
}

const BannerPromptDetail: React.FC<BannerPromptDetailProps> = ({
  prompt,
  isSelling,
  isOwner,
  onOpenStopSell,
}) => {
  const [isLike, setIsLike] = useState(false);
  const [numberLike, setNumberLike] = useState(0);
  const pathname = usePathname();
  const isNsfw = prompt?.asset?.isNsfw;
  const { isLogin } = useAuth();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState('');
  const [currentType, setCurrentType] = useState('');

  const openModal = (src: any, type: any) => {
    setIsModalOpen(true);
    setCurrentImage(src);
    setCurrentType(type);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentImage('');
    setCurrentType('');
  };

  const { mutate: mutateLike } = useSMutation({ mutationFn: likeAsset });
  const { mutate: mutateUnlike } = useSMutation({
    mutationFn: unLikeAsset,
  });

  const getAssetWhenLogin = async () => {
    try {
      const res = await getAssetWithId(prompt.asset.id);
      if (res && res.data !== undefined) {
        if (!isLogin) setIsLike(false);
        else setIsLike(res.data.attributes.isLiked);

        setNumberLike(res.data.attributes.numberOfLikes);
      } else {
        console.error('Invalid response format from getAssetWithId');
      }
    } catch (error) {
      console.error('Error fetching asset');
    }
  };

  const handleUnlike = () => {
    if (!isLogin) return toast.error('You must login to continue!');
    if (prompt?.asset?.id) {
      setIsLike(!isLike);
      mutateUnlike(prompt.asset.id);
      setNumberLike((val) => val - 1);
    }
  };

  const handleLike = () => {
    if (!isLogin) return toast.error('You must login to continue!');
    if (prompt?.asset?.id) {
      setIsLike(!isLike);
      mutateLike(prompt.asset.id);
      setNumberLike((val) => val + 1);
    }
  };

  useEffect(() => {
    if (prompt?.asset?.id) {
      getAssetWhenLogin();
    }
  }, [isLogin]);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        closeModal();
      }
    };

    if (isModalOpen) {
      document.addEventListener('keydown', handleKeyPress);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [isModalOpen]);

  return (
    <div className="shadow-2 w-[45%] rounded-[36px] p-4 md:mb-10 md:w-full">
      <RenderWhen>
        <RenderWhen.If isTrue={Boolean(prompt?.url || prompt?.avatar)}>
          {isRivFile(prompt?.url) ? (
            <div className="h-[500px] w-full overflow-hidden rounded-[28px] sm:h-[300px]">
              <Rive src={prompt?.url} stateMachines="state-machine" />
            </div>
          ) : (
            <div className="relative max-h-[638px] max-w-[638px] overflow-hidden rounded-[28px]">
              <Images
                className="max-h-[638px] max-w-[638px] cursor-pointer rounded-[28px] object-contain"
                src={prompt?.url ?? prompt?.avatar}
                alt="image"
                onClick={() => openModal(prompt?.url, prompt?.urlMimeType)}
              />
              {isNsfw && <DynamicNsfwMark url={prompt?.url ?? prompt?.avatar} />}
            </div>
          )}
        </RenderWhen.If>
        <RenderWhen.If isTrue={!prompt?.url && !prompt?.avatar}>
          <div className="flex h-[638px] w-full items-center justify-center rounded-lg bg-gray-50 lg:h-[300px] lg:w-full">
            <LogoImage />
          </div>
        </RenderWhen.If>
      </RenderWhen>
      <div className="mb-2 mt-5 flex h-full items-center justify-between">
        <div className="flex items-center">
          <div className="mr-8 flex flex-col">
            <span className="text-20 font-bold">{formatNumberSocial(prompt.asset?.totalView)}</span>
            <span className="text-14 font-normal text-gray-500">Views</span>
          </div>
          <div className="flex flex-col">
            <span className="text-20 font-bold">{formatNumberSocial(numberLike)}</span>
            <span className="text-14 font-normal text-gray-500">Likes</span>
          </div>
        </div>
        <div className="flex items-center">
          <Buttons
            onClick={isLike ? handleUnlike : handleLike}
            typeBtn={isLike ? 'secondary' : 'cancel'}
            className={`mr-3 h-[55px] w-[55px] min-w-0 rounded-[100px] p-0 ${
              !isLike ? 'bg-gray-100' : ''
            }`}
          >
            {isLike ? <HeartBuyPromptActiveIcon /> : <HeartBuyPromptIcon />}
          </Buttons>
          <div className="[&>button]:mb-0">
            <ShareButton
              pathname={pathname}
              titleShare={`My artwork with #aiRight: \n${prompt.name ?? '--'} - ${getURL(
                pathname,
              )} \n$ORAI $AIRI #NFT`}
            />
          </div>
          <ClientOnly>
            <RenderWhen>
              <RenderWhen.If isTrue={isSelling && isOwner}>
                <div className="ml-3">
                  <div>
                    <Dropdown>
                      <DropdownTrigger>
                        <Button className="m-0 h-fit min-w-0 rounded-full bg-gray-100 p-[18px]">
                          <IconMore />
                        </Button>
                      </DropdownTrigger>
                      <DropdownMenu aria-label="Static Actions">
                        <DropdownItem onClick={onOpenStopSell} key="stop-selling">
                          Stop selling prompt
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </div>
              </RenderWhen.If>
            </RenderWhen>
          </ClientOnly>
        </div>
      </div>
      {currentType.includes('image') && (
        <ViewFullMedia 
          isOpen={isModalOpen}
          onClose={closeModal}
          url={currentImage}
        />
      )}
    </div>
  );
};

export default BannerPromptDetail;
