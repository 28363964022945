import {
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Textarea,
} from '@nextui-org/react';
import { isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { IconClose } from '@/assets';
import { Buttons, SelectMultipleWithSearch } from '@/components';
import MaxLengthInput from '@/components/max-length-input';
import useLoadTag from '@/hooks/useLoadTag';
import type { IUpdateAssetTagsById } from '@/services';
import { updateAssetAfterMint, updateAssetTagsById } from '@/services';
import type { ITag } from '@/types/asset';

const UpdateArtwork: React.FC<{
  isOpen: boolean;
  onOpenChange: () => void;
  onClose: () => void;
  callback?: () => void;
  data: { id: number; name: string; description: string; tags: ITag[] };
}> = ({ isOpen, onOpenChange, onClose, data, callback }) => {
  const { id, name, description, tags } = data;
  const [loading, setLoading] = useState(false);

  const {
    register,
    setValue,
    watch,
    formState: { errors },
    handleSubmit: handleSubmitForm,
    reset,
  } = useForm();
  const { loadTagOptions, mapOptionTags } = useLoadTag();

  const nameWatch = watch('name', name);
  const descriptionWatch = watch('description', description);

  useEffect(() => {
    reset({
      name,
      description,
      tags: tags.map((t) => ({ content: t.content })),
    });
  }, [data]);

  const onSubmit = async (data: any) => {
    if (!id) return;
    setLoading(true);
    try {
      const hasUpdateArtwork = name !== data.name || description !== data.description;
      if (hasUpdateArtwork)
        await updateAssetAfterMint(id, { name: data.name, description: data.description });
      const hasUpdateTag = !isEqual(
        data.tags,
        tags.map((t) => ({ content: t.content })),
      );
      if (data.tags && hasUpdateTag) {
        const argAssetTags: IUpdateAssetTagsById = {
          id,
          data: {
            tags: data.tags,
          },
        };
        await updateAssetTagsById(argAssetTags);
      }
      toast.success('Updated artwork successfully!');
      if (callback) callback();
      onClose();
    } catch (error: any) {
      toast.error(error?.message);
    }
    setLoading(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      hideCloseButton
      scrollBehavior="inside"
      placement="center"
    >
      <ModalContent className="min-w-[500px] rounded-[32px] sm:min-w-[0] sm:rounded-[12px]">
        {(onClose) => (
          <>
            <ModalHeader className="flex items-center justify-between p-10 pb-8 sm:p-4">
              <span className="text-[24px] font-[600] sm:text-[16px]">Artwork details</span>
              <div
                onClick={onClose}
                className="flex h-[32px] w-[32px] cursor-pointer items-center justify-center rounded-[8px] bg-gray-100"
              >
                <IconClose />
              </div>
            </ModalHeader>
            <ModalBody className="px-[40px] sm:px-4">
              <div>
                <div className="mb-2">
                  <div className="mb-2 text-[14px] font-[600] text-black sm:text-[13px]">
                    Name the artwork <span className="text-red-600">*</span>
                  </div>
                  <div>
                    <Input
                      classNames={{
                        inputWrapper: 'rounded-[100px] bg-gray-50 px-[14px]',
                      }}
                      required
                      maxLength={60}
                      defaultValue={name || ''}
                      placeholder="e.g. my first AI artwork"
                      {...register('name', {
                        required: 'Name is required',
                      })}
                    />
                    <div
                      className={`mt-1 flex h-[20px] ${
                        errors.name ? 'justify-between' : 'justify-end'
                      }`}
                    >
                      {errors.name && (
                        <div className="text-[13px] text-red-500 sm:text-[12px]">
                          {errors.name?.message as string}
                        </div>
                      )}
                      <MaxLengthInput max={60} length={nameWatch?.length || 0} />
                    </div>
                  </div>
                </div>
                <div className="mb-2">
                  <div className="mb-2 text-[14px] font-[600] text-black sm:text-[13px]">
                    Write a story <span className="text-red-600">*</span>
                  </div>
                  <div>
                    <Textarea
                      classNames={{
                        inputWrapper: 'rounded-[16px] bg-gray-50 px-[14px] py-[2px]',
                        label: 'hidden',
                      }}
                      {...register('description', {
                        required: 'Description is required',
                      })}
                      maxLength={500}
                      placeholder="The story is as important as the art itself. Write a story for the artwork to make it even more attractive"
                      defaultValue={description || ''}
                      minRows={7}
                    />
                    <div
                      className={`mt-1 flex h-[20px] ${
                        errors.description ? 'justify-between' : 'justify-end'
                      }`}
                    >
                      {errors.description && (
                        <div className="text-[13px] text-red-500 sm:text-[12px]">
                          {errors.description?.message as string}
                        </div>
                      )}
                      <MaxLengthInput max={500} length={descriptionWatch?.length || 0} />
                    </div>
                  </div>
                </div>
                <div className="mb-2">
                  <div className="mb-2 text-[14px] font-[600] text-black sm:text-[13px]">
                    Tag
                    <div className="text-[13px] font-[400] text-gray-500 sm:text-[12px]">
                      Max 5 tags
                    </div>
                  </div>
                  <div>
                    <SelectMultipleWithSearch
                      onChangeValue={(val) =>
                        setValue(
                          'tags',
                          val.map((v) => ({ content: v.label })),
                        )
                      }
                      defaultValue={mapOptionTags(tags || [])}
                      loadOptions={loadTagOptions}
                      placeholder="Search option"
                      menuPlacement="top"
                    />
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter className="block px-[40px] py-[30px] sm:px-4">
              <div className="flex items-center justify-end">
                <Buttons onClick={onClose} typeBtn="cancel">
                  Cancel
                </Buttons>
                <Buttons
                  typeBtn="primary"
                  className="ml-3"
                  isLoading={loading}
                  onClick={handleSubmitForm(onSubmit)}
                >
                  Save
                </Buttons>
              </div>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default UpdateArtwork;
