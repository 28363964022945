'use client';

import React, { createContext, useContext, useMemo, useState } from 'react';

type InpaintModelProviderProps = {
  children: React.ReactNode;
};

type Dimension = {
  width: number;
  height: number;
};

export interface InpaintModelState {
  imageImport: string;
  setImageImport: React.Dispatch<React.SetStateAction<string>>;
  brushRadius: number;
  setBrushRadius: React.Dispatch<React.SetStateAction<number>>;
  dimensions: Dimension;
  setDimensions: React.Dispatch<React.SetStateAction<Dimension>>;
}

export const initialStateLayout: InpaintModelState = {
  imageImport: '',
  setImageImport: () => {},
  brushRadius: 20,
  setBrushRadius: () => {},
  dimensions: { width: 0, height: 0 },
  setDimensions: () => {},
};

const InpaintModelContext = createContext(initialStateLayout);

export const InpaintModelProvider: React.FC<InpaintModelProviderProps> = ({ children }) => {
  const [imageImport, setImageImport] = useState<string>('');
  const [brushRadius, setBrushRadius] = useState<number>(20);
  const [dimensions, setDimensions] = useState<Dimension>({ width: 0, height: 0 });

  const contextValue: InpaintModelState = useMemo(() => {
    return {
      imageImport,
      setImageImport,
      brushRadius,
      setBrushRadius,
      dimensions,
      setDimensions,
    };
  }, [imageImport, setImageImport, brushRadius, setBrushRadius, dimensions, setDimensions]);

  return (
    <InpaintModelContext.Provider value={contextValue}>{children}</InpaintModelContext.Provider>
  );
};

export const useInpaintModel = () => useContext(InpaintModelContext);
