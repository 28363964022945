import { Bech32Address } from '@keplr-wallet/cosmos';
import { KeplrWalletConnectV1 as OWalletWalletConnectV1 } from '@keplr-wallet/wc-client';
import { KeplrQRCodeModalV1 as OWalletQRCodeModalV1 } from '@keplr-wallet/wc-qrcode-modal';
import { isAndroid, isMobile } from '@walletconnect/browser-utils';
import WalletConnect from '@walletconnect/client';
import Axios from 'axios';
import createHash from 'create-hash';

import { configs } from '@/configs';
import { embedChainInfos } from '@/configs/chainInfos';
import { mobileBlacklistNetworks, network } from '@/configs/network';

const hash160 = (buffer: any) => {
  const t = createHash('sha256').update(buffer).digest();
  return createHash('rmd160').update(t).digest();
};

const sendTx = async (tx: any, mode: any) => {
  const restInstance = Axios.create({
    baseURL: configs.lcdURL,
  });

  const isProtoTx = Buffer.isBuffer(tx) || tx instanceof Uint8Array;

  const params = isProtoTx
    ? {
        tx_bytes: Buffer.from(tx).toString('base64'),
        mode: (() => {
          switch (mode) {
            case 'async':
              return 'BROADCAST_MODE_ASYNC';
            case 'block':
              return 'BROADCAST_MODE_BLOCK';
            case 'sync':
              return 'BROADCAST_MODE_SYNC';
            default:
              return 'BROADCAST_MODE_UNSPECIFIED';
          }
        })(),
      }
    : {
        tx,
        mode,
      };

  const result = await restInstance.post(isProtoTx ? ' /cosmos/tx/v1beta1/txs' : '/txs', params);

  const txResponse = isProtoTx ? result.data.tx_response : result.data;

  if (txResponse.code != null && txResponse.code !== 0) {
    throw new Error(txResponse.raw_log);
  }

  return Buffer.from(txResponse.txhash, 'hex');
};
export default class Keplr {
  walletConnector: any;

  constructor() {
    this.walletConnector = {};
  }

  suggestChain = async (chainId: never) => {
    if (!window.keplr) return;
    const chainInfo = embedChainInfos.find((chainInfo) => chainInfo.chainId === chainId);
    if (!isMobile()) {
      if (chainInfo) {
        await window.keplr.experimentalSuggestChain(chainInfo);
      }
      await window.keplr.enable(chainId);
    } else if (!mobileBlacklistNetworks.includes(chainId)) {
      await window.keplr.enable(chainId);
    }
  };

  onWalletConnectDisconnected = (error: Error) => {
    if (error) {
      console.log(error);
    } else {
      this.disconnect();
    }
  };

  /**
   * Disconnect the wallet regardless of wallet type (extension, wallet connect)
   */
  disconnect() {
    if (this.walletConnector) {
      if (this.walletConnector.connected) {
        this.walletConnector.killSession();
      }
      this.walletConnector = undefined;
    }
  }

  async getMobileKeplr() {
    if (!this.walletConnector) {
      this.walletConnector = new WalletConnect({
        bridge: 'https://bridge.walletconnect.org',
        storageId: 'keplr',
        signingMethods: [],
        qrcodeModal: new OWalletQRCodeModalV1(),
      });
      // this.walletConnector._clientMeta = {
      //   name: 'Oraichain',
      //   description: 'Oraichain is the first IBC-native Cosmos interchain AMM',
      //   url: 'https://oraidex.io',
      //   icons: ['https://dhj8dql1kzq2v.cloudfront.net/keplr-256x256.png']
      // };
      this.walletConnector.on('disconnect', this.onWalletConnectDisconnected);
    }

    //
    if (!this.walletConnector.connected) {
      try {
        await this.walletConnector.connect();
      } catch (e) {
        return undefined;
      }
    }

    // @ts-ignore
    return new OWalletWalletConnectV1(this.walletConnector, {
      sendTx,
      onBeforeSendRequest: this.cnBeforeSendRequest, // onBeforeSendRequest
    });
  }

  public getFixedSignDoc(chainId: String): Object {
    return {
      bodyBytes: Uint8Array.from([
        10, 61, 10, 28, 47, 99, 111, 115, 109, 111, 115, 46, 98, 97, 110, 107, 46, 118, 49, 98, 101,
        116, 97, 49, 46, 77, 115, 103, 83, 101, 110, 100, 18, 29, 10, 6, 102, 111, 111, 98, 97, 114,
        18, 6, 102, 111, 111, 98, 97, 114, 26, 11, 10, 6, 102, 111, 111, 98, 97, 114, 18, 1, 48, 18,
        6, 115, 117, 98, 109, 105, 116,
      ]),
      authInfoBytes: Uint8Array.from([
        10, 78, 10, 70, 10, 31, 47, 99, 111, 115, 109, 111, 115, 46, 99, 114, 121, 112, 116, 111,
        46, 115, 101, 99, 112, 50, 53, 54, 107, 49, 46, 80, 117, 98, 75, 101, 121, 18, 35, 10, 33,
        2, 92, 51, 66, 167, 70, 56, 216, 64, 133, 48, 180, 69, 85, 89, 166, 158, 108, 171, 124, 137,
        250, 106, 100, 171, 219, 241, 112, 201, 253, 156, 117, 58, 18, 4, 10, 2, 8, 1, 18, 15, 10,
        9, 10, 4, 111, 114, 97, 105, 18, 1, 48, 16, 192, 154, 12,
      ]),
      chainId,
      accountNumber: 0,
    };
  }

  public getFixedAminoSignDoc(signer: string, data: any): Object {
    return {
      chain_id: '',
      account_number: '0',
      sequence: '0',
      fee: {
        gas: '0',
        amount: [],
      },
      msgs: [
        {
          type: 'sign/MsgSignData',
          value: {
            data,
            signer,
          },
        },
      ],
      memo: '',
    };
  }

  cnBeforeSendRequest = (request: any) => {
    if (!isMobile()) {
      return;
    }

    const deepLink = isAndroid()
      ? 'intent://wcV1#Intent;packages=com.chainapsis.keplr;scheme=keplrwallet;end;'
      : 'keplrwallet://wcV1';

    switch (request.method) {
      case 'keplr_enable_wallet_connect_v1':
        if (
          request.params &&
          request.params.length === 1 &&
          request.params[0] === network?.chainId
        ) {
          break;
        }
        window.location.href = deepLink;
        break;
      case 'keplr_sign_amino_wallet_connect_v1':
        window.location.href = deepLink;
        break;
      default:
        break;
    }
  };

  async getKeplr() {
    if (isMobile()) {
      // @ts-ignore
      if (!window.keplr) {
        const keplr = await this.getMobileKeplr();
        // @ts-ignore
        if (keplr) window.keplr = keplr;
      }
      // @ts-ignore
      return window.keplr;
    }

    if (document.readyState === 'complete') {
      // @ts-ignore
      return window.keplr;
    }

    return new Promise((resolve) => {
      const documentStateChange = (event: any) => {
        if (
          event.target &&
          // @ts-ignore
          event.target.readyState === 'complete'
        ) {
          // @ts-ignore
          resolve(window.keplr);
          document.removeEventListener('readystatechange', documentStateChange);
        }
      };

      document.addEventListener('readystatechange', documentStateChange);
    });
  }

  // @ts-ignore
  async getKeplrKey(chainId) {
    chainId = chainId ?? network?.chainId;
    // chainId = "Oraichain"
    if (!chainId) return undefined;
    const keplr = await this.getKeplr();
    if (keplr) {
      return keplr.getKey(chainId);
    }
    return undefined;
  }

  async getKeplrAddr(chainId: never) {
    const key = await this.getKeplrKey(chainId);
    return key?.bech32Address;
  }

  async getKeplrPubKey(chainId: never) {
    const key = await this.getKeplrKey(chainId);
    return key?.pubKey;
  }

  async getKeplrBech32Address(chainId: never) {
    const pubkey = await this.getKeplrPubKey(chainId);

    if (!pubkey) return undefined;
    const address = hash160(pubkey);
    return new Bech32Address(address);
  }
}
