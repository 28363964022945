import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  useDisclosure,
} from '@nextui-org/react';
import React from 'react';

import { IconClose, IconMore } from '@/assets';
import { Buttons } from '@/components';
import type { IAssetDetail } from '@/types/asset';
import { currencyFormatter } from '@/utils/currency';

import useWithdraw from './useWithdraw';

const StopSellArtwork: React.FC<{
  asset: IAssetDetail;
  refresh: () => void;
}> = ({ asset, refresh }) => {
  const { isOpen, onOpenChange, onOpen, onClose } = useDisclosure();
  const { handleWithdraw, loading } = useWithdraw(asset.id, refresh, onClose);

  const listMoreAction = [
    {
      key: 'stop',
      title: 'Stop selling artwork',
      onClick: onOpen,
    },
  ];

  return (
    <div className="lg:mt-10 sm:w-full">
      <div className="flex w-[340px] justify-between rounded-[27px] border-1 border-gray-100 p-[24px] sm:rounded-[16px] sm:p-[15px]">
        <div>
          <p className="mb-2 text-[16px] font-semibold text-gray-500 sm:text-[14px]">
            Outright purchase price
          </p>
          <div
            className="text-[40px] font-bold leading-none text-black"
            style={{ wordBreak: 'break-word' }}
          >
            {currencyFormatter(asset?.offer?.amount || 0)}
            {asset.offer?.denom === 'AIRI' ? <span> AIRI</span> : <span>$</span>}
          </div>
        </div>
        <div>
          <Dropdown>
            <DropdownTrigger>
              <div className="cursor-pointer">
                <IconMore />
              </div>
            </DropdownTrigger>
            <DropdownMenu aria-label="Static Actions">
              {listMoreAction.map((item) => (
                <DropdownItem onClick={() => item.onClick()} key={item.key}>
                  {item.title}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        hideCloseButton
        scrollBehavior="inside"
        placement="center"
      >
        <ModalContent className="min-w-[370px] rounded-[20px]">
          {(onClose) => (
            <>
              <ModalHeader className="flex items-center justify-between p-8 pb-0 sm:p-5">
                <span className="text-[24px] font-[600] sm:text-[18px]">Stop selling artwork</span>
                <div
                  onClick={() => {
                    onClose();
                  }}
                  className="flex h-[32px] w-[32px] cursor-pointer items-center justify-center rounded-[8px] bg-gray-100"
                >
                  <IconClose />
                </div>
              </ModalHeader>
              <ModalBody className="gap-0 px-[32px] py-0 sm:px-5">
                <div className="mt-5 text-gray-500">
                  Are you sure you want to stop selling this artwork on Marketplace?
                </div>
                <div className="my-8 flex items-center justify-end">
                  <Buttons onClick={onClose} typeBtn="cancel">
                    Cancel
                  </Buttons>
                  <Buttons
                    isLoading={loading}
                    onClick={() =>
                      asset?.offer && handleWithdraw({ offer: asset.offer, gas: 4000000, fees: 0 })
                    }
                    typeBtn="primary"
                    className="ml-3"
                  >
                    Confirm
                  </Buttons>
                </div>
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
};

export default StopSellArtwork;
