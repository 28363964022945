import {
  cn,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Radio,
  RadioGroup,
  Spinner,
} from '@nextui-org/react';
import React, { useEffect } from 'react';

import { IconClose, IconPlusPrimary } from '@/assets';
import { Buttons, Images } from '@/components';
import AddNewCollection from '@/contents/user-profile/content/collection/CreateCollection';

import NoData from '../NoData';
import RenderWhen from '../RenderWhen';
import useAddCollection from './useAddCollection';

export const CustomRadio = ({ children, value }: { children: any; value: string }) => {
  return (
    <Radio
      value={value}
      classNames={{
        base: cn(
          'inline-flex p-0 mb-4 items-center justify-between',
          'flex-row-reverse w-full max-w-full cursor-pointer',
        ),
        control: 'rounded-[50%]',
        wrapper: 'rounded-[50%] border-gray-400',
      }}
    >
      {children}
    </Radio>
  );
};

const AddCollectionModal: React.FC<{
  isOpen: boolean;
  onOpenChange: () => void;
  assetId: number;
  onClose: () => void;
}> = ({ isOpen, onOpenChange, assetId, onClose }) => {
  const {
    onAddCollectionId,
    collectId,
    onAddNewCollection,
    reset,
    collections,
    loadingList,
    loading,
    setRefreshGetCollection,
    handleAddToCollection,
    setCollectId,
    setIsAddNew,
    isAddNew,
  } = useAddCollection();

  const onSubmit = async () => {
    await handleAddToCollection(assetId);
    onClose();
    reset();
  };

  useEffect(() => {
    if (isAddNew) onSubmit();
  }, [isAddNew]);

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      hideCloseButton
      scrollBehavior="inside"
      placement="center"
      onClick={(e) => e.stopPropagation()}
    >
      <ModalContent className="min-w-[500px] rounded-[32px] sm:min-w-0 sm:rounded-[16px]">
        {(onClose) => (
          <>
            <ModalHeader className="flex items-center justify-between p-10 pb-8 sm:p-5">
              <span className="text-[24px] font-[600] sm:text-[18px]">Add to collection</span>
              <Buttons
                onClick={() => {
                  onClose();
                  reset();
                  setCollectId('');
                }}
                className="flex h-[32px] w-[32px] min-w-0 cursor-pointer items-center justify-center rounded-[8px] bg-gray-100 p-0"
              >
                <IconClose />
              </Buttons>
            </ModalHeader>
            <ModalBody className="max-h-[350px] min-h-[200px] px-[40px] sm:px-5">
              <RenderWhen>
                <RenderWhen.If isTrue={collections.length > 0}>
                  <RadioGroup value={collectId} onValueChange={(val) => onAddCollectionId(val)}>
                    {collections.map((collection, index) => (
                      <CustomRadio key={index} value={collection.id.toString()}>
                        <div className="flex items-center">
                          <div className="h-[60px] w-[60px] overflow-hidden rounded-[12px] bg-gray-100">
                            <RenderWhen>
                              <RenderWhen.If isTrue={Boolean(collection.thumbnail)}>
                                <Images
                                  src={collection.thumbnail}
                                  className="h-full w-full object-cover"
                                  alt="collection"
                                />
                              </RenderWhen.If>
                            </RenderWhen>
                          </div>
                          <div className="ml-3">
                            <p className="font-[600] leading-none text-black sm:text-[14px]">
                              {collection.name}
                            </p>
                            <span className="text-[13px] text-gray-500">
                              {collection.numberOfItems} items
                            </span>
                          </div>
                        </div>
                      </CustomRadio>
                    ))}
                  </RadioGroup>
                </RenderWhen.If>
                <RenderWhen.If isTrue={collections.length === 0 && !loadingList}>
                  <NoData className="!h-[200px] scale-50" />
                </RenderWhen.If>
                <RenderWhen.If isTrue={collections.length === 0 && loadingList}>
                  <div className="flex h-[200px] items-center justify-center">
                    <Spinner />
                  </div>
                </RenderWhen.If>
              </RenderWhen>
            </ModalBody>
            <ModalFooter className="block p-0">
              <div className="flex items-center justify-between border-t-1 border-gray-100 px-[40px] py-[16px] sm:px-5">
                <AddNewCollection
                  setIsAddNew={setIsAddNew}
                  setCollectionId={setCollectId}
                  setRefreshGetCollection={setRefreshGetCollection}
                  customTrigger={
                    <div
                      onClick={onAddNewCollection}
                      className="flex cursor-pointer items-center duration-200 hover:opacity-70"
                    >
                      <IconPlusPrimary className="-ml-[4px]" />{' '}
                      <span className="ml-2 text-[15px] font-[600] text-orange-500 sm:hidden">
                        New collection
                      </span>
                    </div>
                  }
                />
                <div className="flex items-center justify-end">
                  <Buttons
                    onClick={() => {
                      onClose();
                      reset();
                    }}
                    typeBtn="cancel"
                  >
                    Cancel
                  </Buttons>
                  <Buttons
                    typeBtn={collectId ? 'primary' : 'disabled'}
                    className="ml-3"
                    isLoading={loading}
                    onClick={onSubmit}
                  >
                    Save
                  </Buttons>
                </div>
              </div>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default AddCollectionModal;
