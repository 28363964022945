import { Input, Modal, ModalContent, ModalHeader } from '@nextui-org/react';
import React from 'react';
import { toast } from 'react-toastify';

import { IconCloseGray2828 } from '@/assets';
import { Buttons } from '@/components';
import { voteNft } from '@/services/campaign';

interface ModelVoteCampaignProps {
  myAig?: number;
  setVote: React.Dispatch<React.SetStateAction<number>>;
  vote: number;
  campaign: any;
  setDisplayVote?: React.Dispatch<React.SetStateAction<number>>;
  setMyAig?: React.Dispatch<React.SetStateAction<number>>;
  useModel: any;
  nft: any;
}

const ModelVoteCampaign: React.FC<ModelVoteCampaignProps> = ({
  myAig,
  setVote,
  vote,
  campaign,
  setDisplayVote,
  setMyAig,
  useModel,
  nft,
}) => {
  const { isOpen, onOpenChange, onClose } = useModel;

  const handleVoteCampaign = async () => {
    if (myAig && myAig < 1) {
      setVote(0);
      return toast.warning('You are not aig enough!');
    }
    if (myAig && myAig < vote) {
      setVote(0);
      return toast.warning("You don't have enough aig to vote!");
    }
    if (vote > 1000) {
      toast.warning('Limit 1000 votes at a time!');
      return setVote(0);
    }
    const res: any = await voteNft(campaign.id, nft.id, vote);
    console.log('res', res);
    if (res?.status === 201) {
      if (setDisplayVote) {
        setDisplayVote((v: number) => v + vote);
      }
      if (setMyAig) {
        setMyAig((aig) => aig - vote);
      }
      toast.success(`Vote successfully.`);
      onClose();
    }
  };
  return (
    <Modal
      classNames={{ header: 'p-8 md:p-4' }}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      scrollBehavior="inside"
      hideCloseButton
      placement="center"
    >
      <ModalContent className="min-w-[400px] rounded-[20px] sm:min-w-[90%]">
        {(onClose) => (
          <>
            <ModalHeader className="flex items-center justify-between">
              <p className="text-[24px] font-semibold">Votes</p>{' '}
              <IconCloseGray2828 className="cursor-pointer" onClick={onClose} />
            </ModalHeader>
            <div className="m-10 mt-0 h-full overflow-hidden overflow-y-auto md:m-5">
              <p className="text-[14px] font-semibold">Bid your AIG to vote for the NFT</p>
              <p className="text-[13px] font-normal text-gray-500">Each vote costs you 01 AIG.</p>
              <div className="mt-3 w-full ">
                <Input
                  type="number"
                  autoFocus
                  onChange={(e) => {
                    setVote(Number(e.target.value));
                  }}
                  classNames={{
                    input: [
                      'dark:placeholder:text-white aria-selected:text-white font-normal text-14 pl-1',
                      'rounded-[100px]',
                      'aria-selected:placeholder-white',
                    ],
                    innerWrapper: ['bg-transparent', 'rounded-[100px]'],
                    inputWrapper: ['rounded-[100px] mt-3'],
                  }}
                />
              </div>
              <div className="mt-8 flex w-full justify-end ">
                <Buttons
                  className="w-full"
                  disabled={vote === 0}
                  typeBtn={vote === 0 ? 'disabled' : 'primary'}
                  onClick={handleVoteCampaign}
                >
                  <span className="text-[13px] font-semibold text-inherit">Accept</span>
                </Buttons>
              </div>
            </div>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default ModelVoteCampaign;
