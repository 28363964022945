import Rive from '@rive-app/react-canvas';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import React, { useMemo } from 'react';

import { ListSource } from '@/configs/constant';
import type { AssetData } from '@/contents/user-profile/content/artwork/useUserArwork';
import { useLayout } from '@/provider/LayoutProvider';
import { useAuth } from '@/provider/UserProvider';
import { isRivFile } from '@/utils';
import { currencyFormatter } from '@/utils/currency';

import { CardRenderFollowType } from './CardRenderFollowType';

const DynamicNsfwMark = dynamic(() => import('../NsfwMark'));

const CardArtwork: React.FC<{
  asset: AssetData;
  isPublicArtwork?: boolean;
  moreAction?: React.ReactElement;
}> = ({ asset, isPublicArtwork = false, moreAction }) => {
  const { setIsLoadingBar } = useLayout();
  const { auth } = useAuth();
  const accountAddress = asset?.accountAddress || '0x';
  const isOwner = accountAddress === auth.user.publicAddress;

  const linkArtwork = useMemo(() => {
    if (isPublicArtwork) {
      return `/artwork/${asset.id}`;
    }

    let url = '';
    if (isOwner) {
      url =
        asset.prompt !== null
          ? `/profile/${accountAddress}/prompt/${asset.prompt}`
          : `/artwork/${asset.id}`;
    } else {
      url = asset.prompt !== null ? `/prompt/${asset.prompt}` : `/artwork/${asset.id}`;
    }

    return url;
  }, [isPublicArtwork, asset, isOwner]);

  return (
    <Link
      onClick={() => setIsLoadingBar(true)}
      href={linkArtwork}
      className="group relative cursor-pointer overflow-hidden rounded-[20px]"
    >
      {isRivFile(asset?.url) ? (
        <div className="h-[300px] w-full">
          <Rive src={asset?.url} stateMachines="state-machine" />
        </div>
      ) : (
        <CardRenderFollowType
          isControl={false}
          isPlayIcon
          asset={asset}
          disabledClick
          isNotBorder
          isAudioBorder={false}
          listSource={ListSource.marketplace_nft}
          setByPicture
        />
      )}
      {asset.isNsfw && !isOwner && (
        <DynamicNsfwMark url={asset?.url} zIndex={12} />
      )}
      {asset?.model?.name && (
        <div className="absolute left-[10px] top-[10px] z-[12] inline-block rounded-[12px] bg-[rgba(0,0,0,0.5)] px-[14px] py-[6px] text-[13px] font-[600] text-white">
          {asset?.model?.name}
        </div>
      )}
      {asset?.offer ? (
        <div className="absolute bottom-[10px] left-[10px] z-[12] inline-block rounded-[12px] bg-[rgba(0,0,0,0.5)] px-[14px] py-[6px] text-[13px] font-[600] text-white">
          <div className="leading-15 text-[14px] font-bold sm:text-[13px]">
            {asset.offer?.denom !== 'AIRI' && <span>$ </span>}
            {currencyFormatter(asset.offer?.amount || 0, { significantDigits: 3 })}
            {asset.offer?.denom === 'AIRI' && <span> AIRI</span>}
          </div>
        </div>
      ) : (
        <div className="absolute bottom-[10px] left-[10px] z-[12] inline-block rounded-[12px] bg-[rgba(0,0,0,0.5)] px-[14px] py-[6px] text-[13px] font-[600] text-white">
          <div className="leading-15 text-[14px] font-bold sm:text-[13px]">
            NFT is not for sale.
          </div>
        </div>
      )}
      <div
        style={{
          background: `linear-gradient(to top, rgba(0,0,0,0.6) , transparent)`,
        }}
        className="absolute bottom-0 z-[10] h-0 w-full delay-100 duration-300 group-hover:h-full group-hover:delay-0"
      />
      {/* <div className="absolute inset-x-[15px] bottom-[10px] z-[11] translate-y-[110%] opacity-0 delay-0 duration-300 group-hover:translate-y-0 group-hover:opacity-100 group-hover:delay-100">
        <span className="text-[14px] text-white">{asset?.name}</span>
      </div> */}
      {moreAction && (
        <div className="invisible absolute right-[16px] top-[16px] z-[12] group-hover:visible">
          {moreAction}
        </div>
      )}
    </Link>
  );
};

export default CardArtwork;
