import { useEffect, useRef, useState } from 'react';

import type { AssetData } from '@/contents/user-profile/content/artwork/useUserArwork';
import type { IGetListArtworkFromPrompt } from '@/services/prompt';
import { getListArtworkByPromptId } from '@/services/prompt';

const PAGE_SIZE = 10;

const useArtworkRelated = (promptId: number) => {
  const assetDataRef = useRef([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [assetData, setAssetData] = useState<AssetData[]>([]);
  const [loading, setLoading] = useState(false);

  const handleGetArtwork = async (page?: number) => {
    const pageNumber = page || currentPage;
    setLoading(true);
    const queryParams: IGetListArtworkFromPrompt = {
      size: PAGE_SIZE,
      offset: PAGE_SIZE * (pageNumber - 1),
    };
    const res = await getListArtworkByPromptId(promptId, queryParams);

    if (pageNumber === 1) {
      assetDataRef.current = [];
      setAssetData([]);
    }
    assetDataRef.current = assetDataRef.current.concat(res?.data?.items || []);
    setAssetData(assetDataRef.current);
    setLoading(false);
  };

  useEffect(() => {
    handleGetArtwork();
  }, [currentPage]);

  return {
    currentPage,
    setCurrentPage,
    artworks: assetData,
    loading,
  };
};

export default useArtworkRelated;
