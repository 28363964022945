import { Spinner } from '@nextui-org/react';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';

import { CardArtwork } from '@/components/card';
import NoData from '@/components/NoData';
import RenderWhen from '@/components/RenderWhen';

import useArtworkRelated from './useArtworkRelated';

const ArtworkRelated: React.FC<{
  promptId: number;
}> = ({ promptId }) => {
  const { currentPage, artworks, setCurrentPage, loading } = useArtworkRelated(promptId);

  return (
    <div>
      <div className="text-[24px] font-semibold text-black lg:text-[20px] sm:!text-[18px]">
        Artworks using this prompt
      </div>
      <RenderWhen>
        <RenderWhen.If isTrue={loading && artworks.length === 0}>
          <div className="flex min-h-[400px] items-center justify-center">
            <Spinner label="loading..." />
          </div>
        </RenderWhen.If>
        <RenderWhen.If isTrue={artworks.length === 0 && !loading}>
          <NoData />
        </RenderWhen.If>
        <RenderWhen.If isTrue={artworks.length > 0}>
          <div className="mb-[60px] mt-10">
            <InfiniteScroll
              style={{ overflow: 'hidden' }}
              dataLength={artworks?.length}
              next={() => {
                setCurrentPage(currentPage + 1);
              }}
              hasMore
              loader=""
            >
              <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3, 1200: 5 }}>
                <Masonry gutter="16px">
                  {artworks.map((item) => (
                    <CardArtwork key={item?.id} asset={item} isPublicArtwork />
                  ))}
                </Masonry>
              </ResponsiveMasonry>
            </InfiniteScroll>
          </div>
        </RenderWhen.If>
      </RenderWhen>
    </div>
  );
};

export default ArtworkRelated;
