'use client';

import { useParams, usePathname } from 'next/navigation';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';

type LayoutProviderProps = {
  children: React.ReactNode;
};

export interface LayoutState {
  isLoadingBar: boolean;
  setIsLoadingBar: React.Dispatch<React.SetStateAction<boolean>>;
  isLoadingPage: boolean;
  setIsLoadingPage: React.Dispatch<React.SetStateAction<boolean>>;
  isOpenSidebar: boolean;
  setIsOpenSidebar: React.Dispatch<React.SetStateAction<boolean>>;
}

export const initialStateLayout: LayoutState = {
  isLoadingBar: false,
  setIsLoadingBar: () => {},
  isLoadingPage: false,
  setIsLoadingPage: () => {},
  isOpenSidebar: true,
  setIsOpenSidebar: () => {},
};

const LayoutProviderContext = createContext(initialStateLayout);

export const LayoutProvider: React.FC<LayoutProviderProps> = ({ children }) => {
  const [isLoadingBar, setIsLoadingBar] = useState(false);
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const [isOpenSidebar, setIsOpenSidebar] = useState(true);
  const { id, address } = useParams();
  const pathname = usePathname();
  const isCloseSidebar = [
    `/showcase/${id}`, 
    `/ai-model/${id}/generate`,
    `/profile/${address}`,
  ].includes(pathname);

  useEffect(() => {
    if (isCloseSidebar) setIsOpenSidebar(false)
    else setIsOpenSidebar(true)
  }, [isCloseSidebar]);

  const contextValue: LayoutState = useMemo(() => {
    return {
      isLoadingBar,
      setIsLoadingBar,
      isLoadingPage,
      setIsLoadingPage,
      isOpenSidebar,
      setIsOpenSidebar
    };
  }, [isLoadingBar, setIsLoadingBar, isOpenSidebar, setIsOpenSidebar]);

  return (
    <LayoutProviderContext.Provider value={contextValue}>{children}</LayoutProviderContext.Provider>
  );
};

export const useLayout = () => useContext(LayoutProviderContext);
