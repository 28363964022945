import { api } from '../axios';

export const getTagsWithSearch = async (search: string) => {
  return await api.get(
    `/tags?size=20&offset=0&filter=${encodeURIComponent(`{"content":"${search}"}`)}`,
  );
};

export const getTags = async () => {
  return await api.get('/tags?size=20&offset=0');
};

export const getHotTags = async () => {
  return await api.get('/use-case/tag/const');
}