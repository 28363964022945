import React, { useEffect, useRef } from 'react';

import { renderIcon } from '@/utils';
import { AvatarDecor as AvatarDecorProps } from '@/app/shop/content/useFetch';
import AvatarDecor from '../avatar-decor';

interface AvatarPlaceholderProps
  extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  address?: string;
  scale?: number;
  alt?: string;
  className?: string;
  src: string | undefined;
  decor?: AvatarDecorProps | null;
  decorScale?: number,
}

const AvatarPlaceholder: React.FC<AvatarPlaceholderProps> = ({
  address = '',
  alt = '',
  scale = 4,
  src,
  onClick,
  decor,
  decorScale = 0,
  ...props
}) => {
  const imageRef = useRef<HTMLImageElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    // no src image then build it, default scale is 4
    if (!src && address) {
      const canvas = canvasRef.current;
      renderIcon({ seed: address?.toLowerCase(), scale }, canvas);
      const dataUrl = canvas?.toDataURL();
      if (dataUrl && imageRef.current) {
        imageRef.current.src = dataUrl;
      }
    }
  }, [src, scale, address]);
  return (
    <>
      <canvas ref={canvasRef} style={{ display: 'none' }} />
      <img
        className="h-full w-full object-cover"
        loading="lazy"
        onClick={onClick}
        ref={imageRef}
        src={src}
        alt={alt}
        {...props}
      />
      {decor && <AvatarDecor scale={decorScale} url={decor.decoUrl} />}
    </>
  );
};

export default AvatarPlaceholder;
