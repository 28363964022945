import type { AxiosError } from 'axios';
import axios from 'axios';
import { toast } from 'react-toastify';

import { configs } from '@/configs';
import cachedLocalStorage from '@/libs/localStorage';
import { refreshTokenApi } from '@/services/authentication';
import storageFunction from '@/utils/storage';

// const refreshTokenAi =
//   typeof window !== 'undefined' ? cachedLocalStorage.getWithExpiry('refreshToken') : '';

export const api = axios.create({
  baseURL: configs.baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

api.interceptors.request.use((config) => {
  const accessToken =
    typeof window !== 'undefined' ? cachedLocalStorage.getWithExpiry('token') : '';
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  return config;
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: any) => {
    const { deleteToken, deleteInfoUser } = storageFunction;

    if (error?.response?.data?.message === 'Unauthorized') {
      if (!cachedLocalStorage.getWithExpiry('token') && typeof window !== 'undefined') {
        if (cachedLocalStorage.getWithExpiry('refreshToken')) {
          return refreshToken(error);
        }
        deleteToken();
        deleteInfoUser();
        return toast.error('You must login to continue!');
      }
    }
    if (error?.response?.data?.message) {
      if (error?.response?.data?.message !== 'Unauthorized')
        toast.error(error.response.data.message);
    }
    return Promise.reject(error);
  },
);

export const handleRefetchToken = async () => {
  const { saveToken, deleteToken } = storageFunction;
  try {
    const refreshTokenLocal = cachedLocalStorage.getWithExpiry('refreshToken');
    const data: any = await refreshTokenApi(refreshTokenLocal);
    const { accessToken, refreshToken } = data;
    saveToken(accessToken, refreshToken);
    window.dispatchEvent(new Event('custom-storage-event-name'));
    return data;
  } catch (error) {
    deleteToken();
    toast.error('You must login to continue!');
    throw error;
  }
};

let fetchingToken = false;
const refreshToken = async (oError: AxiosError) => {
  try {
    const { response } = oError;
    if (!fetchingToken) {
      fetchingToken = true;
      const data = await handleRefetchToken();
      const { accessToken } = data;
      api.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
      const res = await api(response!.config);
      return res;
    }
  } catch (error) {
    return Promise.reject(oError);
  } finally {
    fetchingToken = false;
  }
};
