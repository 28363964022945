import React from 'react';

type WhenProps = {
  children: React.ReactNode | React.ReactNode[] | React.ReactElement | React.ReactElement[];
  isTrue?: boolean;
};

const RenderWhen = ({ isTrue = true, children }: WhenProps) => {
  const list: React.ReactNode[] = [];

  if (isTrue !== true) {
    return null;
  }

  React.Children.map(children, (child: any) => {
    const { isTrue: isChildTrue } = child?.props || {};

    if (isChildTrue === true) {
      list.push(child);
    }
  });

  return <>{list}</>;
};

// @ts-ignore
RenderWhen.If = ({ children, isTrue }) => isTrue && children;

export default RenderWhen;
