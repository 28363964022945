import type { Coin, StdFee } from '@cosmjs/stargate';
import { isNil } from 'lodash';

export const handleExecute = async (
  contract: string,
  sender: string,
  msg: string,
  {
    gas,
    fees = '',
    funds = 'BROADCAST_MODE_BLOCK',
    type = '',
  }: { gas?: any; fees?: any; funds?: string; type?: string; mode?: any },
) => {
  const { marketplace1155, ow1155, marketplace, ow721 } = window.Wasm.contractAddresses;
  try {
    if (contract === marketplace1155) {
      const isApprovedResult = await window.Wasm.query(
        ow1155 || '',
        JSON.stringify({
          is_approved_for_all: {
            owner: sender,
            operator: marketplace1155,
          },
        }),
      );

      if (!isApprovedResult?.data?.approved) {
        await handleExecute(
          ow1155 || '',
          sender,
          JSON.stringify({
            approve_all: { operator: marketplace1155 },
          }),
          {
            gas: parseInt(gas),
            fees: parseInt(fees),
            mode: 'BROADCAST_MODE_BLOCK',
          },
        );
      }
    } else if (contract === marketplace) {
      const isApprovedResult = await window.Wasm.query(
        ow721 || '',
        JSON.stringify({
          approved_for_all: {
            owner: sender,
          },
        }),
      );

      let isApproved = false;
      isApprovedResult?.data?.operators?.forEach((item: any) => {
        if (item?.spender === marketplace) {
          isApproved = true;
        }
      });

      if (!isApproved) {
        await handleExecute(
          ow721 || '',
          sender,
          JSON.stringify({
            approve_all: { operator: marketplace },
          }),
          {
            gas: parseInt(gas),
            fees: parseInt(fees),
            mode: 'BROADCAST_MODE_BLOCK',
          },
        );
      }
    }
  } catch (error: any) {
    throw new Error(
      `Cannot approve permission in check wallet with error: ${
        error?.message || error?.toString()
      }`,
    );
  }

  // sign transactions using Oraichain wallet
  // if (isOraiWallet === "true") {
  const sentFunds = isNil(type)
    ? funds
    : type === 'native'
    ? [{ denom: 'orai', amount: funds }]
    : null;

  const result = await window.Wasm.execute(contract, msg, sentFunds as Coin[]);
  return result;
};

export const handleExecuteGrant = async ({
  contract,
  msg,
  grant,
}: {
  contract: string;
  msg: string;
  grant: StdFee;
}) => {
  const result = await window.Wasm.executeWithGrant(contract, msg, grant);
  return result;
};
