'use client';

import { Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from '@nextui-org/react';
import Image from 'next/image';
import React, { useEffect } from 'react';

import { IconArrowDown, IconKing } from '@/assets';

import TooltipCustom from '../tooltip-custom';

interface SelectBaseProps {
  list: { value: string | number; label: string; desc?: string; icon?: any }[];
  onChange?: (e: any) => void;
  defaultValue?: string;
  classTrigger?: string;
  classTextTrigger?: string;
  classIcon?: string;
  itemMenu?: React.ReactElement;
  dropdownTrigger?: React.ReactElement;
  setDefaultFormSelect?: () => void;
}

const SelectBase: React.FC<SelectBaseProps> = ({
  list,
  onChange,
  defaultValue,
  classTrigger,
  classTextTrigger,
  classIcon,
  itemMenu,
  dropdownTrigger,
  setDefaultFormSelect,
}) => {
  const [selectedKeys, setSelectedKeys] = React.useState(
    new Set([defaultValue ?? 'Select your option']),
  );

  const selectedValue = React.useMemo(() => Array.from(selectedKeys).join(', '), [selectedKeys]);

  const listItem = () => {
    return list.map((item) => (
      <DropdownItem key={item.value?.toString()?.trim()}>
        <div className="flex flex-col">
          {itemMenu || (
            <>
              <span className="text-14 font-semibold"> {item.label} </span>
              {item?.desc && (
                <div className="flex items-center justify-between text-12 font-normal text-gray-500">
                  {item.desc}
                  <TooltipCustom
                    showArrow
                    content={
                      <span className="max-w-[370px] text-13 font-normal text-white">
                        Subscribe to aiRight plus plan to use this tool.
                      </span>
                    }
                    classNames={{ base: 'tooltip', arrow: 'arrow-tooltip' }}
                  >
                    <span className="rounded-[10px] bg-secondary p-1">
                      <IconKing />
                    </span>
                  </TooltipCustom>
                </div>
              )}
            </>
          )}
        </div>
      </DropdownItem>
    ));
  };

  // useEffect(() => {
  //   if (onChange && selectedValue) {
  //     const value = list.find((item) => item.label === selectedValue)?.value;

  //     if (value && onChange) {
  //       onChange(value);
  //     }
  //   }
  // }, [selectedKeys]);

  useEffect(() => {
    if (defaultValue) {
      const value = new Set([defaultValue]);
      if (value !== selectedKeys && value) {
        setSelectedKeys(value);
      }
    }
    if (setDefaultFormSelect) {
      setDefaultFormSelect();
    }
  }, [defaultValue]);

  const icon = list.find((item) => item.label === selectedValue)?.icon;

  return (
    <Dropdown className="w-full">
      <DropdownTrigger className="w-full cursor-pointer">
        {dropdownTrigger || (
          <div
            className={`flex w-full items-center justify-between rounded-[100px] bg-gray-50 px-4 py-[10px] ${classTrigger}`}
          >
            <div className="flex items-center">
              {icon && <Image className={`mr-2 ${classIcon}`} src={icon} alt="icon select" />}
              <span
                className={`text-14 font-normal leading-none text-gray-500 ${classTextTrigger}`}
              >
                {list.find((item) => item.value === selectedValue)?.label || 'select item'}
              </span>
            </div>
            <IconArrowDown className="mt-[-4px]" />
          </div>
        )}
      </DropdownTrigger>
      <DropdownMenu
        itemClasses={{ base: 'w-322' }}
        className="dropdown-hidden-selected max-h-[350px] w-full overflow-hidden overflow-y-auto scrollbar-hide"
        aria-label="Single selection actions"
        variant="flat"
        disallowEmptySelection
        selectionMode="single"
        selectedKeys={selectedKeys}
        // @ts-ignore
        onSelectionChange={(e: any) => {
          if (onChange) {
            onChange(Array.from(e).join(', '));
          }
          setSelectedKeys(e);
        }}
      >
        {listItem()}
      </DropdownMenu>
    </Dropdown>
  );
};

export default SelectBase;
