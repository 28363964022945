import type { SelectOptionItem } from '@/components/select/SelectMultipleWithSearch';
import { getCollectionTags } from '@/services/collection';

interface TagCollection {
  id: number;
  name: string;
}
const useLoadTagCollection = () => {
  const loadTagOptions = async (search?: string) => {
    try {
      const res = await getCollectionTags(50, 0, search);
      const tags = mapOptionTags(res.data.tag || []);
      return tags;
    } catch (err) {
      return [];
    }
  };

  const mapOptionTags = (tags: TagCollection[]): SelectOptionItem[] => {
    return tags.map((tag) => ({ value: tag.id, label: tag.name }));
  };

  return { loadTagOptions, mapOptionTags };
};

export default useLoadTagCollection;
