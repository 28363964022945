import {
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  useDisclosure,
} from '@nextui-org/react';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { IconAiriToken, IconClose, IconPriceTag } from '@/assets';
import { Buttons } from '@/components';
import MarketApproveDialog from '@/components/market-approve-dialog';
import { configs } from '@/configs';
import InputQuantity from '@/contents/art-work/detail/InputQuantity';
import useGrant from '@/hooks/useGrant';
import useMarketApprove from '@/hooks/useMarketApprove';
import { TokenId, useTokenPrices } from '@/hooks/useTokenPrices';
import type { IAssetDetail } from '@/types/asset';
import { getUsdWithToken, isAsset1155 } from '@/utils';

import useSellArtwork from './useSellArtwork';

const SellArtwork: React.FC<{
  asset: IAssetDetail;
  refresh: () => void;
}> = ({ asset, refresh }) => {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const [isGranted, setIsGranted] = useState(false);
  const [err, setErr] = useState('');
  const [quantity, setQuantity] = useState(1);
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const price = watch('price');

  const handleWithGrant = (type: 'default' | 'request' | 'granted') => {
    if (type !== 'default') setIsGranted(true);
    if (!hasApproved) {
      mOnOpen();
    } else {
      reset();
      onOpen();
    }
  };

  const { onStart: onStartWithCheckGrant } = useGrant(
    () => handleWithGrant('default'),
    () => handleWithGrant('granted'),
    () => handleWithGrant('request'),
  );

  const {
    onClose: mOnClose,
    isOpen: mIsOpen,
    onOpenChange: mOnOpenChange,
    hasApproved,
    onOpen: mOnOpen,
    setHasApproved,
  } = useMarketApprove(true);

  const { handleSellArtwork, handleSellArtworkWithGrant, loading } = useSellArtwork({
    onClose,
    callback: refresh,
  });

  const onSubmit = async (data: any) => {
    if (isGranted) {
      await handleSellArtworkWithGrant(
        asset.id.toString(),
        {
          amount: data.price,
          royaltyAmount: data.royalty,
          gas: 400000,
        },
        {
          granter: configs.grantAddress,
          amount: [
            {
              amount: '10000',
              denom: 'orai',
            },
          ],
          gas: '2000000',
        },
      );
    } else {
      if (err) return;
      await handleSellArtwork({
        assetId: asset.id.toString(),
        dataMessage: {
          amount: data.price,
          royaltyAmount: data.royalty,
          gas: 400000,
          fees: 0,
          quantity,
        },
        is1155: isAsset1155(asset.version),
      });
    }
  };

  const { data: prices } = useTokenPrices();

  const airiToUsd = useMemo(
    () =>
      getUsdWithToken({
        amount: price,
        tokenId: TokenId.AIRI,
        prices: prices || {},
      }),
    [prices, price],
  );

  return (
    <div>
      <Buttons onClick={onStartWithCheckGrant} typeBtn="primary" className="w-full">
        <IconPriceTag />
        Sell artwork
      </Buttons>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        hideCloseButton
        scrollBehavior="inside"
        placement="center"
      >
        <ModalContent className="min-w-[370px] rounded-[20px]">
          {(onClose) => (
            <>
              <ModalHeader className="flex items-center justify-between p-8 pb-0">
                <span className="text-[24px] font-[600]">Sell artwork</span>
                <div
                  onClick={() => {
                    onClose();
                  }}
                  className="flex h-[32px] w-[32px] cursor-pointer items-center justify-center rounded-[8px] bg-gray-100"
                >
                  <IconClose />
                </div>
              </ModalHeader>
              <ModalBody className="gap-0 px-[32px] py-0">
                <div className="mb-[32px] mt-3 border-b-1 border-gray-100 pb-[32px] text-[14px] leading-[1.5] text-gray-500">
                  Every rights you have over this artwork will be transferred to whoever buys it
                </div>
                <div>
                  <p className="mb-2 text-[14px] font-[600] text-black">
                    Set a price <span className="text-red-600">*</span>
                  </p>
                  <Input
                    classNames={{
                      inputWrapper: 'rounded-[100px] bg-gray-50 shadow-none',
                    }}
                    placeholder="Enter price"
                    {...register('price', {
                      required: 'Price is required',
                      validate: (value) => {
                        if (value <= 0) {
                          return 'Price must larger 0';
                        }
                        if (isNaN(value)) {
                          return 'Price is a number';
                        }
                        return true;
                      },
                    })}
                    description={price && <div>~ {airiToUsd.toFixed(6)} USD</div>}
                    endContent={
                      <div className="rounded-[100px] bg-orange-100 px-2 py-1">
                        <div className="flex items-center self-center">
                          <IconAiriToken />
                          <span className="flex self-center pl-1 text-14 text-gray-900">AIRI</span>
                        </div>
                      </div>
                    }
                  />

                  {errors.price && (
                    <div className="mt-2 text-[13px] text-red-500">
                      {errors.price?.message as string}
                    </div>
                  )}
                </div>
                {isAsset1155(asset.version) ? (
                  <div className="mt-5">
                    <InputQuantity
                      err={err}
                      setErr={setErr}
                      setQuantity={setQuantity}
                      total={asset.ownerStatus?.availableQuantity || 0}
                      defaultValue={quantity}
                    />
                  </div>
                ) : (
                  <div className="mt-5">
                    <div className="mb-2 text-[14px] font-[600] text-black">Seller royalty</div>
                    <Input
                      classNames={{
                        inputWrapper: 'rounded-[100px] bg-gray-50 px-[14px]',
                      }}
                      placeholder="0-20"
                      {...register('royalty', {
                        required: 'Royalty is required',
                        validate: (value) => {
                          if (value < 0) {
                            return 'Value between 0 and 20';
                          }
                          if (value > 20) {
                            return 'Value between 0 and 20';
                          }
                          if (isNaN(value)) {
                            return 'Value is a number';
                          }
                          return true;
                        },
                      })}
                      endContent={<span className="text-[14px] text-gray-500">%</span>}
                    />
                    {errors.royalty && (
                      <div className="mt-1 h-[20px] text-[13px] text-red-500">
                        {errors.royalty?.message as string}
                      </div>
                    )}
                  </div>
                )}
                <div className="my-8 flex items-center justify-end">
                  <Buttons onClick={onClose} typeBtn="cancel">
                    Cancel
                  </Buttons>
                  <Buttons
                    isLoading={loading}
                    onClick={handleSubmit(onSubmit)}
                    typeBtn="primary"
                    className="ml-3"
                  >
                    Sell artwork
                  </Buttons>
                </div>
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
      <MarketApproveDialog
        isOpen={mIsOpen}
        onOpenChange={mOnOpenChange}
        onClose={mOnClose}
        callback={() => setHasApproved(true)}
        isGranted={isGranted}
      />
    </div>
  );
};

export default SellArtwork;
