'use client';

import React, { useEffect, useRef } from 'react';
import LoadingBar from 'react-top-loading-bar';

import { useLayout } from '../../provider/LayoutProvider';

interface LoadingBarPageProps {}

const LoadingBarPage: React.FC<LoadingBarPageProps> = ({}) => {
  const ref = useRef<any>();
  const { isLoadingBar } = useLayout();

  useEffect(() => {
    if (isLoadingBar) ref.current.staticStart(10);
    else ref.current.complete();
  }, [isLoadingBar]);

  return (
    <LoadingBar waitingTime={700} ref={ref} className="bg-primary text-primary" color="primary" />
  );
};

export default LoadingBarPage;
