import { configs } from '.';

export const NetworkKey = {
  MAINNET: 'mainnet',
  TESTNET: 'testnet',
  PRERELEASE: 'prerelease',
};

const networks = {
  [NetworkKey.MAINNET]: {
    chainId: 'Oraichain',
    prefix: 'orai',
    denom: 'orai',
    coinType: 118,
    lcd: configs.lcdURL || 'https://lcd.orai.io',
    rpc: configs.rpcURL || 'https://rpc.orai.io',
    id: NetworkKey.MAINNET,
    fee: { gasPrice: '0', amount: '0', gas: '2000000' }, // 0.000500 ORAI
  },
  [NetworkKey.PRERELEASE]: {
    chainId: 'Oraichain-fork',
    prefix: 'orai',
    denom: 'orai',
    coinType: 118,
    lcd: configs.lcdURL || 'https://pre.lcd.orai.io',
    rpc: configs.rpcURL || 'https://pre.rpc.orai.io',
    id: NetworkKey.PRERELEASE,
    fee: { gasPrice: '0', amount: '0', gas: '2000000' }, // 0.050000 ORAI
  },
  [NetworkKey.TESTNET]: {
    chainId: 'Oraichain-testnet',
    prefix: 'orai',
    denom: 'orai',
    coinType: 118,
    lcd: configs.lcdURL || 'https://testnet-lcd.orai.io',
    rpc: configs.rpcURL || 'https://testnet-rpc.orai.io',
    id: NetworkKey.TESTNET,
    fee: { gasPrice: '0', amount: '0', gas: '2000000' }, // 0.050000 ORAI
  },
};

export default networks;

let networkTmp;
if (configs.lcdURL === 'https://testnet-lcd.orai.io') {
  networkTmp = networks[NetworkKey.TESTNET];
} else if (configs.lcdURL === 'https://pre.lcd.orai.io') {
  networkTmp = networks[NetworkKey.PRERELEASE];
} else {
  networkTmp = networks[NetworkKey.MAINNET];
}

export const network = networkTmp;
export const mobileBlacklistNetworks = [];
