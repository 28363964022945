import {
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  useDisclosure,
} from '@nextui-org/react';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import { IconClose, TransferIcon } from '@/assets';
import { Buttons } from '@/components';
import MarketApproveDialog from '@/components/market-approve-dialog';
import InputQuantity from '@/contents/art-work/detail/InputQuantity';
import useGrant from '@/hooks/useGrant';
import useMarketApprove from '@/hooks/useMarketApprove';
import type { IAssetDetail } from '@/types/asset';
import { isAsset1155 } from '@/utils';

import useTransferNFT from './useTransferNFT';

const TransferNFT: React.FC<{
  asset: IAssetDetail;
  refresh: () => void;
  publicAddress: string;
}> = ({ asset, refresh, publicAddress }) => {
  const [err, setErr] = useState('');
  const [quantity, setQuantity] = useState(1);
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const [isGranted, setIsGranted] = useState(false);

  const handleWithGrant = (type: 'default' | 'request' | 'granted'): Promise<void> => {
    return new Promise((resolve) => {
      if (type !== 'default') setIsGranted(true);
      if (!hasApproved) {
        mOnOpen();
        resolve();
      } else {
        reset();
        handleOpenTransferModal();
        resolve();
      }
    });
  };

  const { onStart: onStartWithCheckGrant } = useGrant(
    () => handleWithGrant('default'),
    () => handleWithGrant('granted'),
    () => handleWithGrant('request'),
  );

  const {
    onClose: mOnClose,
    isOpen: mIsOpen,
    onOpenChange: mOnOpenChange,
    hasApproved,
    onOpen: mOnOpen,
    setHasApproved,
  } = useMarketApprove(true);

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const addressSubmit = watch('addressSubmit');
  const { handleTransferNFT, handleTransferNFTWithGrant, loading } = useTransferNFT(
    asset,
    publicAddress,
    onClose,
    refresh,
    addressSubmit,
    quantity,
  );

  const handleOpenTransferModal = async () => {
    reset();
    onOpen();
  };

  const onSubmit = async () => {
    if (isGranted) {
      await handleTransferNFTWithGrant();
    } else {
      await handleTransferNFT();
    }
  };

  return (
    <div>
      <Buttons onClick={onStartWithCheckGrant} typeBtn="secondary" className="w-full">
        <TransferIcon />
        Transfer NFT
      </Buttons>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        hideCloseButton
        scrollBehavior="inside"
        placement="center"
      >
        <ModalContent className="min-w-[370px] rounded-[20px]">
          {(onClose) => (
            <>
              <ModalHeader className="flex items-center justify-between p-8 pb-0">
                <span className="text-[24px] font-[600]">Recipient’s address</span>
                <div
                  onClick={() => {
                    onClose();
                  }}
                  className="flex h-[32px] w-[32px] cursor-pointer items-center justify-center rounded-[8px] bg-gray-100"
                >
                  <IconClose />
                </div>
              </ModalHeader>
              <ModalBody className="gap-0 px-[32px] py-0">
                <div className="mb-[32px] mt-3 border-b-1 border-gray-100 pb-[32px] text-[14px] leading-[1.5] text-gray-500">
                  Please enter the wallet address you want to transfer this artwork to.
                </div>
                <div>
                  <p className="mb-2 text-[14px] font-[600] text-black">
                    Address transfer to <span className="text-red-600">*</span>
                  </p>
                  <Input
                    classNames={{
                      inputWrapper: 'rounded-[100px] bg-gray-50 shadow-none',
                    }}
                    placeholder="Paste here"
                    {...register('addressSubmit', {
                      required: 'Address is required',
                    })}
                  />

                  {errors.price && (
                    <div className="mt-2 text-[13px] text-red-500">
                      {errors.price?.message as string}
                    </div>
                  )}

                  {isAsset1155(asset.version) && (
                    <div className="mt-4">
                      <InputQuantity
                        err={err}
                        setErr={setErr}
                        setQuantity={setQuantity}
                        total={asset.ownerStatus?.availableQuantity || 0}
                        defaultValue={quantity}
                        isIconRequired
                      />
                    </div>
                  )}
                </div>
                <div className="my-8 flex items-center justify-end">
                  <Buttons onClick={onClose} typeBtn="cancel">
                    Cancel
                  </Buttons>
                  <Buttons
                    isLoading={loading}
                    onClick={handleSubmit(onSubmit)}
                    typeBtn="primary"
                    className="ml-3"
                  >
                    Transfer NFT
                  </Buttons>
                </div>
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
      <MarketApproveDialog
        isOpen={mIsOpen}
        onOpenChange={mOnOpenChange}
        onClose={mOnClose}
        callback={() => setHasApproved(true)}
        isGranted={isGranted}
      />
    </div>
  );
};

export default TransferNFT;
