import { useEffect, useState } from 'react';

export default function ClientOnly({
  children,
  skeleton,
}: {
  children: React.ReactElement;
  skeleton?: React.ReactElement;
}) {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) return skeleton || null;

  return children;
}
