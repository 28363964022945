const isProduction = process.env.NEXT_PUBLIC_NODE_ENV === 'production';
const MARKETPLACE_SUB_NAME = isProduction ? 'marketplace' : 'marketplace-staging';
import cachedLocalStorage from "@/libs/localStorage";

export const getMarketplaceURL = (isOwallet: boolean, path = '/') => {
  const accessToken =
    typeof window !== 'undefined' ? cachedLocalStorage.getAllWithExpiry('token') : null;
  const refreshToken =
    typeof window !== 'undefined' ? cachedLocalStorage.getAllWithExpiry('refreshToken') : null;

  if (accessToken && refreshToken) {
    return `https://${MARKETPLACE_SUB_NAME}.airight.io${path}?token=${accessToken.value}&token_expiry=${accessToken.expiry}&refresh_token=${refreshToken.value}&refresh_token_expiry=${refreshToken.expiry}&isowallet=${isOwallet}`;
  }
  return `https://${MARKETPLACE_SUB_NAME}.airight.io${path}`;
};