'use client';

import { groupBy } from 'lodash';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useAuth } from '@/provider/UserProvider';
import { getHistoryGenerate } from '@/services';
import type { IAssetProcessing } from '@/types/asset';
import { useSQuery } from '@/utils';

type HistoryProviderProps = {
  children: React.ReactNode;
};

export interface HistoryState {
  state: PromptProviderState[];
  setState: React.Dispatch<React.SetStateAction<PromptProviderState[]>>;
  imgSelect: PromptProviderState;
  setImgSelect: React.Dispatch<React.SetStateAction<PromptProviderState>>;
  isRun: boolean;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  historyProcessImg: PromptProviderState[][];
  isLoadingHistoryGenerateImage: boolean;
  isBeginner?: boolean;
}

export type PromptProviderState = {
  file?: File;
  processId?: number | string;
  generateType?: string;
  model?: string;
  modelId?: number;
  assetId?: number;
  prompt: string;
  progress: string;
  done: boolean;
  url: string;
  step: number;
  imageId: number | string;
  readyRun: boolean;
  customId:
    | 'V1'
    | 'V2'
    | 'V3'
    | 'V4'
    | 'U1'
    | 'U2'
    | 'U3'
    | 'U4'
    | 'x15'
    | 'x20'
    | '4x'
    | '2x'
    | 'strong'
    | null;
  tool?: string;
  usedOptions?: string[];
  transferred?: boolean;
  imageConfig?: {
    batch_size: number;
    cfg_scale: number;
    enable_hires: number;
    height: number;
    lora: string;
    model: string;
    negative_prompt: string;
    samplers: string;
    seed: string;
    steps: number;
    width: number;
  };
  upScale?: boolean;
  createdAt?: string;
  modelName?: string;
  modelConfig?: string;
  isNsfw?: boolean;
  message?: string;
  type?: string;
};

export const initialState: PromptProviderState = {
  assetId: 0,
  prompt: '',
  progress: '0%',
  done: false,
  url: '',
  step: 1,
  imageId: 0,
  readyRun: false,
  customId: null,
  createdAt: new Date().toISOString(),
  modelConfig: '',
  isNsfw: false,
  upScale: false,
  message: '',
  type: 'image',
};

const HistoryProviderContext = createContext<HistoryState>({
  state: [initialState],
  setState: () => {},
  imgSelect: initialState,
  setImgSelect: () => {},
  isRun: false,
  setCurrentPage: () => {},
  historyProcessImg: [[initialState]],
  isLoadingHistoryGenerateImage: false,
});

export const HistoryProvider: React.FC<HistoryProviderProps> = ({ children }) => {
  const { isLoginClient } = useAuth();
  const [state, setState] = useState<PromptProviderState[]>([initialState]);
  const [imgSelect, setImgSelect] = useState<PromptProviderState>(initialState);
  const [isRun, setIsRun] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isBeginner, setIsBeginner] = useState(false);

  const {
    data: historyGenerateImage,
    refetch: refetchHistoryGenerateImage,
    isLoading: isLoadingHistoryGenerateImage,
  } = useSQuery({
    queryKey: ['list-history-generate'],
    queryFn: () =>
      getHistoryGenerate({
        size: 30,
        offset: (currentPage - 1) * 30,
      }),
    keepPreviousData: true,
    staleTime: Infinity,
    enabled: isLoginClient,
  });

  const handleCheckRunCreate = useCallback(() => {
    if (state.some((img) => img?.readyRun)) setIsRun(true);
    else {
      setIsRun(false);
    }
  }, [state]);

  const stateTransformer = (assetsProcessing: IAssetProcessing[]): PromptProviderState[] => {
    if (!Array.isArray(assetsProcessing)) {
      console.error('Expected assetsProcessing to be an array but got:', assetsProcessing);
      return [];
    }
    const arrs: PromptProviderState[] = assetsProcessing.map((item) => ({
      assetId: item.id,
      prompt: item?.prompt?.prompt || '',
      progress: '100%',
      done: true,
      url: item?.url || '',
      step: 3,
      imageId: item.id,
      readyRun: false,
      customId: null,
      createdAt: item.createdAt,
      modelName: item?.model?.name || '',
      modelConfig: item?.modelConfig || '',
      isNsfw: item.isNsfw,
      upScale: item?.modelConfig?.includes('"mode":"upscale"'),
      message: item?.message || '',
      type: item.type || '',
      modelId: item.modelId,
    }));
    return arrs;
  };

  useEffect(() => {
    if (currentPage !== 1 && isLoginClient) {
      refetchHistoryGenerateImage().then((data: any) =>
        setState((states: any) => [...states, ...stateTransformer(data?.data?.items)]),
      );
    }
  }, [currentPage]);

  useEffect(() => {
    if (currentPage === 1 && historyGenerateImage?.items?.length > 0) {
      setState(stateTransformer(historyGenerateImage.items));
    }
  }, [historyGenerateImage]);

  useEffect(() => {
    setImgSelect(state[0] || initialState);
    handleCheckRunCreate();
    if (state.some((item) => item.prompt)) {
      setIsBeginner(false);
    } else {
      setIsBeginner(true);
    }
    if (state.length === 0) {
      setIsBeginner(true);
      setState([initialState]);
    }
  }, [state]);

  useEffect(() => {
    setState([initialState]);
    if (isLoginClient) {
      refetchHistoryGenerateImage().then((data: any) =>
        setState([...stateTransformer(data?.data?.items)]),
      );
    } else {
      setCurrentPage(1);
    }
  }, [isLoginClient]);

  const historyProcessImg = Object.values(groupBy(state, 'createdAt'));
  const contextValue: HistoryState = useMemo(() => {
    return {
      state,
      setState,
      imgSelect,
      setImgSelect,
      isRun,
      setCurrentPage,
      historyProcessImg,
      isLoadingHistoryGenerateImage,
      isBeginner,
    };
  }, [
    isLoadingHistoryGenerateImage,
    state,
    setState,
    imgSelect,
    setImgSelect,
    isRun,
    setCurrentPage,
    historyProcessImg,
    isBeginner,
  ]);

  return (
    <HistoryProviderContext.Provider value={contextValue}>
      {children}
    </HistoryProviderContext.Provider>
  );
};

export const useHistoryState = () => useContext(HistoryProviderContext);
