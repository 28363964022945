import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { configs } from '@/configs';
import { ActionSSO } from '@/libs/ssoWallet';
import { useAuth } from '@/provider/UserProvider';
import { approveAccount, approveAccountSso } from '@/services/account';

const useApprove = (onClose: () => void, callback?: () => void) => {
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState('');
  const [err, setErr] = useState('');
  const { auth } = useAuth();

  // for owallet
  const handleSubmit = async (isGranted?: boolean) => {
    const address = auth?.user?.publicAddress;
    if (address === '0x') return;

    if (auth.isOwallet) {
      setLoading(true);
      const result = await approveAccount(address, isGranted);

      if (result) {
        toast.success('You have successfully approved new contract!');
        onClose();
        callback && callback();
      } else {
        toast.error('Approve contract failed! Something went wrongs');
      }
      setLoading(false);
    } else {
      setLoading(true);
      await approveAccountSso(address, isGranted);
    }
  };

  // for sso
  useEffect(() => {
    if (typeof window !== 'undefined' && !auth.isOwallet) {
      window.addEventListener(
        'message',
        (event) => {
          if (event.origin !== configs.ssoUrl) return;
          const data = JSON.parse(event?.data || '');

          if (data.action !== ActionSSO.approveContractMarket) return;
          setLoading(false);

          if (data.status === 'success') {
            setDone('You have successfully approved new contract!');
            onClose();
            callback && callback();
          } else {
            setErr(data.response);
            onClose();
          }
        },
        false,
      );
    }
    return window.removeEventListener('message', () => {});
  }, []);

  useEffect(() => {
    if (done) {
      toast.success(done);
    }
  }, [done]);

  useEffect(() => {
    if (err) {
      toast.error(err);
    }
  }, [err]);

  return { handleSubmit, loading };
};

export default useApprove;
