import type { ExecuteInstruction } from '@cosmjs/cosmwasm-stargate';
import { SigningCosmWasmClient } from '@cosmjs/cosmwasm-stargate';
import { Decimal } from '@cosmjs/math';
import type { AccountData, Coin, OfflineDirectSigner, OfflineSigner } from '@cosmjs/proto-signing';
import type { StdFee } from '@cosmjs/stargate';
import { GasPrice } from '@cosmjs/stargate';
import axios from 'axios';

import { configs } from '@/configs';
import { network } from '@/configs/network';

export const broadcastModeObj = {
  BROADCAST_MODE_BLOCK: 'BROADCAST_MODE_BLOCK',
  BROADCAST_MODE_ASYNC: 'BROADCAST_MODE_ASYNC',
  BROADCAST_MODE_SYNC: 'BROADCAST_MODE_SYNC',
  BROADCAST_MODE_UNSPECIFIED: 'BROADCAST_MODE_UNSPECIFIED',
};

type ClientData = {
  account: AccountData;
  client: SigningCosmWasmClient;
};

type Cosmos = {
  chainId: string;
};

export default class Wasm {
  public denom: string;

  public prefix: string;

  public chainId: string;

  // LEGACY to support backward compabilitiy for components
  public cosmos: Cosmos;

  constructor() {
    this.denom = network?.denom || '';
    this.prefix = network?.prefix || '';
    this.chainId = network?.chainId || '';
    this.cosmos = {
      chainId: this.chainId,
    };
  }

  getClientData = async (
    wallet: OfflineSigner | OfflineDirectSigner,
    denom?: string,
    prefix?: string,
  ): Promise<ClientData> => {
    const [firstAccount] = await wallet.getAccounts();

    const client = await SigningCosmWasmClient.connectWithSigner(network?.rpc as string, wallet, {
      gasPrice: new GasPrice(Decimal.fromUserInput('0', 6), denom || this.denom),
      prefix: prefix || this.prefix,
    });

    return { account: firstAccount as AccountData, client };
  };

  collectWallet = async () => {
    const keplr = await window.Keplr.getKeplr();
    if (!keplr) {
      throw 'You have to install Keplr first if you do not use a mnemonic to sign transactions';
    }
    return await keplr.getOfflineSignerAuto(this.chainId);
  };

  execute = async (address: string, inputData: string, funds: Coin[]) => {
    const wallet = await this.collectWallet();

    const { account, client } = await this.getClientData(wallet);

    const input = JSON.parse(inputData);
    const result = await client.execute(account.address, address, input, 'auto', undefined, funds);

    // LEGACY: backward compatibility for component files => need to return tx_response object
    return { ...result, tx_response: { txhash: result.transactionHash } };
  };

  executeWithGrant = async (contractAddress: string, inputData: string, grant: StdFee) => {
    const wallet = await this.collectWallet();
    const { account, client } = await this.getClientData(wallet);
    const input = JSON.parse(inputData);
    const result = await client.execute(account.address, contractAddress, input, grant);

    return { ...result, tx_response: { txhash: result.transactionHash } };
  };

  // LEGACY: support functions that are used in component files
  /**
   * get an object containing marketplace and ow721 contract addresses
   * @returns ContractAddress
   */
  get contractAddresses(): ContractAddress {
    return {
      marketplace: configs.marketPlace721Contract,
      ow721: configs.nftTokenContract,
      lock: configs.lockContractAddr,
      auction: configs.auctionContract,
      ow1155: configs.nft1155TokenContract,
      marketplace1155: configs.marketPlace1155Contract,
    };
  }

  async query(address: string, input: string) {
    const param = Buffer.from(input).toString('base64');
    // TODO: need to filter old & new cosmwasm version
    return this.get(`/cosmwasm/wasm/v1/contract/${address}/smart/${param}`);
  }

  async get(url: string) {
    return axios
      .get(`${network?.lcd}${url}`)
      .then((res) => res?.data)
      .catch((err) => err.response?.data);
  }

  executeMultipleTransaction = async (msgs: ExecuteInstruction[]) => {
    const wallet = await this.collectWallet();
    const { account, client } = await this.getClientData(wallet);
    const result = await client.executeMultiple(account.address, msgs, 'auto');

    return { ...result, tx_response: { txhash: result.transactionHash } };
  };

  executeMultipleTransactionGrant = async (msgs: ExecuteInstruction[], fee: StdFee) => {
    const wallet = await this.collectWallet();
    const { account, client } = await this.getClientData(wallet);
    const result = await client.executeMultiple(account.address, msgs, fee);

    return { ...result, tx_response: { txhash: result.transactionHash } };
  };
}
