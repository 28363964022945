import type { ButtonProps } from '@nextui-org/react';
import { Button } from '@nextui-org/react';
import React from 'react';

type Types =
  | 'primary'
  | 'secondary'
  | 'default'
  | 'success'
  | 'warning'
  | 'danger'
  | 'ghost'
  | 'disabled'
  | 'cancel'
  | 'white'
  | 'gray100'
  | 'black'
  | 'brown';
interface ButtonsProps {
  children: any;
  className?: string;
  typeBtn?: Types;
}

const Buttons: React.FC<ButtonsProps & ButtonProps> = ({
  children,
  className = '',
  typeBtn = 'primary',
  ...props
}) => {
  const type: {
    [key in Types]: {
      color: string;
      typeDefault:
        | 'primary'
        | 'secondary'
        | 'default'
        | 'success'
        | 'warning'
        | 'danger'
        | undefined;
      bg?: string;
    };
  } = {
    secondary: { color: 'text-primary', typeDefault: 'secondary' },
    primary: { color: 'text-white', typeDefault: 'primary' },
    default: { color: 'text-primary', typeDefault: 'default' },
    ghost: { color: 'text-black', typeDefault: undefined, bg: 'bg-ghost' },
    disabled: {
      color: '!text-white',
      typeDefault: undefined,
      bg: 'bg-gray-200',
    },
    cancel: {
      color: 'text-black',
      typeDefault: undefined,
      bg: 'bg-gray-50',
    },
    white: {
      color: 'text-black',
      typeDefault: undefined,
      bg: 'bg-white',
    },
    brown: {
      color: '!text-white',
      typeDefault: undefined,
      bg: 'bg-brown-500',
    },
    gray100: {
      color: '!text-black',
      typeDefault: undefined,
      bg: 'bg-gray-100',
    },
    black: {
      color: '!text-white',
      typeDefault: undefined,
      bg: 'bg-black',
    },
    success: { color: 'text-white', typeDefault: 'success' },
    warning: { color: 'text-white', typeDefault: 'warning' },
    danger: { color: 'text-white', typeDefault: 'danger' },
  };

  return (
    typeBtn && (
      <Button
        className={`rounded-[100px] px-5 py-2.5 text-15 font-semibold ${type[typeBtn].bg ?? ''}  ${
          type[typeBtn]?.color ?? ''
        }  ${className}  ${props.disabled ? 'cursor-not-allowed' : ''}`}
        color={type[typeBtn]?.typeDefault}
        {...props}
      >
        {children}
      </Button>
    )
  );
};

export default Buttons;
