import { useRouter } from 'next/navigation';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { useAuth } from '@/provider/UserProvider';
import type { IGetCollectionWithAddress } from '@/services/collection';
import { addAssetToCollection, getCollectionWithAddress } from '@/services/collection';
import type { CollectionData } from '@/types/collection';

const PAGE_SIZE = 30;

const useAddCollection = () => {
  const collectionRef = useRef([]);
  const { auth } = useAuth();
  const router = useRouter();
  const [loadingList, setLoadingList] = useState(false);
  const [loading, setLoading] = useState(false);
  const currentPage = 1;
  const [collectId, setCollectId] = useState<string>('');
  const [isAddNew, setIsAddNew] = useState<boolean>(false);
  const [newName, setNewName] = useState<string>('');
  const [collections, setCollections] = useState<CollectionData[]>([]);
  const [refreshGetCollection, setRefreshGetCollection] = useState(false);

  const handleGetCollection = async () => {
    setLoadingList(true);
    const queryParams: IGetCollectionWithAddress = {
      pageSize: PAGE_SIZE,
      offset: PAGE_SIZE * (currentPage - 1),
      address: auth?.user?.publicAddress,
    };

    const res = await getCollectionWithAddress(queryParams);
    if (currentPage === 1) {
      collectionRef.current = [];
      setCollections([]);
    }
    collectionRef.current = collectionRef.current.concat(res?.data || []);
    setCollections(collectionRef.current);
    setLoadingList(false);
  };

  const handleAddToCollection = async (assetId: number) => {
    try {
      if (!collectId) return;
      setLoading(true);
      await addAssetToCollection(collectId.toString(), { nftIds: [assetId] });
      toast.success('Add to collection successfully!');
      setLoading(false);
      router.refresh();
    } catch (error: any) {
      toast.error(error?.message);
    }
  };

  useEffect(() => {
    handleGetCollection();
  }, [refreshGetCollection]);

  const onAddNewCollection = () => {
    setCollectId('');
  };

  const onAddCollectionId = (id: string) => {
    setCollectId(id);
    setIsAddNew(false);
  };

  const reset = () => {
    setCollectId('');
    setIsAddNew(false);
    setNewName('');
  };

  return {
    collectId,
    isAddNew,
    setCollectId,
    setIsAddNew,
    onAddNewCollection,
    setNewName,
    newName,
    reset,
    onAddCollectionId,
    loadingList,
    collections,
    handleAddToCollection,
    loading,
    setRefreshGetCollection,
  };
};

export default useAddCollection;
