import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@nextui-org/react';
import React from 'react';

import { IconApproveMarket, IconClose } from '@/assets';

import useApprove from './useApprove';

const MarketApproveDialog: React.FC<{
  isOpen: boolean;
  onOpenChange: () => void;
  onClose: () => void;
  callback?: () => void;
  isGranted: boolean;
}> = ({ isOpen, onOpenChange, onClose, callback, isGranted }) => {
  const { handleSubmit, loading } = useApprove(onClose, callback);

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      placement="center"
      hideCloseButton
      classNames={{
        base: 'rounded-[32px]',
        body: 'px-[30px] pt-0',
        footer: 'px-[30px] py-[30px]',
      }}
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex justify-between px-[30px] pb-[0px] pt-[25px]">
              <div className="max-w-[300px] text-[24px] font-semibold leading-[1.5] text-black">
                Approve the market contract
              </div>
              <IconClose onClick={onClose} className="mt-1 scale-125 cursor-pointer" />
            </ModalHeader>
            <ModalBody>
              <div className="flex justify-center">
                <IconApproveMarket />
              </div>
              <p className="text-[16px] leading-[1.5] text-gray-500">
                Please sign the approval transaction below to give our contract the permission to
                process all the functionalities on your data in the marketplace.
              </p>
              <p className="rounded-[20px] bg-yellow-50 p-4 text-[16px] leading-[1.5] text-black">
                You won’t be able to sell your artworks or prompts on aiRight before you approve the
                contract.
              </p>
            </ModalBody>
            <ModalFooter>
              <Button
                isLoading={loading}
                className="h-full w-full rounded-[100px] px-[20px] py-[10px] text-[15px] font-semibold text-white"
                color="primary"
                onPress={() => handleSubmit(isGranted)}
              >
                Approve now
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default MarketApproveDialog;
