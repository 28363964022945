import Rive from '@rive-app/react-canvas';
import { ErrorBoundary } from '@sentry/nextjs';
import Lottie from 'lottie-react';
import dynamic from 'next/dynamic';
import React, { useEffect, useState } from 'react';

import { MusicPlayAudioIcon } from '@/assets';
import { Images } from '@/components';
import { classifyMediaType, MediaType } from '@/utils/classifyMediaType';
import ViewFullMedia from '../view-media';

const DynamicNsfwMark = dynamic(() => import('@/components/NsfwMark'));

const RenderMedia: React.FC<{
  dataUrl: string;
  mimeType: string;
  isBlur?: boolean;
}> = ({ dataUrl, mimeType, isBlur = false }) => {
  const [jsonFile, setJsonFile] = useState();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState('');
  const [currentType, setCurrentType] = useState('');

  const openModal = (src: any, type: any) => {
    setIsModalOpen(true);
    setCurrentImage(src);
    setCurrentType(type);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentImage('');
    setCurrentType('');
  };

  const readUrlJson = async () => {
    const json = await fetch(dataUrl).then((response) => response.json());
    return setJsonFile(json);
  };
  useEffect(() => {
    if (mimeType === 'application/json' && !jsonFile) {
      readUrlJson();
    }
  }, [dataUrl]);
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        closeModal();
      }
    };

    if (isModalOpen) {
      document.addEventListener('keydown', handleKeyPress);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [isModalOpen]);

  const render = {
    [MediaType.IMAGE]: (
      <div className="w-[640px]  items-center justify-center xl:w-auto">
        <div className="relative overflow-hidden rounded-[24px] border-1 border-solid border-[#F0F2FA] p-[20px] md:w-full">
          <Images
            className="cursor-pointer rounded-[24px]"
            src={dataUrl}
            alt="thumbnail"
            onClick={() => openModal(dataUrl, mimeType)}
          />
          {isBlur && (
            <DynamicNsfwMark url={dataUrl} />
          )}
        </div>
        {currentType.includes('image') && (
          <ViewFullMedia
            isOpen={isModalOpen}
            onClose={closeModal}
            url={currentImage}
          />
        )}
      </div>
    ),
    [MediaType.AUDIO]: (
      <div className="my-10 flex flex-col items-center">
        <MusicPlayAudioIcon />
        <div className="mt-5 h-[50px] w-[500px] sm:w-[300px]">
          <audio className="h-full w-full" controls>
            <source src={dataUrl} />
          </audio>
        </div>
      </div>
    ),
    [MediaType.JSON]: (
      <div>
        <div className="h-full max-h-[498px] w-full">
          {jsonFile && (
            <ErrorBoundary fallback={<>Json File</>}>
              <Lottie
                className="h-full w-full [&>svg]:max-h-[498px] [&>svg]:max-w-[498px]"
                autoPlay
                animationData={jsonFile}
                onDataFailed={() => <>Json File</>}
              />
            </ErrorBoundary>
          )}
        </div>
      </div>
    ),
    [MediaType.TEXT]: <div>{dataUrl}</div>,
    [MediaType.VIDEO]: (
      <div className="relative my-10 w-[700px] before:block before:pb-[56.25%] lg:w-[500px] sm:!w-full">
        <video controls className="absolute left-0 top-0 h-full w-full">
          <source src={dataUrl} />
        </video>
      </div>
    ),
    [MediaType.RIV]: (
      <div className="h-[400px] w-[400px] sm:h-[300px] sm:w-[300px]">
        <Rive src={dataUrl} stateMachines="state-machine" />
      </div>
    ),
  };

  return render[classifyMediaType(mimeType)];
};

export default RenderMedia;
