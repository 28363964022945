export * from './maketPlace';
export * from './view';

export type ParamsType = {
  params: { id: string };
  searchParams: { [key: string]: string | string[] | undefined };
};

export enum ProfileSocial {
  twitter = 'TWITTER',
  web = 'WEB',
  telegram = 'TELEGRAM',
  facebook = 'FACEBOOK',
  linkedin = 'LINKEDIN',
  medium = 'MEDIUM',
  gmail = 'GMAIL',
  youtube = 'YOUTUBE',
  instagram = 'INSTAGRAM',
  discord = 'DISCORD',
}

export enum ArtworkDonateType {
  Template = 'template',
  Tool = 'tool',
  Model = 'model',
}
