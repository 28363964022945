const cachedLocalStorage = {
  setWithExpiry: (key: string, value: any, ttl?: number) => {
    if (typeof window === 'undefined') return;
    const now = new Date();

    const item = {
      value,
      ...(ttl && { expiry: now.getTime() + ttl }),
    };

    localStorage.setItem(key, JSON.stringify(item));
  },

  setItem: (key: string, value: string) => {
    if (typeof window === 'undefined') return;
    try {
      localStorage.setItem(key, value);
    } catch (error) {
      return '';
    }
  },

  removeItem: (key: string) => {
    if (typeof window === 'undefined') return;
    try {
      localStorage.removeItem(key);
    } catch (error) {
      return '';
    }
  },

  getItemStr: (key: string) => {
    try {
      if (typeof window === 'undefined') return;
      const itemStr = localStorage?.getItem(key);
      if (!itemStr) {
        return null;
      }
      return itemStr;
    } catch (error) {
      return '';
    }
  },

  getItem: (key: string) => {
    if (typeof window === 'undefined') return;
    try {
      const itemStr = localStorage.getItem(key);
      if (!itemStr) {
        return null;
      }
      const item = JSON.parse(itemStr);
      return item.value;
    } catch (error) {
      return '';
    }
  },

  getWithExpiry: (key: string) => {
    if (typeof window === 'undefined') return;
    try {
      const itemStr = localStorage.getItem(key);
      if (!itemStr) {
        return null;
      }
      const item = JSON.parse(itemStr);
      const now = new Date();
      if (now.getTime() > item.expiry) {
        localStorage.removeItem(key);
        return null;
      }
      return item.value;
    } catch (error) {
      return;
    }
  },

  getAllWithExpiry: (key: string) => {
    if (typeof window === 'undefined') return;
    try {
      const itemStr = localStorage.getItem(key);
      if (!itemStr) {
        return null;
      }
      const item = JSON.parse(itemStr);
      const now = new Date();
      if (now.getTime() > item.expiry) {
        localStorage.removeItem(key);
        return null;
      }
      return item;
    } catch (error) {
      return '';
    }
  },
};

export default cachedLocalStorage;
