import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { configs } from '@/configs';
import { network } from '@/configs/network';
import type { InputExecuteSSO } from '@/libs/ssoWallet';
import { ActionSSO, ssoExecute } from '@/libs/ssoWallet';
import { useSocket } from '@/provider/SocketProvider';
import { useAuth } from '@/provider/UserProvider';
import type { IOffer } from '@/types/asset';
import { handleExecute } from '@/utils/wasm';

interface WithdrawParams {
  offer: IOffer | null;
  gas: number;
  fees: number;
  is1155?: boolean;
}

const MESS_FINISH = 'Stop selling artwork successfully!';

const useWithdraw = (assetId: number, refresh: () => void, callback?: () => void) => {
  const { auth } = useAuth();
  const { socket } = useSocket();
  const [loading, setLoading] = useState(false);
  const [finish, setFinish] = useState('');
  const [err, setErr] = useState('');
  const walletAddress = auth.user.publicAddress;

  const handleExecuteWithOwallet = async (
    marketplace: string,
    gas: number,
    fees: number,
    message: string,
  ) => {
    setLoading(true);
    const withdrawResult = await handleExecute(marketplace, walletAddress, message, {
      gas,
      fees,
      mode: 'BROADCAST_MODE_BLOCK',
    });
    if (withdrawResult.tx_response.txhash) {
      listenBuyingEvent(assetId.toString());
    }
  };

  const handleExecuteWithSSO = async (marketplace: string, message: string) => {
    setLoading(true);
    const input: InputExecuteSSO = {
      contractAddress: marketplace,
      chainId: network?.chainId || 'Oraichain',
      params: message,
    };
    await ssoExecute(input, ActionSSO.stopSellArtwork);
    listenBuyingEvent(assetId.toString());
  };

  const handleWithdraw = async ({ offer, gas, fees, is1155 = false }: WithdrawParams) => {
    if (offer === null) return;
    if (gas > 4000000 || gas < 400000) {
      toast.error('Gas number must be between 400,000 and 4,000,000');
      return;
    }
    const message = JSON.stringify({
      withdraw_nft: {
        offering_id: offer?.offeringId,
      },
    });
    const { marketplace1155 } = window.Wasm.contractAddresses;
    const marketplace =
      offer?.version === 2
        ? configs.marketPlace721Contract
        : offer?.contractAddress || configs.marketPlaceContract;

    const contract = is1155 ? marketplace1155 : marketplace;

    try {
      if (auth.isOwallet) {
        await handleExecuteWithOwallet(contract || '', gas, fees, message);
      } else {
        await handleExecuteWithSSO(contract || '', message);
      }
    } catch (error: any) {
      if (error === 'popup-closed') {
        toast.error(
          'The signing transaction popup might be closed or your browser has blocked the popup! Please try again or check your browser setting',
        );
        return;
      }
      console.log('error on withdraw: ', error);
      toast.error(error?.message);
    }
  };

  const listenBuyingEvent = (assetId: string) => {
    if (!socket) return;
    const event = `withdraw-${assetId}`;
    socket.on(event, () => {
      socket.removeListener(event);
      setLoading(false);
      setFinish(MESS_FINISH);
      if (callback) callback();
      refresh();
    });
  };

  // for sso
  useEffect(() => {
    if (typeof window !== 'undefined' && !auth.isOwallet) {
      window.addEventListener(
        'message',
        (event) => {
          if (event.origin !== configs.ssoUrl) return;
          const data = JSON.parse(event?.data || '');

          if (data.action !== ActionSSO.stopSellArtwork) return;
          if (data.status !== 'success') {
            setLoading(false);
            setErr(data.response);
          }
        },
        false,
      );
    }
    return window.removeEventListener('message', () => {});
  }, []);

  useEffect(() => {
    if (err) {
      toast.error(err);
    }
  }, [err]);

  useEffect(() => {
    if (finish) {
      toast.success(finish);
    }
  }, [finish]);

  return { handleWithdraw, loading };
};

export default useWithdraw;
