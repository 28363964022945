import { debounce } from 'lodash';
import { useLayoutEffect, useState } from 'react';

const useGetWidth = () => {
  const [Width, setWidth] = useState<number>(0);

  const onResize = () => {
    const { width } = window.screen;
    setWidth(width);
  };

  useLayoutEffect(() => {
    onResize();
  }, []);

  useLayoutEffect(() => {
    window.addEventListener('resize', debounce(onResize, 500));
    return () => window.removeEventListener('resize', onResize);
  }, []);
  return Width;
};

export default useGetWidth;
