import { Input } from '@nextui-org/react';
import { debounce } from 'lodash';
import React from 'react';

const InputQuantity: React.FC<{
  defaultValue?: number;
  total: number;
  setQuantity: (val: number) => void;
  err: string;
  setErr: (val: string) => void;
  isIconRequired?: boolean;
}> = ({ defaultValue, total, setQuantity, err, setErr, isIconRequired = false }) => {
  const onChangeQuantity = (val: string) => {
    if (!val) {
      setErr('Please enter quantity!');
      return;
    }
    const value = Number(val);
    if (value < 1 || value > total) {
      setErr(`Quantity must be from 1 to ${total}`);
      return;
    }
    setErr('');
    setQuantity(value);
  };

  return (
    <div>
      <div>
        <p className="mb-2 text-[14px] font-semibold text-black">
          Quantiy {isIconRequired && <span className="text-red-600">*</span>}
        </p>
        <Input
          defaultValue={defaultValue?.toString()}
          classNames={{
            input: ['dark:placeholder:text-white font-normal text-14 pl-1', 'rounded-[100px]'],
            innerWrapper: '!bg-gray-50 rounded-[100px]',
            inputWrapper: '!bg-gray-50 rounded-[100px] shadow-none h-[40px]',
          }}
          max={total}
          min={1}
          type="number"
          onChange={debounce((e) => onChangeQuantity(e.target.value), 300)}
          endContent={<span className="text-[14px] text-gray-500">/{total}</span>}
          placeholder="Enter quantity"
        />
      </div>
      {err && <p className="mt-1 text-[14px] text-red-500">{err}</p>}
    </div>
  );
};

export default InputQuantity;
