/* eslint-disable react/no-unused-prop-types */
// @ts-nocheck

import { ErrorBoundary } from '@sentry/nextjs';
import Lottie from 'lottie-react';
import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { match } from 'ts-pattern';

import {
  MusicPlayAudioIcon,
  PauseAudioBlackIcon,
  PauseIcon,
  PlayAudioBlackIcon,
  PlayAudioIcon,
} from '@/assets';
import { ButtonIcon, Images } from '@/components';
import type { ListSource } from '@/configs/constant';
import { convertJsonFileToStringJson } from '@/utils';

type Props = {
  asset: any;
  listSource: ListSource;
  disabledClick?: boolean;
  isControl?: boolean;
  isPlayIcon?: boolean;
  isNotBorder?: boolean;
  isAudioBorder?: boolean;
  classImage?: string;
  overlay?: boolean;
  isImageLazy?: boolean;
  setByPicture?: boolean;
};

type CardAudioPlayerProps = {
  isPlayIcon?: boolean;
  isNotBorder?: boolean;
  isControl?: boolean;
  asset: {
    url: string;
    picture: string;
    id: string;
  };
  autoPlay?: boolean | false;
  type?: string | 'audio/mpeg';
  listSource?: string;
  disabledClick?: boolean;
  isAudioBorder?: boolean;
};

type CardVideoPlayerProps = {
  isNotBorder?: boolean;
  isControl?: boolean;
  asset: {
    url: string;
    picture: string;
    id: string;
  };
  autoPlay?: boolean | false;
  type?: string | 'video/mpeg';
  listSource?: string;
  disabledClick?: boolean;
  isPlayIcon?: boolean;
};

// const useAudio = (url: string) => {
//   const [audio] = useState(
//     typeof Audio !== 'undefined' ? new Audio(url) : undefined,
//   );
//   const [playing, setPlaying] = useState(false);

//   const toggle: any = () => setPlaying(!playing);

//   useEffect(() => {
//     if (audio) {
//       if (playing) {
//         audio.play();
//       } else {
//         audio.pause();
//       }
//     }
//   }, [playing]);

//   useEffect(() => {
//     if (audio) {
//       audio.addEventListener('ended', () => setPlaying(false));
//     }
//     return () => {
//       if (audio) {
//         audio.removeEventListener('ended', () => setPlaying(false));
//         audio.currentTime = 0;
//         audio.pause();
//       }
//     };
//   }, []);

//   return [playing, toggle];
// };

const CardAudioPlayer: React.FC<CardAudioPlayerProps> = (props: CardAudioPlayerProps) => {
  const { asset, isControl, isNotBorder, isPlayIcon, isAudioBorder } = props;
  const { url } = asset;
  const hasAudioBorder = isAudioBorder !== undefined ? isAudioBorder : true;

  const [playing, setPlaying] = useState(false);

  useEffect(() => {
    if (playing) {
      setPlaying(false);
    }
  }, []);

  return (
    <div onClick={() => {}} className="relative h-full w-full">
      {!isControl && isPlayIcon && (
        <ButtonIcon
          onClick={(e) => {
            e.stopPropagation();
            setPlaying(!playing);
          }}
          className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-transparent"
        >
          {playing ? <PauseAudioBlackIcon /> : <PlayAudioBlackIcon />}
        </ButtonIcon>
      )}
      {hasAudioBorder ? (
        <div className="absolute flex h-full w-full items-center justify-center rounded-[16px] border border-dashed border-gray-200 bg-gray-50 lg:rounded-[10px] sm:!rounded-[4px]">
          <MusicPlayAudioIcon />
        </div>
      ) : (
        <div className="absolute flex h-full w-full items-center justify-center bg-gray-50">
          <MusicPlayAudioIcon />
        </div>
      )}
      <ReactPlayer
        className={`!h-full !w-full p-3 ${!isNotBorder ? '[&>video]:rounded-2xl' : ''}`}
        config={{
          file: {
            attributes: {
              controlsList: 'nodownload',
            },
          },
        }}
        controls={isControl}
        playing={playing}
        url={url}
      />
    </div>
  );
};

const CardVideoPlayer: React.FC<CardVideoPlayerProps> = (props: CardVideoPlayerProps) => {
  const { asset, isControl, isPlayIcon, isNotBorder } = props;
  const { url } = asset;

  const [playing, setPlaying] = useState(false);

  useEffect(() => {
    if (playing) {
      setPlaying(false);
    }
  }, []);

  return (
    <div onClick={() => {}} className="relative h-full w-full">
      {!isControl && isPlayIcon && (
        <ButtonIcon
          onClick={(e) => {
            e.stopPropagation();
            setPlaying(!playing);
          }}
          className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-transparent xl:scale-75"
        >
          {playing ? <PauseIcon /> : <PlayAudioIcon />}
        </ButtonIcon>
      )}

      <ReactPlayer
        className={`${!isNotBorder ? '[&>video]:rounded-[16px]' : ''} [&>video]:object-cover`}
        config={{
          file: {
            attributes: {
              controlsList: 'nodownload',
            },
          },
        }}
        height="100%"
        width="100%"
        controls={isControl}
        playing={playing}
        url={url}
      />
    </div>
  );
};

export const CardRenderFollowType: React.FC<Props> = (props: Props) => {
  const {
    asset,
    listSource,
    isPlayIcon,
    isControl,
    isNotBorder,
    isAudioBorder,
    classImage = '',
    overlay = false,
    isImageLazy = true,
    setByPicture = null,
  } = props;
  let { url } = asset;
  if (!asset) return null;

  if (setByPicture) {
    url = asset.picture;
  }
  if (!url) return <></>;
  let { mimeType } = asset;

  mimeType = mimeType?.split('/')[0] ?? 'image';

  const renderCard = () => {
    switch (mimeType) {
      case 'image':
        return (
          <div className="h-full w-full">
            <Images
              className={`h-full w-full object-cover ${classImage}`}
              loading={isImageLazy ? 'lazy' : 'eager'}
              src={url}
              alt={url}
              overlay={overlay}
            />
          </div>
        );
      case 'audio':
        return (
          <CardAudioPlayer
            isPlayIcon={isPlayIcon}
            isControl={isControl}
            listSource={listSource}
            asset={{ id: asset.id.toString(), url: asset.url, picture: asset.picture }}
            isNotBorder={isNotBorder}
            isAudioBorder={isAudioBorder}
          />
        );
      case 'video':
        return (
          <CardVideoPlayer
            isPlayIcon={isPlayIcon}
            isControl={isControl}
            listSource={listSource}
            asset={{ id: asset.id.toString(), url: asset.url, picture: asset.picture }}
            isNotBorder={isNotBorder}
          />
        );
      case 'application':
        return (
          <div className="h-full w-full">
            <ErrorBoundary fallback={<>Json File</>}>
              <Lottie className="h-full w-full" path={url} />
            </ErrorBoundary>
          </div>
        );
      default:
        return <></>;
    }
  };

  return <div className="h-full w-full">{renderCard()}</div>;
};

export const CardFileRenderFollowType: React.FC<{
  type: string;
  file: File;
  isControl?: boolean;
  isPlayIcon?: boolean;
  isNotBorder?: boolean;
  enablerAnimation?: boolean;
}> = ({ type, file, isControl, isNotBorder, isPlayIcon, enablerAnimation }) => {
  const [fileJson, setFileJson] = useState<any>();

  const url = URL.createObjectURL(file);

  useEffect(() => {
    if (file.type === 'application/json') {
      convertJsonFileToStringJson(file, setFileJson);
    }
  }, [file]);

  const renderCard = () => {
    return match(type)
      .with('video', () => (
        <CardVideoPlayer
          isNotBorder={isNotBorder}
          isControl={isControl}
          isPlayIcon={isPlayIcon}
          asset={{ url, id: '', picture: '' }}
        />
      ))
      .with('audio', () => (
        <CardAudioPlayer
          isPlayIcon={isPlayIcon}
          isNotBorder={isNotBorder}
          isControl={isControl}
          asset={{ url, id: '', picture: '' }}
          disabledClick
        />
      ))
      .with('image', () => (
        <div
          aria-expanded={!isNotBorder}
          className="flex h-full w-full items-center justify-center bg-gray-50 aria-expanded:rounded-[16px]"
        >
          <Images
            aria-expanded={!isNotBorder}
            className="object-contain aria-expanded:rounded-[16px]"
            src={url}
            alt={url}
          />
        </div>
      ))
      .with('application', () =>
        fileJson && enablerAnimation ? (
          <div
            aria-expanded={!isNotBorder}
            className="flex h-full w-full items-center justify-center bg-gray-50 aria-expanded:rounded-[16px]"
          >
            <ErrorBoundary fallback={<>Json File</>}>
              <Lottie className="h-full w-full" animationData={fileJson} loop />
            </ErrorBoundary>
          </div>
        ) : (
          <div
            aria-expanded={!isNotBorder}
            className="flex h-full w-full items-center justify-center bg-gray-50 aria-expanded:rounded-[16px]"
          >
            <Images
              aria-expanded={!isNotBorder}
              className="object-contain aria-expanded:rounded-[16px]"
              src=""
              alt="Json file"
            />
          </div>
        ),
      )
      .otherwise(() => <></>);
  };

  return <div className="h-full w-full">{renderCard()}</div>;
};
