'use client';

import type { ReactNode } from 'react';
import React, { useState } from 'react';
import makeAnimated from 'react-select/animated';
import AsyncCreatableSelect from 'react-select/async-creatable';

export interface SelectOptionItem {
  value: string | number;
  label: string;
}

export interface AsyncCreatableSelectProps {
  placeholder?: string;
  options?: SelectOptionItem[];
  onChangeValue?: (val: SelectOptionItem[]) => void;
  defaultValue?: SelectOptionItem[];
  loadOptions?: (val: string) => void;
  defaultOptions?: boolean;
  isDisable?: boolean;
  styleProps?: any;
  cacheOptions?: boolean;
  isValidNewOption?: (e: any) => boolean;
  formatCreateLabel?: (inputValue: string) => ReactNode;
  hideSelectedOptions?: boolean;
  controlShouldRenderValue?: boolean;
  optionsComponent?: any;
  inputValue?: string;
  onInputChange?: any;
  keepInput?: boolean;
  value?: any;
  isClearable?: boolean;
  menuPlacement?: 'top' | 'bottom' | 'auto';
}

const animatedComponents = makeAnimated();

const SelectMultipleWithSearch: React.FC<AsyncCreatableSelectProps> = ({
  placeholder,
  options,
  onChangeValue,
  defaultValue,
  loadOptions,
  isDisable = false,
  defaultOptions = true,
  styleProps = {},
  cacheOptions = false,
  optionsComponent,
  keepInput = false,
  isClearable = true,
  ...props
}) => {
  const [searchTag, setSearchTag] = useState('');

  const keepInputProps = keepInput
    ? {
        inputValue: searchTag,
        onInputChange: (value: string, action: any) => {
          // only set the input when the action that caused the
          // change equals to "input-change" and ignore the other
          // ones like: "set-value", "input-blur", and "menu-close"
          if (action.action === 'input-change') setSearchTag(value); // <---
        },
      }
    : {};

  return (
    <AsyncCreatableSelect
      {...keepInputProps}
      isClearable={isClearable}
      cacheOptions={cacheOptions}
      closeMenuOnSelect={false}
      components={optionsComponent ?? animatedComponents}
      isMulti
      defaultOptions={defaultOptions}
      defaultValue={defaultValue}
      placeholder={placeholder || 'Select option'}
      options={options}
      loadOptions={loadOptions}
      onChange={(val: any) => onChangeValue && onChangeValue(val as SelectOptionItem[])}
      isDisabled={isDisable}
      styles={{
        multiValueRemove: () => ({
          '&:hover': {
            backgroundColor: 'none',
          },
        }),
        control: (baseStyles: any) => ({
          ...baseStyles,
          background: '#F8F9FD',
          border: 'none',
          outline: 'none',
          boxShadow: 'none',
          borderRadius: 100,
          padding: '0px 6px',
          paddingLeft: 0,
          fontSize: 14,
          minHeight: 40,
          cursor: 'pointer',
        }),
        multiValue: (baseStyles: any) => ({
          ...baseStyles,
          background: 'white',
          padding: '3px 7px',
          borderRadius: 100,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }),
        multiValueLabel: (baseStyles: any) => ({
          ...baseStyles,
          fontSize: 14,
        }),
        menu: (baseStyles: any) => ({
          ...baseStyles,
          background: 'white',
          borderRadius: 10,
          border: 'none',
          padding: 8,
        }),
        option: (baseStyles: any, state: any) => ({
          ...baseStyles,
          background: state.isFocused ? '#F8F9FD !important' : 'white',
          borderRadius: 100,
          color: '#181818',
          fontSize: 14,
          cursor: 'pointer',
        }),
        ...styleProps,
      }}
      {...props}
    />
  );
};

export default SelectMultipleWithSearch;
