import { api } from '../axios';

export interface IDataPostUsecase {
  name: string;
  description: string;
  processingAssetId: number | string;
}
export interface IDataEditUsecase {
  name: string;
  description: string;
  useCaseId: number | string;
}

export const postUsecaseById = async (arg: IDataPostUsecase) => {
  return api.post('/use-case', arg);
};
export const postEditUsecase = async (arg: IDataEditUsecase) => {
  return api.post('/use-case/update', arg);
};

export const postIncreaseTotalUse = async (id: number) => {
  return api.post(`/use-case/increase-total-use/${id}`);
};
export const postUnpublishTemplalte = async (id: number) => {
  return api.post(`/use-case/unpublish`, { ids: [id] });
};

export const getUsecaseById = async (id: number | string) => {
  return api.get(`/use-case/${id}`);
};

export interface IPostUsecaseTag {
  useCaseId: number;
  tags: { content: string }[];
}
export const postUsecaseTag = async (arg: IPostUsecaseTag) => {
  return api.post('/use-case/tag', arg);
};

export interface IGetListUseCase {
  size: number;
  offset: number;
  sort?: string;
  filter?: string;
  creatorID?: number;
  totalUse?: string;
}

export const getListUseCase = async (args: IGetListUseCase) => {
  const { size, offset, sort, filter } = args;
  return await api.get('/use-case', { params: { size, offset, sort, filter } });
};

export const getListUseCaseProfile = async (args: IGetListUseCase) => {
  const { size, offset, sort, filter, creatorID } = args;
  return await api.get('/use-case', { params: { size, offset, sort, filter, creatorID } });
};

export const getListBookMarkMe = async (args: IGetListUseCase) => {
  const { size, offset, sort, filter, creatorID } = args;
  return await api.get('/use-case/bookmark/me', {
    params: { size, offset, sort, filter, creatorID },
  });
};

export const getUseCaseTrending = async (args: IGetListUseCase) => {
  const { size, offset, sort, filter, totalUse } = args;
  return await api.get('/use-case', { params: { size, offset, sort, filter, totalUse } });
};

export const getUseCase = async (id: number) => {
  const res = await api.get(`/use-case/${id}`);
  return res.data;
};

export const getUseCases = async ({
  filter,
  size,
  offset,
}: {
  filter: string;
  size: number;
  offset: number;
}) => {
  const res = await api.get(`/use-case/examples`, {
    params: { size, offset, filter },
  });
  return res.data;
};

export const publishTemplate = async (ids: number[]) => {
  return api.post('/use-case/publish', { ids });
};
export const publishTemplateByWorkflow = async (processingAssetId: number) => {
  return api.post(`/use-case/${processingAssetId}/publish`);
};
export const deleteTemplate = async (ids: number[]) => {
  return api.post('/processing-assets/delete', { ids });
};

export const getListUseCaseSimilarity = async (args: IGetListUseCase, usecaseId: number) => {
  const { size, offset, sort, filter } = args;
  const res = await api.get(
    `/use-case/${usecaseId}/similarity?limit=${size}&offset=${offset}&sort=${sort}&filter=${filter}`,
  );
  return res.data;
};

export const getListUseCaseModel = async (args: IGetListUseCase, modelId: number) => {
  const { size, offset, sort, filter } = args;
  const res = await api.get(
    `/lora/${modelId}/related?size=${size}&offset=${offset}&sort=${sort}&filter=${filter}`,
  );
  return res.data;
};

export const likeTemplate = async (id: number) => {
  const res = await api.post(`/reactions/${id}/like-template`);
  return res.data;
};

export const unLikeTemplate = async (id: number) => {
  const res = await api.post(`/reactions/${id}/unlike-template`);
  return res.data;
};

export const apiAddBookMark = async (id: number) => {
  const res = await api.post(`/use-case/bookmark/add/${id}`);
  return res.data;
};

export const apiUnBookMark = async (id: number) => {
  const res = await api.delete(`/use-case/bookmark/remove/${id}`);
  return res.data;
};
