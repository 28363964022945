import { api } from '@/services/axios';

export const listCampaign = async () => {
  try {
    const res = await api.get('/campaigns?size=50&offset=0');
    return res.data;
  } catch (error) {
    return error;
  }
};

export const listCampaignByModel = async (filter: any) => {
  const res = await api.get(`/campaigns?size=50&offset=0&filter=${filter}`);
  return res;
};

export const detailCampaign = async (id: string) => {
  try {
    const res = await api.get(`/campaigns/${id}/details`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getTopNftById = async (id: string) => {
  try {
    const res = await api.get(`/campaigns/${id}/top-nft`);
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getDailyPicksById = async (id: string) => {
  try {
    const res = await api.get(`/campaigns/${id}/daily-picks`);
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getAllNft = async ({ id, pageSize, offset, collectionId }: any) => {
  try {
    const res = await api.get(
      `/campaigns/${id}/all-nfts?size=${pageSize}&offset=${offset}&filter={"collectionIds": [${collectionId}]}&sort={"createdAt":"DESC"}`,
    );
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getMyNfts = async ({ id, pageSize, offset, address, collectionId }: any) => {
  try {
    const res = await api.get(
      `/campaigns/${id}/my-nfts?size=${pageSize}&offset=${offset}&filter={"userAddress":"${address}","nftStatuses":[2], "collectionIds": [${collectionId}]}&sort={"createdAt":"DESC"}`,
    );
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getTasksCampaign = async (id: string) => {
  try {
    const res = await api.get(`/campaigns/${id}/task`);
    return res.data;
  } catch (error) {
    return error;
  }
};

export const voteNft = async (campaignsId: string, assetId: string, vote: number) => {
  try {
    const res = await api.post(`/campaigns/${campaignsId}/vote`, {
      assetId: Number(assetId),
      vote,
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const verifyTask = async (taskId: any) => {
  try {
    const res = await api.patch(`/campaigns/verify-task`, {
      taskId,
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const getCampaignExample = async ({ campaignId, modelId }: any) => {
  try {
    const res = await api.get(`/campaigns/${campaignId}/example?modelId=${modelId}`);
    return res.data;
  } catch (error) {
    return error;
  }
};

export const checkFinishedTask = async (id: number) => {
  try {
    const res = await api.post(`/campaigns/${id}/finished-task`);
    return res;
  } catch (error) {
    return error;
  }
};
