'use client';

import type {
  AppRouterInstance,
  NavigateOptions,
} from 'next/dist/shared/lib/app-router-context.shared-runtime';
import { usePathname, useRouter as useRouterOrigin } from 'next/navigation';
import React, { createContext, useContext, useMemo } from 'react';

import { useLayout } from '@/provider/LayoutProvider';

type RouterProviderProps = {
  children: React.ReactNode;
};

interface NewAppRouterInstance extends Omit<AppRouterInstance, 'push'> {
  push: (
    _href: string,
    _options?: NavigateOptions,
    _optionsCustom?: { noLoadBar: boolean },
  ) => void;
}

export const initialStateRouter: NewAppRouterInstance = {
  back: () => window.history.back(),
  forward: () => window.history.forward(),
  prefetch: () => {},
  push: () => {},
  refresh: () => {},
  replace: () => {},
};

const RouterProviderContext = createContext(initialStateRouter);

export const RouterProvider: React.FC<RouterProviderProps> = ({ children }) => {
  const routerOrigin = useRouterOrigin();
  const pathname = usePathname();
  const { setIsLoadingBar } = useLayout();

  const contextValue: any = useMemo(() => {
    return {
      ...routerOrigin,
      push(href: string, options?: NavigateOptions, optionsCustom?: { noLoadBar: boolean }) {
        if (href === pathname) return;
        if (!optionsCustom?.noLoadBar) {
          setIsLoadingBar(true);
        }
        routerOrigin.push(href, options);
      },
    };
  }, [routerOrigin, pathname]);

  return (
    <RouterProviderContext.Provider value={contextValue}>{children}</RouterProviderContext.Provider>
  );
};

export const useRouter = () => useContext(RouterProviderContext);
