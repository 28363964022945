import type { CardProps } from '@nextui-org/react';
import { Card } from '@nextui-org/react';
import type { ReactNode } from 'react';
import React from 'react';

import { DirectorCardCreatorIcon } from '@/assets';
import { Images } from '@/components';

interface CardCreatorProps {
  img: string;
  title?: string;
  totalCreated?: number;
  cost?: number;
  className?: string;
  footer?: ReactNode;
}

const CardCreator: React.FC<CardCreatorProps & CardProps> = ({
  img,
  title,
  totalCreated,
  cost,
  className = '',
  footer,
  ...props
}) => {
  return (
    <Card
      isFooterBlurred
      radius="lg"
      className={`group relative cursor-pointer border-none ${className}`}
      {...props}
    >
      <Images
        className="object-cover duration-400 group-hover:scale-125"
        alt="card image"
        src={img}
      />
      <div className="absolute bottom-0 z-10 w-full">
        {footer || (
          <div className="flex w-full items-end justify-between bg-transparent p-7">
            <div className="flex flex-col">
              <span className="text-24 font-bold text-white">{title ?? 'Lorem ipsum'}</span>
              <span className="text-14 font-normal text-white">{`${totalCreated} creations`}</span>
              <span className="text-14 font-normal text-white">{`Vol: ${cost} ORAI`}</span>
            </div>
            <DirectorCardCreatorIcon className="cursor-pointer" />
          </div>
        )}
      </div>
    </Card>
  );
};

export default CardCreator;
