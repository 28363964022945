import { api } from '@/services';
import type { LinkSocialProfile } from '@/types/user';

export interface IGetCollectionWithAddress {
  address: string;
  pageSize: number;
  offset: number;
  search?: string;
  sort?: string;
}
export const getCollectionWithAddress = async ({
  address,
  pageSize,
  offset,
  search = '',
  sort = 'DESC',
}: IGetCollectionWithAddress) => {
  const res = api.get(
    `/collections?size=${pageSize}&offset=${offset}${`&filter=${encodeURIComponent(
      `{"accountAddress":"${address}", "name": "${search}"}`,
    )}&sort=${encodeURIComponent(`{"createdAt":"${sort}"}`)}`}`,
  );
  return res;
};
export const getCollectionTags = async (pageSize: number, offset: number, search?: string) => {
  const res = api.get(
    `/collections/tags/support?size=${pageSize}&offset=${offset}&filter={"tags": ["${search}"]}`,
  );
  return res;
};

export const getCollectionById = async (id: string | number) => {
  return await api.get(`/collections/${id}`);
};

export interface DataAddAssetToCollection {
  nftIds: Array<number>;
}

export const addAssetToCollection = async (
  collectionId: string | number,
  data: DataAddAssetToCollection,
) => {
  return await api.patch(`/collections/${collectionId}/add-nfts`, data);
};

export interface DataCreateCollection {
  name: string;
  description?: string;
  links?: LinkSocialProfile[];
  tags?: [];
}
export const createNewCollection = async (data: DataCreateCollection) => {
  return await api.post('/collections', data);
};

export const deleteCollection = async (collectionId: number) => {
  return await api.delete(`/collections/${collectionId}`);
};

export interface DataUpdateCollection {
  name?: string;
  description?: string;
  links?: LinkSocialProfile[];
  tags?: [
    {
      id: number;
      content: string;
    },
  ];
}
export const updateCollection = async (collectionId: number, data: DataUpdateCollection) => {
  return await api.patch(`/collections/${collectionId}`, data);
};

export const removeAssetFromCollection = async (collectionId: number, assetId: number) => {
  return await api.delete(`collections/${collectionId}/remove-nft/${assetId}`);
};

export const updatePictureCollection = async (collectionId: number, data: FormData) => {
  return await api.patch(`collections/${collectionId}/picture`, data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const updateBannerCollection = async (collectionId: number, data: FormData) => {
  return await api.patch(`collections/${collectionId}/update-banner`, data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const increaseViewCollection = async (body: { collectionId: number }) => {
  const res = await api.post('/view/collection/increase-view', body);
  return res.data;
};

export const getViewCollection = async (body: { collectionId: number }) => {
  const res = await api.post('/view/collection/total-view', body);
  return res.data;
};

export const getCollections = async ({
  sort = '',
  date = '',
  search = '',
  size,
  offset,
}: {
  size: number;
  offset: number;
  sort?: string;
  search?: string;
  date?: string;
}) => {
  return api.get(
    `/collections?size=${size}&offset=${offset}${`&filter=${encodeURIComponent(
      `{"name": "${search}"}`,
    )}&sort=${encodeURIComponent(
      `{${sort ? `"tradingVolume":"${sort}"` : ''}${date ? `"createdAt":"${date}"` : ''}}`,
    )}`}`,
  );
};

export interface ActivityCollectionParams {
  size: number;
  offset: number;
  sortDate?: 'DESC' | 'ASC' | string;
  filterEvent?: string | null;
}

export const getActivityCollectionById = async (
  id: number | string,
  data: ActivityCollectionParams,
) => {
  const { size, offset, sortDate = 'DESC', filterEvent } = data;
  return api.get(
    `/collections/activity/${id}?size=${size}&offset=${offset}&sort=${encodeURIComponent(
      `{"createdAt":"${sortDate}"}`,
    )}&filter=${encodeURIComponent(`{${filterEvent ? `"event":"${filterEvent}"` : ''}}`)}`,
  );
};
