import type { ReactElement } from 'react';
import React from 'react';
import { FileUploader } from 'react-drag-drop-files';

import { UploadFileIcon } from '@/assets';

interface UpLoadFileMultipleProps {
  children?: ReactElement;
  classes?: string;
  handleChange?: (e: any) => void;
  types?: string[];
  title?: string;
  [key: string]: any;
}

const UpLoadFileMultiple: React.FC<UpLoadFileMultipleProps> = ({
  classes = '',
  handleChange,
  children,
  title = 'Drop or upload here (max 5 files)',
  types = ['png', 'jpg', 'jpeg'],
  ...props
}) => {
  return (
    <div>
      <FileUploader
        maxSize={5}
        multiple
        handleChange={handleChange}
        classes={`[&>input]:w-full w-full ${classes}`}
        types={types}
        {...props}
      >
        {children ?? (
          <div className="flex cursor-pointer flex-col items-center justify-center rounded-[16px] border border-dashed border-gray-200 bg-gray-50 stroke-2 py-5">
            <UploadFileIcon className="mb-4" />
            <span className="w-[80%] text-center text-12 font-normal text-gray-500">{title}</span>
          </div>
        )}
      </FileUploader>
    </div>
  );
};

export default UpLoadFileMultiple;
