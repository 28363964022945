import React from 'react';

import { checkActionAigToIcon, checkActionAigToTitle } from '@/utils';

interface ToastEarnProps {
  point: { point: number; action: string };
  type?: string;
  iconClassName?: string;
}

const ToastEarn: React.FC<ToastEarnProps> = ({ point, type = 'AIG', iconClassName = '' }) => {
  return (
    <div className="flex items-center bg-blue-50 px-3">
      {checkActionAigToIcon(point.action, `mr-2 ${iconClassName}`)}
      <div className="flex flex-col">
        <span className="mb-1 text-16 font-semibold">{checkActionAigToTitle(point.action)}</span>
        <div className="flex items-center">
          <span className="text-13 font-normal text-gray-600">You just earned</span>
          <span className="ml-1 text-13 font-semibold text-blue-500">+{point.point} {type}</span>
        </div>
      </div>
    </div>
  );
};

export default ToastEarn;
