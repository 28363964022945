import { api } from '../axios';

export const checkGrant = async () => {
  const res = await api.get(`/providers/me/grant`);
  return res.data;
};

export const requestGrant = async () => {
  return api.post(`/providers/me/grant`);
};
