import type { TooltipProps } from '@nextui-org/react';
import { Button, Tooltip } from '@nextui-org/react';
import React, { useState } from 'react';

interface TooltipCustomProps extends TooltipProps {
  children: React.ReactNode;
  content: any;
}

const TooltipCustom = ({ children, content, ...props }: TooltipCustomProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Tooltip
      {...props}
      content={
        <div onMouseOver={() => setIsOpen(true)} onMouseLeave={() => setIsOpen(false)}>
          {content}
        </div>
      }
      isOpen={isOpen}
    >
      <Button
        className="m-0 h-auto min-h-0 w-auto min-w-0 rounded-none border-none bg-transparent p-0 outline-none"
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
        onPress={() => setIsOpen(!isOpen)}
      >
        {children}
      </Button>
    </Tooltip>
  );
};

export default TooltipCustom;
