import React from 'react';

import { NoDataSvg } from '@/assets';

const NoData: React.FC<{
  className?: string;
  classNameIcon?: string;
}> = ({ className = '', classNameIcon = '' }) => {
  return (
    <div className={`flex h-[500px] items-center justify-center ${className}`}>
      <NoDataSvg className={classNameIcon} />
    </div>
  );
};

export default NoData;
