import cachedLocalStorage from '@/libs/localStorage';

const storageFunction = {
  saveToken: (accessToken: string, refreshToken: string) => {
    cachedLocalStorage.setWithExpiry('token', accessToken, 1000 * 60 * 60 * 8);
    cachedLocalStorage.setWithExpiry('refreshToken', refreshToken, 1000 * 60 * 60 * 24 * 30);
  },

  markDontShowPromoDiscord: () => {
    cachedLocalStorage.setWithExpiry('promo', false);
  },

  markDontShowCountdown: () => {
    cachedLocalStorage.setWithExpiry('countdown', 'disable', 1000 * 60 * 60 * 8);
  },

  saveInfoUser: (info: {
    username: string;
    picture: string;
    publicAddress: string;
    id: number;
    isOwallet: boolean;
    email?: string;
    isPlus?: boolean;
    credit?: number;
    [key: string]: any;
  }) => {
    cachedLocalStorage.setWithExpiry('infoUser', info);
  },

  deleteToken: () => {
    cachedLocalStorage.removeItem('token');
    cachedLocalStorage.removeItem('refreshToken');
  },
  deleteInfoUser: () => {
    cachedLocalStorage.removeItem('infoUser');
  },
};

export default storageFunction;
