import { io } from 'socket.io-client';

import { configs } from '@/configs';

export const SSocket = (
  url = configs.socketUrlMidjourney,
  path = '/provider-midjourney-service/socket.io',
) => {
  return io(url ?? '', {
    autoConnect: false,
    transports: ['websocket'],
    // withCredentials: true,
    rejectUnauthorized: false,
    agent: false,
    // extraHeaders: {
    //   'Access-Control-Allow-Origin': '*',
    // },
    path,
  });
};
