import type { StdFee } from '@cosmjs/stargate';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { configs } from '@/configs';
import { network } from '@/configs/network';
import type { InputExecuteSSO } from '@/libs/ssoWallet';
import { ActionSSO, ssoExecute } from '@/libs/ssoWallet';
import { useSocket } from '@/provider/SocketProvider';
import { useAuth } from '@/provider/UserProvider';
import type { IAssetDetail } from '@/types/asset';
import { isAsset1155 } from '@/utils';
import {
  getMessageExecuteTransferTransaction721WithCw20Token,
  getMessageExecuteTransferTransaction1155WithCw20Token,
} from '@/utils/transactionMessage';
import { handleExecute, handleExecuteGrant } from '@/utils/wasm';

const MESS_FINISH = 'Transfer artwork successfully!';

const useTransferNFT = (
  asset: IAssetDetail,
  publicAddress: string,
  onClose: () => void,
  refresh: () => void,
  addressSubmit: string,
  quantity: number,
) => {
  const { auth } = useAuth();
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState('');
  const [finish, setFinish] = useState('');
  const { socket } = useSocket();

  const { ow721, marketplace, marketplace1155, ow1155 } = window.Wasm.contractAddresses;
  const is1155 = isAsset1155(asset.version);
  const assetId = asset.id;
  const contractAddress = is1155 ? marketplace1155 : marketplace;

  const message = is1155
    ? getMessageExecuteTransferTransaction1155WithCw20Token({
        contractAddr: ow1155 || '',
        amount: quantity.toString(),
        tokenId: assetId.toString(),
        addressRecipient: addressSubmit,
      })
    : getMessageExecuteTransferTransaction721WithCw20Token({
        contractAddr: ow721 || '',
        addressRecipient: addressSubmit,
        tokenID: assetId.toString(),
      });

  const handleExecuteWithOwallet = async ({
    additionalInfor = {},
    grant,
  }: {
    additionalInfor?: object;
    grant?: StdFee;
  }) => {
    setLoading(true);
    if (grant) {
      await handleExecuteGrant({ contract: contractAddress || '', grant, msg: message });
    } else {
      await handleExecute(contractAddress || '', publicAddress, message, additionalInfor);
    }
  };

  const handleExecuteWithSSO = async (fee?: StdFee) => {
    const input: InputExecuteSSO = {
      contractAddress: contractAddress || '',
      chainId: network?.chainId || 'Oraichain',
      params: message,
    };
    if (fee) input.fee = fee;
    setLoading(true);
    await ssoExecute(input, ActionSSO.transferNFT);
  };

  const handleTransferNFT = async () => {
    listenTransferEvent();
    const additionalInfor = { gas: 1000000, fees: 0 };

    try {
      if (auth.isOwallet) {
        await handleExecuteWithOwallet({
          additionalInfor,
        });
      } else {
        await handleExecuteWithSSO();
      }
    } catch (error: any) {
      console.log('error transfering: ', error);
      setLoading(false);
      toast.error(error?.message);
    }
  };
  const handleTransferNFTWithGrant = async () => {
    listenTransferEvent();
    const fee: StdFee = {
      granter: configs.grantAddress,
      amount: [
        {
          amount: '10000',
          denom: 'orai',
        },
      ],
      gas: '2000000',
    };
    try {
      if (auth.isOwallet) {
        await handleExecuteWithOwallet({
          grant: fee,
        });
      } else {
        await handleExecuteWithSSO(fee);
      }
    } catch (error: any) {
      console.log('error transfering: ', error);
      setLoading(false);
      toast.error(error?.message);
    }
  };

  const listenTransferEvent = () => {
    if (!socket) return;
    const event = `transfer-nft-${publicAddress}-${assetId}`;
    socket.on(event, () => {
      socket.removeListener(event);
      setLoading(false);
      refresh();
      setFinish(MESS_FINISH);
      setTimeout(() => {
        onClose();
      }, 100);
    });
  };

  // for sso
  useEffect(() => {
    if (typeof window !== 'undefined' && !auth.isOwallet) {
      window.addEventListener(
        'message',
        (event) => {
          if (event.origin !== configs.ssoUrl) return;
          const data = JSON.parse(event?.data || '');

          if (data.action !== ActionSSO.transferNFT) return;
          if (data.status !== 'success') {
            console.log('err transfer: ', data.response);
            setErr(data.response);
          }
        },
        false,
      );
    }
    return window.removeEventListener('message', () => {});
  }, []);

  useEffect(() => {
    if (err) {
      toast.error(err);
      setLoading(false);
    }
  }, [err]);

  useEffect(() => {
    if (finish) {
      toast.success(finish);
      setLoading(false);
    }
  }, [finish]);

  return { handleTransferNFT, handleTransferNFTWithGrant, loading };
};

export default useTransferNFT;
