import type { JsonObject } from '@cosmjs/cosmwasm-stargate';
import type { Coin } from '@cosmjs/proto-signing';
import type { StdFee } from '@cosmjs/stargate';

import { configs } from '@/configs';
import { network } from '@/configs/network';
import { popupCenter } from '@/utils';

export enum ActionSSO {
  approveContractMarket = 'approve-contract-market',
  sellPrompt = 'sell-prompt',
  stopSellPrompt = 'stop-sell-prompt',
  buyPrompt = 'buy-prompt',
  buyArtwork = 'buy-artwork',
  sellArtwork = 'sell-artwork',
  stopSellArtwork = 'stop-sell-artwork',
  transferNFT = 'transfer-nft',
  mintNFT = 'mint-nft',
  lockToken = 'lock-token',
  claimToken = 'claim-token',
  withdrawToken = 'withdraw-token',
}
export interface ExecuteInstruction {
  contractAddress: string;
  msg: JsonObject;
  funds?: readonly Coin[];
}
export interface InputExecuteSSO {
  chainId: string;
  contractAddress: string;
  params: any;
  fee?: any;
  funds?: readonly Coin[];
}

export const ssoExecute = async (input: InputExecuteSSO, action: ActionSSO) => {
  const message = Buffer.from(JSON.stringify(input)).toString('base64');
  const url = `${configs.ssoUrl}/execute?input=${message}&action=${action}&callbackUrl=${configs.ssoCallback}`;

  popupCenter({
    url,
    title: '',
    w: 380,
    h: 550,
  });
};

export const ssoExecuteMultiple = async (
  input: { fee?: StdFee; data: ExecuteInstruction[] },
  action: ActionSSO,
) => {
  const message = Buffer.from(JSON.stringify(input)).toString('base64');
  const url = `${configs.ssoUrl}/execute-multiple?chainId=${
    network?.chainId || 'Oraichain'
  }&input=${message}&action=${action}&callbackUrl=${configs.ssoCallback}`;

  popupCenter({
    url,
    title: '',
    w: 380,
    h: 550,
  });
};
