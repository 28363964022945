import type { Coin, StdFee } from '@cosmjs/stargate';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { match } from 'ts-pattern';

import { configs } from '@/configs';
import { network } from '@/configs/network';
import useBalance from '@/hooks/useBalance';
import useGrant from '@/hooks/useGrant';
import { TokenDenom } from '@/hooks/useTokenPrices';
import type { InputExecuteSSO } from '@/libs/ssoWallet';
import { ActionSSO, ssoExecute } from '@/libs/ssoWallet';
import { useSocket } from '@/provider/SocketProvider';
import { useAuth } from '@/provider/UserProvider';
import type { IAssetDetail, OfferAsk } from '@/types/asset';
import { isAsset1155 } from '@/utils';
import { handleExecute, handleExecuteGrant } from '@/utils/wasm';

import { getInputBuyingExecute } from './helpers';

const MESS_FINISH = 'Artwork purchased successfully!';
const GRANT_FEE: StdFee = {
  granter: configs.grantAddress,
  amount: [
    {
      amount: '10000',
      denom: 'orai',
    },
  ],
  gas: '2000000',
};

const useCheckout = ({
  price,
  callback,
  asset,
  quantity,
  dataAsk,
}: {
  price: string;
  callback: () => void;
  asset: IAssetDetail;
  quantity?: string;
  dataAsk?: OfferAsk;
}) => {
  const denom = (dataAsk ? dataAsk.denom : asset.offer?.denom || TokenDenom.USDT) as TokenDenom;
  const { orai, airi, usd, cupi } = useBalance([denom]);
  const { auth } = useAuth();
  const { socket } = useSocket();
  const [err, setErr] = useState('');
  const [finish, setFinish] = useState('');
  const [isRecivedFromSSO, setIsRecivedFromSSO] = useState(false);
  const [loading, setLoading] = useState(false);

  const { onStart: onStartPurchaseWithCheckGrant } = useGrant(
    () => handlePurchase(false),
    () => handlePurchase(true),
    () => handlePurchase(true),
  );

  const balance = match(denom)
    .with(TokenDenom.ORAI, () => orai)
    .with(TokenDenom.AIRI, () => airi)
    .with(TokenDenom.CUPI, () => cupi)
    .otherwise(() => usd);
  const isEnough = parseFloat(price) <= balance;

  const handleExecuteWithOwallet = async ({
    contract,
    sender,
    message,
    additionalInfor,
    isGrant = false,
  }: {
    contract: string;
    sender: string;
    message: string;
    additionalInfor: any;
    isGrant?: boolean;
  }) => {
    setLoading(true);
    const purchaseResult = isGrant
      ? await handleExecuteGrant({
          contract,
          grant: GRANT_FEE,
          msg: message,
        })
      : await handleExecute(contract, sender, message, additionalInfor);

    if (purchaseResult.tx_response.txhash) {
      listenBuyingEvent();
    }
  };

  const handleExecuteWithSSO = async ({
    contract,
    message,
    fee,
    funds,
  }: {
    contract: string;
    message: string;
    fee: StdFee | number | 'auto';
    funds?: readonly Coin[];
  }) => {
    setLoading(true);
    const input: InputExecuteSSO = {
      contractAddress: contract,
      chainId: network?.chainId || 'Oraichain',
      params: message,
      fee,
      funds,
    };
    await ssoExecute(input, ActionSSO.buyArtwork);
  };

  const onPurchase = async () => {
    setLoading(true);
    onStartPurchaseWithCheckGrant();
  };

  const handlePurchase = async (isGrant: boolean) => {
    try {
      if (!asset) return;
      const offering = asset.offer;
      if (!offering) return;

      const sender = auth.user.publicAddress;
      const { marketplace1155 } = window.Wasm.contractAddresses;
      const marketplace721 =
        offering?.version === 2 ? configs.marketPlace721Contract : configs.marketPlaceContract;

      const { offeringId } = dataAsk || offering;
      const marketplace = isAsset1155(asset.version) ? marketplace1155 || '' : marketplace721 || '';
      const { contract, message, additionalInfor } = getInputBuyingExecute({
        offeringId,
        denom,
        marketplace,
        price,
        quantity,
        is1155: isAsset1155(asset.version),
      });

      if (auth.isOwallet) {
        handleExecuteWithOwallet({
          contract: contract || '',
          message,
          sender,
          additionalInfor,
          isGrant,
        });
      } else {
        const { type, funds } = additionalInfor;
        const sentFunds =
          type === 'native' && funds ? [{ denom: 'orai', amount: funds }] : undefined;
        handleExecuteWithSSO({
          contract: contract || '',
          message,
          fee: isGrant ? GRANT_FEE : 'auto',
          funds: sentFunds as Coin[],
        });
      }
    } catch (error: any) {
      setLoading(false);
      console.log('err checkout: ', error);
      if (error === 'popup-closed') {
        toast.error(
          'The signing transaction popup might be closed or your browser has blocked the popup! Please try again or check your browser setting',
        );
        return;
      }
      toast.error(error?.message);
    }
  };

  const listenBuyingEvent = () => {
    if (!socket) return;
    const event = `buy-${auth.user.publicAddress}-${asset.id}`;
    socket.on(event, () => {
      socket.removeListener(event);
      setLoading(false);
      callback();
      setFinish(MESS_FINISH);
    });
  };

  // for sso
  useEffect(() => {
    if (typeof window !== 'undefined' && !auth.isOwallet) {
      window.addEventListener(
        'message',
        (event) => {
          if (event.origin !== configs.ssoUrl) return;
          const data = JSON.parse(event?.data || '');
          if (data.action !== ActionSSO.buyArtwork) return;
          if (data.status === 'success') {
            setIsRecivedFromSSO(true);
          } else {
            setLoading(false);
            setErr(data.response);
          }
        },
        false,
      );
    }
    return window.removeEventListener('message', () => {});
  }, []);

  // for sso
  useEffect(() => {
    if (isRecivedFromSSO) listenBuyingEvent();
  }, [isRecivedFromSSO]);

  useEffect(() => {
    if (err) {
      toast.error(err);
    }
  }, [err]);

  useEffect(() => {
    if (finish) {
      toast.success(finish);
    }
  }, [finish]);

  return { isEnough, onPurchase, loading, balance };
};

export default useCheckout;
