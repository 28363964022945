import { configs } from '@/configs';
import {
  getMessageExecuteBuyingTransaction721WithCw20Token,
  getMessageExecuteBuyingTransaction721WithNativeToken,
  getMessageExecuteBuyingTransaction1155WithCw20Token,
  getMessageExecuteBuyingTransaction1155WithNativeToken,
} from '@/utils/transactionMessage';

export const getInputBuyingExecute = ({
  denom,
  offeringId,
  price,
  marketplace,
  quantity = '0',
  is1155 = false,
}: {
  denom: string;
  offeringId: number;
  marketplace: string;
  price: string;
  quantity?: string;
  is1155?: boolean;
}) => {
  const FEES = 0;
  const GAS = 4000000;

  let contract;
  let message;
  let additionalInfor;

  switch (denom) {
    case 'AIRI':
      contract = configs.airiTokenContract;
      message = is1155
        ? getMessageExecuteBuyingTransaction1155WithCw20Token({
            offeringId,
            price,
            marketplace,
            quantity,
          })
        : getMessageExecuteBuyingTransaction721WithCw20Token(offeringId, price, marketplace);
      additionalInfor = { gas: GAS, type: 'cw20', fees: FEES };
      break;

    case 'CUPI':
      contract = configs.cupiTokenContract;
      message = getMessageExecuteBuyingTransaction1155WithCw20Token({
        offeringId,
        price,
        marketplace,
        quantity,
      });
      additionalInfor = { gas: GAS, type: 'cw20', fees: FEES };
      break;

    case 'ORAI':
      contract = marketplace;
      message = is1155
        ? getMessageExecuteBuyingTransaction1155WithNativeToken(offeringId, quantity)
        : getMessageExecuteBuyingTransaction721WithNativeToken(offeringId);
      additionalInfor = { gas: GAS, funds: price, fees: FEES, type: 'native' };
      break;

    default:
      contract = configs.usdtTokenContract;
      message = is1155
        ? getMessageExecuteBuyingTransaction1155WithCw20Token({
            offeringId,
            price,
            marketplace,
            quantity,
          })
        : getMessageExecuteBuyingTransaction721WithCw20Token(offeringId, price, marketplace);
      additionalInfor = { gas: GAS, type: 'cw20', fees: FEES };
      break;
  }

  return { contract, message, additionalInfor };
};
