import type { LinkProps } from 'next/link';
import Link from 'next/link';
import type { ReactNode } from 'react';
import React from 'react';

interface MyLinkProps {
  children: ReactNode;
  className?: string;
  target?: string;
}

const MyLink: React.FC<LinkProps & MyLinkProps> = ({ children, className = '', ...props }) => {
  return (
    <Link target="_blank" className={className} {...props}>
      {children}
    </Link>
  );
};

export default MyLink;
