import type { ReactElement } from 'react';
import React from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { match } from 'ts-pattern';

import { CancelFileIcon, UploadFileIcon } from '@/assets';
import { ButtonIcon, Images } from '@/components';
import { checkSizeBeforeUpload } from '@/utils';

interface UpLoadFileModelProps {
  handleClearFile?: () => void;
  handleChange?: (e: any) => void;
  classes?: string;
  types?: string[];
  file: any;
  setFile: React.Dispatch<React.SetStateAction<File | undefined>>;
  children?: ReactElement;
  required?: boolean;
  title?: string;
  desc?: string;
  [key: string]: any;
}

const UpLoadFileModel: React.FC<UpLoadFileModelProps> = ({
  classes = '',
  handleClearFile,
  handleChange,
  types = ['png', 'jpg', 'jpeg'],
  file,
  setFile,
  children,
  required = true,
  title = 'Drop your image file here, or browse',
  desc = 'Support: PNG, JPG, JPEG. Max size: 5MB',
  ...props
}) => {
  return match(Boolean(file) || typeof file === 'string')
    .with(true, () => {
      const url = typeof file === 'string' ? file : URL.createObjectURL(file);

      return children ? (
        <div className="h-full w-full">
          {children}
          <div className="mt-3 flex w-full items-center justify-between">
            <span className="line-clamp-2 w-[90%] text-14 font-normal text-gray-600">
              {file.name}
            </span>
            <ButtonIcon
              onClick={() => {
                setFile(undefined);
                if (handleClearFile) {
                  handleClearFile();
                }
              }}
            >
              <CancelFileIcon className="cursor-pointer" />
            </ButtonIcon>
          </div>
        </div>
      ) : (
        <div>
          <div className="flex h-[200px] items-center justify-center rounded-[16px] bg-gray-50">
            {types[0] === 'mp4' ? (
              <video className="h-full" controls src={url} />
            ) : (
              <Images className="object-contain" src={url} alt={url} />
            )}
          </div>
          <div className="mt-3 flex items-center">
            <span className="line-clamp-2 w-[90%] text-14 font-normal text-gray-600">
              {file.name}
            </span>
            <ButtonIcon
              onClick={() => {
                setFile(undefined);
                if (handleClearFile) {
                  handleClearFile();
                }
              }}
            >
              <CancelFileIcon className="cursor-pointer" />
            </ButtonIcon>
          </div>
        </div>
      );
    })
    .with(false, () => (
      <FileUploader
        required={required}
        classes={`[&>input]:w-full w-full ${classes}`}
        handleChange={(e: any) => {
          if (checkSizeBeforeUpload(e, 5)) return;
          if (handleChange) {
            handleChange(e);
          }
        }}
        types={types}
        {...props}
      >
        {children ?? (
          <div className="flex cursor-pointer flex-col items-center justify-center rounded-[16px] border border-dashed border-gray-200 bg-gray-50 stroke-2 py-5">
            <UploadFileIcon className="mb-4" />
            <span className="text-14 font-semibold">{title}</span>
            <span className="text-center text-13 font-normal text-gray-400">{desc}</span>
          </div>
        )}
      </FileUploader>
    ))
    .run();
};

export default UpLoadFileModel;
