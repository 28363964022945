import { initializeApp } from 'firebase/app';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import { toast } from 'react-toastify';

export const withTryCatch = async (callbackTry: Function, callbackFinally?: Function) => {
  try {
    const response = await callbackTry();
    return response;
  } catch (error: any) {
    return toast.error(error.message);
  } finally {
    if (callbackFinally) {
      callbackFinally();
    }
  }
};

export const withServerTryCatch = async (callbackTry: Function, dataDefault: any) => {
  try {
    const response = await callbackTry();
    return response;
  } catch {
    return dataDefault;
  }
};

const firebaseConfig = {
  apiKey: 'AIzaSyBIoGgT6pDiy_MyLNnI8ZUibocRqOGpys4',
  authDomain: 'oraichain-mainnet.firebaseapp.com',
  projectId: 'oraichain-mainnet',
  storageBucket: 'oraichain-mainnet.appspot.com',
  messagingSenderId: '2014872230',
  appId: '1:2014872230:web:4fe9c195d1f5307a1b6a67',
  measurementId: 'G-7NXVGJ8B83',
};

const app = initializeApp(firebaseConfig);

const storage = getStorage(app);

export const uploadFileToFirebase = (file: File, onChange: any) => {
  // let returnResult;
  if (file) {
    const folderPath = process.env.REACT_APP_FIREBASE_VIDEO_PATH || 'airight/testnet/videos';
    const storageRef = ref(storage, `${folderPath}${file.name}_${new Date()}`); //
    //

    const metadata = {
      contentType: file.type,
    };

    const uploadTask = uploadBytesResumable(storageRef, file, metadata);

    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        onChange(Math.floor(progress));

        switch (snapshot.state) {
          case 'paused':
            break;
          case 'running':
            break;
          default:
            break;
        }
      },
      (error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case 'storage/unauthorized':
            // User doesn't have permission to access the object
            break;
          case 'storage/canceled':
            // User canceled the upload
            break;

          // ...

          case 'storage/unknown':
            // Unknown error occurred, inspect error.serverResponse
            break;
          default:
            break;
        }
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          onChange({
            url: downloadURL,
            mimeType: uploadTask.snapshot.metadata.contentType,
            hash: uploadTask.snapshot.metadata.md5Hash,
            size: uploadTask.snapshot.metadata.size,
            type: uploadTask.snapshot.metadata.contentType,
          });
        });
      },
    );
  }
};

export const transferApiMetaInfo = (obj: {
  [key: number | string]: { inputs: { [key: string]: any }; class_type: string; _widgets: any[] };
}): {
  [key: string]: any;
}[] => {
  const result: {
    [key: string]: any;
  }[] = [];
  for (const key in obj) {
    const value = obj[key];
    for (const field in value?.inputs) {
      if (typeof value?.inputs[field] === 'number' || typeof value?.inputs[field] === 'string')
        result.push({
          nameField: `[${key}] ${value?.class_type} - ${field}`,
          value: value?.inputs[field],
          nodeName: value?.class_type,
          nodeId: Number(key),
          fieldValue: value?.inputs[field].toString(),
          fieldName: field,
          fieldDisplayName: field,
          _widgets: value?._widgets.find((item) => item.name === field),
        });
    }
  }
  return result;
};

export const transferUserPlan = (userPlan: any) => {
  if (
    userPlan?.subscription?.plan === 'unlimitedPro' ||
    userPlan?.subscription?.plan === 'unlimitedProMax'
  )
    return 3; // plus
  if (userPlan?.subscription?.plan === 'starter') return 2; // start
  if (!userPlan?.subscription) return 1; // free
  return 1; // free
};
