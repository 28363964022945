import { useDisclosure } from '@nextui-org/react';
import { useEffect, useState } from 'react';

import { useAuth } from '@/provider/UserProvider';
import { isApproved } from '@/services/account';

const useMarketApprove = (isClick?: boolean) => {
  const { onClose, isOpen, onOpen, onOpenChange } = useDisclosure();
  const { auth } = useAuth();
  const address = auth?.user?.publicAddress;
  const [loading, setLoading] = useState(false);
  const [hasApproved, setHasApproved] = useState(false);

  // check approval
  const isApprovedFunc = async () => {
    setLoading(true);
    const hasApproved = await isApproved(address, 'all');
    if (!hasApproved) {
      onOpen();
    }
    setLoading(false);
  };

  const handleCheckApprove = async () => {
    setLoading(true);
    const hasApprove = await isApproved(address, 'all');
    setLoading(false);
    setHasApproved(hasApprove);
  };

  useEffect(() => {
    const isLogined = address && address !== '0x';
    if (isLogined && !isClick) {
      isApprovedFunc();
    }
    if (isLogined && isClick) {
      handleCheckApprove();
    }
  }, [address]);

  return {
    onClose,
    onOpen,
    isOpen,
    onOpenChange,
    loading,
    handleCheckApprove,
    hasApproved,
    setHasApproved,
  };
};

export default useMarketApprove;
