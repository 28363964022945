interface CurrencyFormatterOptions {
  significantDigits?: number;
  thousandsSeparator?: string;
  decimalSeparator?: string;
  divisor?: number;
}

export const defaultOptions = {
  significantDigits: 6,
  thousandsSeparator: ',',
  decimalSeparator: '.',
  divisor: 1000000,
};

export const currencyFormatter = (valueRaw: number, options?: CurrencyFormatterOptions) => {
  const formatOptions = { ...defaultOptions, ...options };

  let value = (valueRaw || 0.0) / formatOptions.divisor;
  if (typeof value !== 'number' || !value) value = 0.0;
  const valueString = value.toString();

  const [currency, decimal] = valueString.split('.');

  let postFix = '';

  if (decimal) {
    postFix = `${formatOptions.decimalSeparator}${decimal
      .toString()
      .slice(0, formatOptions.significantDigits)}`;
  }

  return `${currency?.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    formatOptions.thousandsSeparator,
  )}${postFix}`;
};
