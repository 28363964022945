import { Button, Popover, PopoverContent, PopoverTrigger } from '@nextui-org/react';
import React from 'react';
import { FacebookShareButton, TwitterShareButton } from 'react-share';

import { CopyLinkIcon, ShareBuyPromptIcon, ShareFacebookIcon, ShareTwitterIcon } from '@/assets';
import { copyClipboard, getURL } from '@/utils';

interface ShareButtonProps {
  pathname: string;
  titleShare: string;
}

const ShareButton: React.FC<ShareButtonProps> = ({ pathname, titleShare }) => {
  return (
    <Popover placement="left-start">
      <PopoverTrigger>
        <Button className="mb-3 h-[46px] w-[46px] min-w-0 rounded-[100px] bg-gray-50 p-0">
          <ShareBuyPromptIcon />
        </Button>
      </PopoverTrigger>

      <PopoverContent className="p-4">
        <div className="flex flex-col">
          <p
            onClick={(e) => copyClipboard(e, getURL(pathname))}
            className="m-0 mb-1 flex cursor-pointer items-center rounded-lg p-2 text-14 font-normal duration-300 hover:bg-gray-100"
          >
            <CopyLinkIcon className="mr-[10px]" /> Copy link
          </p>

          <TwitterShareButton
            hashtags={['aiRight', 'aiRightart', 'AIartwork']}
            url={`${getURL(pathname)} \n\n `}
            title={titleShare}
          >
            <p className="m-0 mb-1 flex cursor-pointer items-center rounded-lg p-2 text-14 font-normal duration-300 hover:bg-gray-100">
              <ShareTwitterIcon className="mr-[10px]" /> Share to X
            </p>
          </TwitterShareButton>

          <FacebookShareButton
            url={`${getURL(pathname)} \n\n `}
            hashtag="#aiRight #GenerativeAI"
            title={titleShare}
          >
            <p className="m-0 mb-1 flex cursor-pointer items-center rounded-lg p-2 text-14 font-normal duration-300 hover:bg-gray-100">
              <ShareFacebookIcon className="mr-[10px]" /> Share to Facebook
            </p>
          </FacebookShareButton>
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default ShareButton;
