import { useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { useRouter } from '@/provider/RouterProvider';
import {
  createNewCollection,
  getCollectionTags,
  updateBannerCollection,
  updatePictureCollection,
} from '@/services/collection';
import { ProfileSocial } from '@/types';
import type { LinkSocialProfile } from '@/types/user';

const useCreateCollection = () => {
  const [loading, setLoading] = useState(false);
  const router = useRouter();

  const [previewThumbnail, setPreviewThumbnail] = useState('');
  const [previewCover, setPreviewCover] = useState('');
  const thumbnailRef = useRef<File>();
  const coverRef = useRef<File>();

  const getTagList = async (search: string) => {
    const res = await getCollectionTags(50, 0, search);
    return res.data;
  };

  const handleChangeFile = (file: File, type: 'thumbnail' | 'cover') => {
    if (file.size / 1024 / 1024 > 20) {
      toast.warning('Maximum size is 20MB');
      return;
    }
    const url = URL.createObjectURL(file);
    if (type === 'thumbnail') {
      thumbnailRef.current = file;
      setPreviewThumbnail(url);
    } else {
      coverRef.current = file;
      setPreviewCover(url);
    }
  };
  const handleRemoveFile = (type: 'thumbnail' | 'cover') => {
    if (type === 'thumbnail') {
      thumbnailRef.current = undefined;
      setPreviewThumbnail('');
    } else {
      coverRef.current = undefined;
      setPreviewCover('');
    }
  };
  const handleCreateCollection = async (
    name: string,
    links: LinkSocialProfile[],
    description?: string,
    tags?: [],
    noNavigate?: boolean,
  ) => {
    try {
      setLoading(true);

      const res = await createNewCollection({ name, description, links, tags });
      const collectionId = res.data?.id;
      if (!collectionId) {
        toast.error('Error, please to try!');
        return;
      }
      if (collectionId && thumbnailRef.current) {
        const formData = new FormData();
        formData.append('picture', thumbnailRef.current, 'thumbnail');
        const resUpdate = await updatePictureCollection(collectionId, formData);
        if (!resUpdate.data?.id) {
          toast.error('Error, please to try!');
          return;
        }
      }
      if (collectionId && coverRef.current) {
        const formData = new FormData();
        formData.append('picture', coverRef.current, 'banner');
        const resUpdate = await updateBannerCollection(collectionId, formData);
        if (!resUpdate.data?.id) {
          toast.error('Error, please to try!');
          return;
        }
      }
      setLoading(false);
      toast.success('Create collection successfully!');
      if (!noNavigate) return router.push(`/collection/${collectionId}`);
      return collectionId;
    } catch (error: any) {
      setLoading(false);
      toast.error(error?.message);
    }
  };

  const getLinks = (data: any): LinkSocialProfile[] => {
    const links = [
      {
        type: ProfileSocial.facebook,
        url: data[ProfileSocial.facebook] || '',
      },
      {
        type: ProfileSocial.twitter,
        url: data[ProfileSocial.twitter] || '',
      },
      {
        type: ProfileSocial.telegram,
        url: data[ProfileSocial.telegram] || '',
      },
      {
        type: ProfileSocial.instagram,
        url: data[ProfileSocial.instagram] || '',
      },
      {
        type: ProfileSocial.discord,
        url: data[ProfileSocial.discord] || '',
      },
    ];

    return links.filter((link) => link?.url !== '');
  };

  return {
    loading,
    handleCreateCollection,
    handleChangeFile,
    handleRemoveFile,
    previewThumbnail,
    previewCover,
    getLinks,
    getTagList,
  };
};

export default useCreateCollection;
