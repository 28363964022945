'use client';

import { Accordion, AccordionItem } from '@nextui-org/react';
import type { ReactNode } from 'react';
import React, { useState } from 'react';

import { DownIndicatorAccordionIcon, UpIndicatorAccordionIcon } from '@/components/assets';

interface AccordionBaseSingleProps {
  title: ReactNode;
  action?: boolean;
  className?: string;
  children?: ReactNode;
  openDefault?: boolean;
  checked?: boolean;
  isNewHeight?: boolean;
}

const AccordionBaseSingle: React.FC<AccordionBaseSingleProps> = ({
  title,
  children,
  action,
  className = '',
  openDefault = false,
  checked,
  isNewHeight,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const key = '1';
  return (
    <Accordion
      defaultExpandedKeys={openDefault ? key : '0'}
      motionProps={{
        variants: {
          enter: {
            y: 0,
            opacity: 1,
            height: 'auto',
            transition: {
              height: {
                type: 'spring',
                stiffness: 500,
                damping: 30,
                duration: 1,
              },
              opacity: {
                easings: 'ease',
                duration: 1,
              },
            },
          },
          exit: {
            y: -10,
            opacity: 0,
            height: 0,
            transition: {
              height: {
                easings: 'ease',
                duration: 0.25,
              },
              opacity: {
                easings: 'ease',
                duration: 0.3,
              },
            },
          },
        },
      }}
      selectionMode="single"
      itemClasses={{
        base: `${
          action
            ? isOpen
              ? 'bg-orange-50 rounded-[16px] p-0 mx-[-8px] border border-orange-500 mb-3'
              : 'bg-gray-50 rounded-[16px] p-0 mb-3 mx-[-8px]'
            : checked
            ? 'bg-orange-50 rounded-[16px] p-0 mx-[-8px] border border-orange-500 mb-3'
            : `bg-gray-50 rounded-[16px] p-0 mb-3 mx-[-8px] ${className}`
        }`,
      }}
    >
      <AccordionItem
        textValue=" "
        key={key}
        title={title}
        className=""
        motionProps={{}}
        indicator={({ isOpen }) => {
          setIsOpen(isOpen ?? false);
          return isOpen ? (
            <UpIndicatorAccordionIcon className="rotate-90" />
          ) : (
            <DownIndicatorAccordionIcon />
          );
        }}
        classNames={{
          content: [`p-0 !overflow-y-auto ${isNewHeight && 'min-h-[320px]'}`],
          trigger: ['!outline-white'],
        }}
      >
        {children}
      </AccordionItem>
    </Accordion>
  );
};

export default AccordionBaseSingle;
