import { api } from '@/services';

export const getMarketTopModels = async (
  size: number,
  offset: number,
  sort?: object,
  filter?: object,
) => {
  const res = await api.get('/marketplaces/top-models', {
    params: { size, offset, sort, filter },
  });
  return res;
};

export interface IGetListMarketplace {
  size: number;
  offset: number;
  sort?: string;
  filter?: string;
}

export const getListMarketplace = async (args: IGetListMarketplace) => {
  const { size, offset, sort, filter } = args;
  return await api.get('/marketplaces/nft-market', { params: { size, offset, sort, filter } });
};

export const marketplaceSearch = async (text: string) => {
  const res = await api.get(`marketplaces/search?text=${text}`);
  return res.data;
};

export const getTopCreator = async (params: { size: number; offset: number }) => {
  return api.get('/trending/top-creator', { params });
};

export const getAssetTopView = async (params: { size: number; offset: number }) => {
  return api.get('/trending/trending-nft-sell-top-view', { params });
};

export const getAssetTopWithType = async (params: { limit: number; type: string }) => {
  const inputParams = `filter={"tag":"${params.type}"}&limit=${params.limit}`;
  return api.get(`/trending/trending-nft-sell-f?${inputParams}`);
};
