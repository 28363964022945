import type { SelectOptionItem } from '@/components/select/SelectMultipleWithSearch';
import { getTagsWithSearch } from '@/services/tags';
import type { ITag } from '@/types/asset';

const useLoadTag = () => {
  const loadTagOptions = async (search: string) => {
    try {
      const res: { data: ITag[] } = await getTagsWithSearch(search);
      const tags = mapOptionTags(res.data || []);
      return tags;
    } catch (err) {
      return [];
    }
  };

  const mapOptionTags = (tags: ITag[]): SelectOptionItem[] => {
    return tags.map((tag) => ({ value: tag.id, label: tag.content }));
  };

  return { loadTagOptions, mapOptionTags };
};

export default useLoadTag;
