import { api } from '@/services';

type CustomMidjourney = {
  assetId: number;
  flags: number;
  custom: 'V1' | 'V2' | 'V3' | 'V4' | 'U1' | 'U2' | 'U3' | 'U4';
};

export const getImageMidjourney = async (id: number) => {
  const res = await api.get(`/processing-assets/midjourney/details/${id}`);
  return res;
};

export const postImageMidjourney = async (assetId: number) => {
  const res = await api.post(`/processing-assets/midjourney/update-image/${assetId}`);
  return res;
};

export const updateEmailForEvent = async ({
  assetId,
  email,
}: {
  assetId: number;
  email: string;
}) => {
  const res = await api.post(`/processing-assets/update-email/${assetId}`, {
    email,
  });
  return res;
};

export const createImageMidjourney = async ({
  prompt,
  modelId,
  campaignId,
}: {
  prompt: string;
  modelId: number;
  campaignId?: number;
}) => {
  const res = await api.post('/processing-assets/midjourney', {
    prompt,
    modelId,
    ...(campaignId && { campaignId }),
  });
  return res;
};

export const variationImageMidjourney = async (arg: CustomMidjourney) => {
  const res = api.post('/processing-assets/midjourney/variation', arg);
  return res;
};

export const upscaleImageMidjourney = async (arg: CustomMidjourney) => {
  const res = api.post('/processing-assets/midjourney/upscale', arg);
  return res;
};

export const zoomOut15ImageMidjourney = async (assetId: number) => {
  const res = api.post('/processing-assets/midjourney/zoomOut1.5x', { assetId });
  return res;
};

export const zoomOut20ImageMidjourney = async (assetId: number) => {
  const res = api.post('/processing-assets/midjourney/zoomOut2x', { assetId });
  return res;
};

export const upscale4xImageMidjourney = async (assetId: number) => {
  const res = api.post('/processing-assets/midjourney/upscale4x', { assetId });
  return res;
};

export const upscale2xImageMidjourney = async (assetId: number) => {
  const res = api.post('/processing-assets/midjourney/upscale2x', { assetId });
  return res;
};

export const varyStrongImageMidjourney = async (assetId: number) => {
  const res = api.post('/processing-assets/midjourney/vary-strong', { assetId });
  return res;
};

export const blendImageMidjourneyApi = async (formData: FormData) => {
  const res = await api.post(`/processing-assets/midjourney/blend`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return res;
};
