/* eslint-disable no-plusplus */
/* eslint-disable no-multi-assign */
/* eslint-disable prefer-destructuring */
import { toast } from 'react-toastify';
import { match } from 'ts-pattern';

import {
  CreateArtworkIcon,
  EarnAigProfileIcon,
  IconCreditFlashOrange,
  LikeArtworkIcon,
  MintArtworkIcon,
  ReferAccountIcon,
  SocialDiscord,
  SocialFb,
  SocialGlobal,
  SocialGmail,
  SocialInstagram,
  SocialLinkedin,
  SocialMedium,
  SocialTelegram,
  SocialTwitter,
} from '@/assets';
import { configs } from '@/configs';
import { bannedWords } from '@/configs/banedWork';
import { paramExpectMidjourney } from '@/configs/paramsMidjourney';
import type { MsgErrorsValidation } from '@/configs/validate';
import { msgErrorsValidation, ValidationKey } from '@/configs/validate';
import type { TokenPrices } from '@/hooks/useTokenPrices';
import { TokenDenom } from '@/hooks/useTokenPrices';
import { ArtworkDonateType, ProfileSocial } from '@/types';
import type { IAssetDetail } from '@/types/asset';
import type { LinkSocialProfile } from '@/types/user';
import { SIDEBAR_MENU_LINK } from '@/layouts/sidebar';
import cachedLocalStorage from '@/libs/localStorage';

export * from './datePicker';
export * from './socket';
export * from './storage';
export * from './useQuery';

export const ROYALTY_PERCENT_DIGITS = 10000000;

export const getNumberOfPage = (total: number, pageSize: number) => {
  return total % pageSize !== 0 ? Math.floor(total / pageSize) + 1 : total / pageSize;
};

export const centerTextEllipsis = (text: string, size?: number, key?: string) => {
  return `${text?.slice(0, size || 5)}${key || '...'}${text?.slice(-(size || 5))}`;
};

export const getWalletBalance = async (address: string) => {
  return await window.Wasm.get(
    `/cosmos/bank/v1beta1/balances/${address}/${configs.balanceApiSuffix}`,
  );
};

export const getWalletAIRIBalance = async (address: string) => {
  const query = { balance: { address } };
  return await window.Wasm.query(configs.airiTokenContract, JSON.stringify(query));
};

export const getWalletUsdBalance = async (address: string) => {
  const query = { balance: { address } };
  return await window.Wasm.query(configs.usdtTokenContract, JSON.stringify(query));
};

export const getWalletCupiBalance = async (address: string) => {
  const query = { balance: { address } };
  return await window.Wasm.query(configs.cupiTokenContract || '', JSON.stringify(query));
};

const randseed = new Array(4);

function seedrand(seed: any) {
  randseed.fill(0);

  for (let i = 0; i < seed.length; i++) {
    randseed[i % 4] = (randseed[i % 4] << 5) - randseed[i % 4] + seed.charCodeAt(i);
  }
}

function rand() {
  // based on Java's String.hashCode(), expanded to 4 32bit values
  const t = randseed[0] ^ (randseed[0] << 11);

  randseed[0] = randseed[1];
  randseed[1] = randseed[2];
  randseed[2] = randseed[3];
  randseed[3] = randseed[3] ^ (randseed[3] >> 19) ^ t ^ (t >> 8);

  return (randseed[3] >>> 0) / ((1 << 31) >>> 0);
}

function createColor() {
  // saturation is the whole color spectrum
  const h = Math.floor(rand() * 360);
  // saturation goes from 40 to 100, it avoids greyish colors
  const s = `${rand() * 60 + 40}%`;
  // lightness can be anything from 0 to 100, but probabilities are a bell curve around 50%
  const l = `${(rand() + rand() + rand() + rand()) * 25}%`;

  return `hsl(${h},${s},${l})`;
}

function createImageData(size: number) {
  const width = size; // Only support square icons for now
  const height = size;

  const dataWidth = Math.ceil(width / 2);
  const mirrorWidth = width - dataWidth;

  const data = [];
  for (let y = 0; y < height; y++) {
    let row = [];
    for (let x = 0; x < dataWidth; x++) {
      // this makes foreground and background color to have a 43% (1/2.3) probability
      // spot color has 13% chance
      row[x] = Math.floor(rand() * 2.3);
    }
    const r = row.slice(0, mirrorWidth);
    r.reverse();
    row = row.concat(r);

    for (let i = 0; i < row.length; i++) {
      data.push(row[i]);
    }
  }

  return data;
}

function buildOpts(opts: any) {
  const newOpts: any = {};

  newOpts.seed = opts.seed || Math.floor(Math.random() * 10 ** 16).toString(16);

  seedrand(newOpts.seed);

  newOpts.size = opts.size || 8;
  newOpts.scale = opts.scale || 4;
  newOpts.color = opts.color || createColor();
  newOpts.bgcolor = opts.bgcolor || createColor();
  newOpts.spotcolor = opts.spotcolor || createColor();

  return newOpts;
}

export function renderIcon(opts: any, canvas: any) {
  opts = buildOpts(opts || {});
  const imageData = createImageData(opts.size);
  const width = Math.sqrt(imageData.length);

  canvas.width = canvas.height = opts.size * opts.scale;

  const cc = canvas.getContext('2d');
  cc.fillStyle = opts.bgcolor;
  cc.fillRect(0, 0, canvas.width, canvas.height);
  cc.fillStyle = opts.color;

  for (let i = 0; i < imageData.length; i++) {
    // if data is 0, leave the background
    if (imageData[i]) {
      const row = Math.floor(i / width);
      const col = i % width;

      // if data is 2, choose spot color, if 1 choose foreground
      cc.fillStyle = imageData[i] === 1 ? opts.color : opts.spotcolor;

      cc.fillRect(col * opts.scale, row * opts.scale, opts.scale, opts.scale);
    }
  }

  return canvas;
}

export function createIcon(opts: any) {
  const canvas = document.createElement('canvas');

  renderIcon(opts, canvas);

  return canvas;
}

export const getIconSocial = (type: ProfileSocial) => {
  switch (type) {
    case ProfileSocial.twitter:
      return <SocialTwitter />;
    case ProfileSocial.web:
      return <SocialGlobal />;
    case ProfileSocial.telegram:
      return <SocialTelegram />;
    case ProfileSocial.facebook:
      return <SocialFb />;
    case ProfileSocial.linkedin:
      return <SocialLinkedin />;
    case ProfileSocial.medium:
      return <SocialMedium />;
    case ProfileSocial.gmail:
      return <SocialGmail />;
    case ProfileSocial.instagram:
      return <SocialInstagram />;
    case ProfileSocial.discord:
      return <SocialDiscord />;
    default:
      return <SocialTwitter />;
  }
};

export const getUrlSocial = (type: ProfileSocial, links: LinkSocialProfile[]) => {
  return links?.find((link) => link.type === type)?.url || '';
};

export const makeid = (length: number) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const copyClipboard = (event: any, text: string) => {
  event.stopPropagation();
  navigator.clipboard.writeText(text);
  toast.success('Copied !');
};

export const decimalFormatter = (currency: string | number) => {
  return currency.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
};

export const formatCurrency = (num: number, fractionDigits = 6) => {
  let [left, right] = num.toFixed(fractionDigits).split('.');
  // number following by group 3 number then end
  left = decimalFormatter(left || 0);
  right = right?.replace(/0+$/, '');
  if (right) {
    left += `.${right}`;
  }
  return left;
};

export const is1155 = (assetDetail: IAssetDetail) => {
  return assetDetail?.version === 2;
};

export const reportedErrorAuthenticated = (text?: string) => {
  return toast.error(text ?? 'You must login to continue!');
};

export const checkSizeBeforeUpload = (file: File, maxSize: number = 20) => {
  if (!file) {
    toast.error(`Invalid file.`);
    return true;
  }

  if (file.size / 1024 / 1024 > maxSize) {
    toast.error(`File size exceeds maximum limit ${maxSize} MB.`);
    return true;
  }
  return false;
};

// https://data.airight.io//midjourney/lkVt71Uj8fC2.png
export async function downloadImage(url: string, name: string) {
  fetch(
    url.replace(
      'https://data.airight.io/',
      'https://s3.ap-southeast-1.amazonaws.com/data.airight.io/',
    ),
  )
    .then((resp) => resp.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      // the filename you want
      a.download = name;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    });
}

export const getRoyaltyFromInput = (amount: string) => {
  return parseFloat(amount) * ROYALTY_PERCENT_DIGITS;
};

export const convertRoyaltyFromContract = (amount: number) => {
  return (amount / ROYALTY_PERCENT_DIGITS).toString();
};

export const popupCenter = ({
  url,
  title,
  w,
  h,
  callbackPopupBlock,
}: {
  url: string;
  title: string;
  w: number;
  h: number;
  callbackPopupBlock?: () => void;
}) => {
  const left = screen.width / 2 - w / 2;
  const top = screen.height / 2 - h / 2;
  const newWin = window.open(
    url,
    title,
    `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${top}, left=${left}`,
  );
  if (!newWin || newWin.closed || typeof newWin.closed === 'undefined') {
    if (callbackPopupBlock) callbackPopupBlock();
    else toast.warning('Pop-up Blocker is enabled! Please add this site to your exception list.');
  }
  return newWin;
  // newWin?.close();
};

function dataURLtoFile(dataurl: any, filename: string) {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

const toDataURL = (url: string) =>
  fetch(url)
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        }),
    );

export const convertUrlToFile = async (url: string, fileName: string) => {
  return toDataURL(url).then((dataUrl) => {
    return dataURLtoFile(dataUrl, fileName);
  });
};

export const getEventNameByType = (type: string) => {
  switch (type) {
    case 'buy':
      return 'Bought';
    case 'sell':
      return 'For Sale';
    case 'list':
      return 'Minted';
    case 'lock':
      return 'Locked';
    case 'auction':
      return 'Auctioned';
    default:
      return type;
  }
};

export const getUsdWithToken = ({
  amount,
  tokenId,
  prices,
}: {
  amount: number;
  tokenId: string;
  prices: TokenPrices<string>;
}): number => {
  return amount * (prices[tokenId] ?? 0);
};
export const getAIRIWithToken = ({
  amount,
  tokenId,
  prices,
}: {
  amount: number;
  tokenId: string;
  prices: TokenPrices<string>;
}): number => {
  return amount / (prices[tokenId] ?? 0);
};

export const formatNumber = (number: number, n: number, x?: number, s?: string, c?: string) => {
  const re = `\\d(?=(\\d{${x || 3}})+${n > 0 ? '\\D' : '$'})`;
  const num = number.toFixed(Math.max(0, ~~n));
  return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), `$&${s || ','}`);
};

export const formatCash = (n: number, decimal: number = 2) => {
  if (n < 1e3) return +n.toFixed(decimal);
  if (n >= 1e3 && n < 1e6) return `${+(n / 1e3).toFixed(2)}K`;
  if (n >= 1e6 && n < 1e9) return `${+(n / 1e6).toFixed(2)}M`;
  if (n >= 1e9 && n < 1e12) return `${+(n / 1e9).toFixed(2)}B`;
  if (n >= 1e12) return `${+(n / 1e12).toFixed(1)}T`;
};

export const largeNumber = (value: number, withSign: boolean = true) => {
  let sign = value < 0 ? '-' : '';
  if (withSign) sign = value > 0 ? '+' : value < 0 ? '-' : '';
  const absValue = Math.abs(value);
  if (absValue < 10 ** 6) return sign + (Math.round(absValue * 100) / 100).toLocaleString();
  if (absValue < 10 ** 9)
    return `${sign + (Math.round(absValue / 10 ** 4) / 100).toLocaleString()}M`;
  return `${sign + (Math.round(absValue / 10 ** 7) / 100).toLocaleString()}B`;
};

export function detectBannedWords(prompt: string, extend: string[] = []): string[] {
  const matches: string[] = [];
  [...bannedWords, ...extend].forEach((word) => {
    const regex = new RegExp(`\\b${word}\\b`, 'i');
    if (regex.test(prompt)) {
      matches.push(word);
    }
  });
  if (matches.length > 0) {
    toast.error(`${matches.join(', ')} ${matches.length === 1 ? 'is' : 'are'} banned words!`);
  }
  return matches;
}

export function checkParamExpect(prompt: string): boolean {
  const words = prompt.split(' ');
  const result = words.filter((word) => word.includes('--'));
  const banWord = result.filter((item) => !paramExpectMidjourney.includes(item));
  const resultPass = result.filter((item) => paramExpectMidjourney.includes(item));

  if (prompt.indexOf('--') !== -1) {
    const characterSpecial = prompt
      .substring(prompt.indexOf('--'))
      // eslint-disable-next-line no-useless-escape
      .match(/[`!@#$%^&*()_+\=\[\]{};'"\\|,<>\/?~]/);

    if (characterSpecial && characterSpecial.length > 0) {
      toast.error(`character "${characterSpecial.join(', ')}" cannot be included in params`);
      return true;
    }
  }

  if (banWord.length > 0) {
    toast.error(`${banWord.join(', ')} ${banWord.length === 1 ? 'is' : 'are'} banned words!`);
    return true;
  }

  if (new Set(resultPass).size !== resultPass.length) {
    toast.error('Prompt cannot have identical parameters!');
    return true;
  }

  return false;
}

export function isJSON(str: string) {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
}

export function isJSONReturn(str: string) {
  try {
    const object = JSON.parse(str);
    return object;
  } catch (e) {
    return false;
  }
}

export function formatNumberSocial(number: number = 0): string {
  if (number >= 1000000) {
    return `${(number / 1000000).toFixed(2)}M`;
  }
  if (number >= 1000) {
    return `${(number / 1000).toFixed(2)}K`;
  }
  return number.toString();
}

export function getURL(path: string) {
  const baseURL = typeof window === 'undefined' ? configs.ssoCallback : window.location.origin;
  return new URL(path, baseURL).toString();
}

export function getUnitCurrencyWithDenom(denom: TokenDenom) {
  return match(denom)
    .with(TokenDenom.ORAI, () => 'ORAI')
    .with(TokenDenom.AIRI, () => 'AIRI')
    .with(TokenDenom.CUPI, () => 'CUPI')
    .otherwise(() => 'USD');
}

export const replaceImageURL = (imgUrl: string, hostNameReplace: string, baseUrl: string) => {
  try {
    const url = new URL(imgUrl);
    if (url.hostname !== hostNameReplace) return imgUrl;
    return baseUrl + url.pathname;
  } catch {
    return imgUrl;
  }
};

export function isValidUrl(url: string) {
  try {
    new URL(url);
    return true;
  } catch (err) {
    return false;
  }
}

export function arraymove(arr: Array<any>, fromIndex: number, toIndex: number) {
  const element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
}

export const isUserUsingMobile = () => {
  if (typeof window === 'undefined') return;
  // User agent string method
  let isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  );

  // Screen resolution method
  if (!isMobile) {
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    isMobile = screenWidth < 768 || screenHeight < 768;
  }

  // Touch events method
  if (!isMobile) {
    isMobile = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  }

  return Boolean(isMobile);
};

export const convertJsonFileToStringJson = (file: File, setFileJson: any) => {
  const reader = new FileReader();
  reader.onload = (e: any) => {
    try {
      const json = JSON.parse(e.target.result);
      return setFileJson(json);
    } catch (ex) {
      return setFileJson('json file');
    }
  };
  reader.readAsText(file);
};

export const testPreventSubmitLink = (prompt: string) => {
  if (/http[s]?:\/\/.+/.test(prompt)) {
    toast.error('Links are not allowed in the prompt!');
    return true;
  }
  return false;
};

export const checkActionAigToIcon = (action: string, className?: string) => {
  return match(action)
    .with(
      'GEN_MIDJOURNEY',
      'GEN_CONTROL_NET',
      'GEN_TEXT_TO_IMAGE',
      'GEN_QR_CODE',
      'IMAGE_TO_ANIME',
      () => <CreateArtworkIcon className={className} />,
    )
    .with('MINT', () => <MintArtworkIcon className={className} />)
    .with('LIKE', () => <LikeArtworkIcon className={className} />)
    .with('USER_ADD_REF', 'USER_REF', () => <ReferAccountIcon className={className} />)
    .with('TASK_EARN_CREDIT_LIKE', () => <IconCreditFlashOrange className={className} />)
    .otherwise(() => <EarnAigProfileIcon className={className} />);
};

export const checkActionAigToTitle = (action: string) => {
  return match(action)
    .with(
      'GEN_MIDJOURNEY',
      'GEN_CONTROL_NET',
      'GEN_TEXT_TO_IMAGE',
      'GEN_QR_CODE',
      'IMAGE_TO_ANIME',
      () => 'Create an artwork',
    )
    .with('MINT', () => 'Mint an artwork')
    .with('LIKE', () => 'Like an artwork')
    .with('TASK_EARN_CREDIT_LIKE', () => 'Like to earn credit')
    .with('USER_ADD_REF', 'USER_REF', () => 'Refer a friend')
    .otherwise(() => '');
};

export const isRivFile = (url: string) => {
  if (!url) return false;
  return url.slice(-3) === 'riv';
};

export const isAsset1155 = (version: number) => version === 2;

export function getContainedSize(img: HTMLImageElement) {
  if (!img) return [0, 0];
  const ratio = img.naturalWidth / img.naturalHeight;
  let width = img.height * ratio;
  let height = img.height;
  if (width > img.width) {
    width = img.width;
    height = img.width / ratio;
  }
  return [width, height];
}

export const getDimensionImage = (img: HTMLImageElement): { width: number; height: number } => {
  if (!img)
    return {
      width: 0,
      height: 0,
    };
  return {
    width: img.naturalWidth,
    height: img.naturalHeight,
  };
};

export const comparisonObject = (arg1: any, arg2: any) => {
  return JSON.stringify(arg1) === JSON.stringify(arg2);
};

export const formatDateCountdown = (
  days: number,
  hours: number,
  minutes: number,
  seconds: number,
) => {
  const formattedDays = days < 10 ? `0${days}` : `${days}`;
  const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
  const formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;

  return {
    days: formattedDays,
    hours: formattedHours,
    minutes: formattedMinutes,
    seconds: formattedSeconds,
  };
};

export const isEnoughFee = (balanceOrai: number) => balanceOrai * 10 ** -6 >= 0.01;

export const getImageDimensions = async (file: File) => {
  const img = new Image();
  img.src = URL.createObjectURL(file);
  await img.decode();
  const width = img.width;
  const height = img.height;
  return {
    width,
    height,
  };
};
export class ValidateFieldError {
  errors: string[];

  msgErrorsValidation: MsgErrorsValidation;

  constructor() {
    this.msgErrorsValidation = msgErrorsValidation;
    this.errors = [];
  }

  pushError(error: string) {
    this.errors.push(error);
  }

  checkRequire(field: any, fieldName: string) {
    if (!field) {
      const err = this.msgErrorsValidation[ValidationKey.NOT_EMPTY].replace('_', fieldName);
      this.errors.push(err);
    }
  }

  checkRequirePrompt(field: any) {
    if (!field) {
      const err = this.msgErrorsValidation[ValidationKey.PROMPT_REQUIRE];
      this.errors.push(err);
    }
  }

  checkRequireFile(field: any) {
    if (!field) {
      const err = this.msgErrorsValidation[ValidationKey.FILE_REQUIRE];
      this.errors.push(err);
    }
  }

  checkRequireVideo(field: any) {
    if (!field) {
      const err = this.msgErrorsValidation[ValidationKey.VIDEO_REQUIRE];
      this.errors.push(err);
    }
  }

  getListError() {
    return this.errors;
  }

  cleanErrors() {
    this.errors = [];
  }
}

export const getUrlDonate = (
  address: string,
  id?: number | string,
  type?: ArtworkDonateType,
  isOwallet?: boolean,
) => {
  const accessToken =
    typeof window !== 'undefined' ? cachedLocalStorage.getAllWithExpiry('token') : null;
  const refreshToken =
    typeof window !== 'undefined' ? cachedLocalStorage.getAllWithExpiry('refreshToken') : null;

  let url = `${SIDEBAR_MENU_LINK.marketplace}/donate?address=${address}`;
  if (id && type) {
    url += `&id=${id}&type=${type}`;
  }
  if (accessToken && refreshToken) {
    url += `&token=${accessToken.value}&token_expiry=${accessToken.expiry}&refresh_token=${refreshToken.value}&refresh_token_expiry=${refreshToken.expiry}&isowallet=${isOwallet}`;
  }
  return url;
};

export const isVideoByUrl = (url: string) => {
  const VIDEO_TYPES = ['mp4', '3gp', 'ogg'];
  const urlExtension = url.split('.');
  const urlType = urlExtension[urlExtension.length - 1];
  const isVideoURL = !!urlType && VIDEO_TYPES.includes(urlType);
  return isVideoURL;
};

export const censorCircular = (obj: any): string => {
  const seen = new WeakSet<any>();
  return JSON.stringify(obj, (_key: string, value: any) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return ''; // Remove circular references
      }
      seen.add(value);
    }
    return value;
  });
};
