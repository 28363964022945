'use client';

import { Web3Provider } from '@ethersproject/providers';
import { NextUIProvider } from '@nextui-org/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Web3ReactProvider } from '@web3-react/core';
import type { ReactNode } from 'react';
import { useState } from 'react';
import type { TypeOptions } from 'react-toastify';
import { ToastContainer, Zoom } from 'react-toastify';
import { match } from 'ts-pattern';

import { ToastErrorIcon, ToastSuccessIcon, ToastWarnIcon } from '@/assets';
import useGetWidth from '@/hooks/useGetWidth';
import LoadingBarPage from '@/layouts/loadingPage/LoadingBarPage';
import Keplr from '@/libs/keplr';
import Wasm from '@/libs/wasm';
import { LayoutProvider } from '@/provider/LayoutProvider';
import { RouterProvider } from '@/provider/RouterProvider';
import { SocketProvider } from '@/provider/SocketProvider';
import { UserProvider } from '@/provider/UserProvider';
import mixpanel from 'mixpanel-browser';
import { HistoryProvider } from '@/provider/HistoryProvider';

const queryClientOptions = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
};

interface Props {
  children: ReactNode;
}

export const ClientProvider = ({ children }: Props) => {
  const [queryClient] = useState(() => new QueryClient(queryClientOptions));
  const widthScreen = useGetWidth();

  if (typeof window !== 'undefined') {
    // global Wasm
    window.Wasm = new Wasm();
    // global Keplr
    window.Keplr = new Keplr();
  }

  const generateIconToastContainer = (type: TypeOptions) =>
    match(type)
      .with('success', () => <ToastSuccessIcon />)
      .with('warning', () => <ToastWarnIcon />)
      .with('error', () => <ToastErrorIcon />)
      .otherwise(() => <></>);

  const getLibrary = (provider: any) => {
    const library = new Web3Provider(provider);
    library.pollingInterval = 12000;
    return library;
  };

  mixpanel.init('f62efeb263cbe1d53d00ca4677ce43a6', {
    debug: true,
    track_pageview: true,
    persistence: 'localStorage',
  });

  return (
    <UserProvider>
      <Web3ReactProvider getLibrary={getLibrary}>
        <LayoutProvider>
          <QueryClientProvider client={queryClient}>
            <RouterProvider>
              <SocketProvider>
                <HistoryProvider>
                  <NextUIProvider>
                    {/* <PromoDiscord /> */}
                    <LoadingBarPage />
                    <>{children}</>
                    <ToastContainer
                      icon={({ type }) => generateIconToastContainer(type)}
                      closeButton={false}
                      position={widthScreen > 767 ? 'top-right' : 'top-center'}
                      autoClose={3000}
                      hideProgressBar
                      transition={Zoom}
                    />
                  </NextUIProvider>
                </HistoryProvider>
              </SocketProvider>
            </RouterProvider>
          </QueryClientProvider>
        </LayoutProvider>
      </Web3ReactProvider>
    </UserProvider>
  );
};
