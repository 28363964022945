'use client';

import mixpanel from 'mixpanel-browser';
// import { usePathname } from 'next/navigation';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { configs } from '@/configs';
import cachedLocalStorage from '@/libs/localStorage';
import { api, handleRefetchToken } from '@/services';
import { getUserProfile, updateAvatarProfile } from '@/services/provider';
import { convertUrlToFile, popupCenter } from '@/utils';
import storageFunction from '@/utils/storage';
import { AvatarDecor } from '@/app/shop/content/useFetch';

export const AVATAR_DEFAULT = [
  'https://data.airight.io/home/avatar-default/boy-1.png',
  'https://data.airight.io/home/avatar-default/boy-2.png',
  'https://data.airight.io/home/avatar-default/boy-3.png',
  'https://data.airight.io/home/avatar-default/boy-4.png',
  'https://data.airight.io/home/avatar-default/boy-5.png',
  'https://data.airight.io/home/avatar-default/boy-6.png',
  'https://data.airight.io/home/avatar-default/boy-7.png',
  'https://data.airight.io/home/avatar-default/boy-8.png',
  'https://data.airight.io/home/avatar-default/girl-1.png',
  'https://data.airight.io/home/avatar-default/girl-2.png',
  'https://data.airight.io/home/avatar-default/girl-3.png',
  'https://data.airight.io/home/avatar-default/girl-4.png',
  'https://data.airight.io/home/avatar-default/girl-5.png',
  'https://data.airight.io/home/avatar-default/girl-6.png',
  'https://data.airight.io/home/avatar-default/girl-7.png',
  'https://data.airight.io/home/avatar-default/girl-8.png',
];

type UserProviderProps = {
  children: React.ReactNode;
};
type UsedAddRef = {
  username: string;
  publicAddress: string;
  picture: string;
  refCode: string;
};

export interface WalletState {
  id: number | null | undefined;
  username: string;
  publicAddress: string;
  picture: string;
  cover?: string;
  childKey: string | null;
  description: '';
  email?: string;
  isPlus?: boolean;
  refCode?: string;
  usedRef?: UsedAddRef | null;
  credit: number;
  sVerify?: string;
  isFilterNsfw?: boolean;
  avatarDecoration: AvatarDecor | null;
  isEmailVerified: boolean;
}

const infoUser =
  typeof window !== 'undefined' ? cachedLocalStorage.getWithExpiry('infoUser') ?? '' : '';

const {
  picture,
  username,
  publicAddress,
  id,
  isOwallet,
  email,
  cover,
  isPlus,
  refCode,
  usedRef,
  credit,
  sVerify,
  avatarDecoration,
  isEmailVerified,
} = infoUser;

export const initialStateWallet: WalletState = {
  id,
  username: username ?? 'Unnamed',
  publicAddress: publicAddress ?? '0x',
  picture: picture ?? '',
  cover: cover ?? '',
  childKey: null,
  description: '',
  email: email ?? '',
  isPlus,
  refCode,
  usedRef,
  credit,
  sVerify,
  avatarDecoration,
  isEmailVerified: isEmailVerified ?? false,
};

export type UserProviderState = {
  isOwallet?: boolean;
  user: WalletState;
  accessToken: string;
  refreshToken: string;
};

type UserProviderContextType = {
  auth: UserProviderState;
  setAuth: React.Dispatch<React.SetStateAction<UserProviderState>>;
  isLogin: boolean;
  logout: () => void;
  isLoginClient: boolean;
};

export const initialUserProviderState: UserProviderState = {
  isOwallet,
  accessToken: '',
  refreshToken: '',
  user: initialStateWallet,
};

const UserProviderContext = createContext<UserProviderContextType>({
  auth: initialUserProviderState,
  setAuth: () => {},
  isLogin: false,
  logout: () => {},
  isLoginClient: false,
});

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const accessToken =
    typeof window !== 'undefined' ? cachedLocalStorage.getWithExpiry('token') ?? '' : '';
  const refreshToken =
    typeof window !== 'undefined' ? cachedLocalStorage.getWithExpiry('refreshToken') ?? '' : '';

  const { saveInfoUser } = storageFunction;

  const [auth, setAuth] = useState<UserProviderState>({
    ...initialUserProviderState,
    accessToken,
    refreshToken,
  });
  const { deleteToken, deleteInfoUser } = storageFunction;
  const [isLogin, setIsLogin] = useState<boolean>(true);
  const isLoginClient = !!auth.accessToken;

  const handleGetInfoUser = async () => {
    const res = await getUserProfile();
    const info = res.data;
    if (info) {
      setAuth((prev) => ({
        ...prev,
        user: {
          ...prev.user,
          id: info.id,
          username: info.username,
          picture: info.picture,
          publicAddress: info.publicAddress,
          description: info.description ?? '',
          email: info?.email ?? '',
          refCode: info.refCode,
          sVerify: info.sVerify,
          avatarDecoration: info.avatarDecoration,
          isEmailVerified: info.isEmailVerified,
          usedRef: {
            username: info.usedRef?.username,
            publicAddress: info.usedRef?.publicAddress,
            picture: info.usedRef?.picture,
            refCode: info.usedRef?.refCode,
          },
        },
      }));
    }
  };

  const handleUploadAvatarDefault = async () => {
    const avatarLinkRandom = AVATAR_DEFAULT[Math.floor(Math.random()*AVATAR_DEFAULT.length)];
    const file = await convertUrlToFile(avatarLinkRandom || '', 'avatar');
    const formData = new FormData();
    formData.append('picture', file, 'profile');
    const resUser = await updateAvatarProfile(formData);
    if (resUser.data?.picture) {
      setAuth({
        ...auth,
        user: {
          ...auth?.user,
          picture: resUser.data.picture,
        },
      });
    }
  }

  //init info from BE
  useEffect(() => {
    if (isLoginClient) handleGetInfoUser();
  }, [isLoginClient]);

  //set avatar default if null
  useEffect(() => {
    if (!auth.user.picture && isLoginClient) handleUploadAvatarDefault();
  }, [isLoginClient, auth.user.picture])

  //cached storage
  useEffect(() => {
    saveInfoUser({
      credit: auth.user.credit,
      email: auth.user.email,
      id: auth.user.id ?? 0,
      isOwallet: auth.isOwallet ?? true,
      isPlus: auth.user.isPlus,
      picture: auth.user.picture,
      publicAddress: auth.user.publicAddress,
      refCode: auth.user.refCode,
      sVerify: auth.user.sVerify,
      usedRef: auth.user.usedRef,
      username: auth.user.username,
      avatarDecoration: auth.user.avatarDecoration,
      isEmailVerified: auth.user.isEmailVerified,
    });
  }, [auth]);

  const logout = () => {
    deleteToken();
    deleteInfoUser();
    if (!auth.isOwallet) {
      popupCenter({
        url: `${configs.ssoUrl}/logout`,
        title: 'Logout',
        h: 100,
        w: 200,
      });
    }
    setAuth({
      user: {
        id: null,
        username: 'Unnamed',
        publicAddress: '0x',
        picture: '',
        childKey: null,
        description: '',
        isPlus: false,
        refCode: '',
        credit: 0,
        sVerify: '',
        avatarDecoration: null,
        isEmailVerified: false,
        usedRef: {
          username: 'Unnamed',
          publicAddress: '0x',
          picture: '',
          refCode: '',
        },
      },
      accessToken: '',
      refreshToken: '',
      isOwallet: true,
    });
    delete api.defaults.headers.common.Authorization;
  };

  const handleUpdateAfterRefeshToken = async (accessToken: string, refreshToken: string) => {
    const result = await getUserProfile();
    const info = result.data;
    if (info && accessToken) {
      setAuth({
        ...auth,
        user: {
          ...auth.user,
          id: info.id,
          username: info.username,
          picture: info.picture,
          publicAddress: info.publicAddress,
          description: info.description ?? '',
          email: info?.email ?? '',
          refCode: info.refCode,
          sVerify: info.sVerify,
          avatarDecoration: info.avatarDecoration,
        },
        accessToken,
        refreshToken,
      });
    }
  };
  const contextValue: UserProviderContextType = useMemo(() => {
    return {
      auth,
      setAuth,
      isLogin,
      logout,
      isLoginClient,
    };
  }, [auth, setAuth, isLogin, logout]);

  const handleRefetchAccessToken = useCallback(async () => {
    const { deleteToken } = storageFunction;
    try {
      if (
        !cachedLocalStorage.getWithExpiry('token') &&
        cachedLocalStorage.getWithExpiry('refreshToken')
      ) {
        const res = await handleRefetchToken();
        if (res) handleUpdateAfterRefeshToken(res.accessToken, res.refreshToken);
      }
    } catch (error) {
      deleteToken();
    }
  }, [auth.accessToken]);

  useEffect(() => {
    setIsLogin(!!refreshToken);
  }, [auth.refreshToken]);

  useEffect(() => {
    handleRefetchAccessToken();
  }, []);

  useEffect(() => {
    window.addEventListener('focus', handleRefetchAccessToken, false);
    return window.removeEventListener('focus', () => {});
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const storageEventHandler = () => {
        const accessToken = cachedLocalStorage.getWithExpiry('token') ?? '';
        const refreshToken = cachedLocalStorage.getWithExpiry('refreshToken');
        api.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
        if (accessToken && refreshToken) {
          setAuth({ ...auth, accessToken, refreshToken });
        }
      };
      window.addEventListener('custom-storage-event-name', storageEventHandler, false);
    }
    return window.removeEventListener('custom-storage-event-name', () => {});
  }, []);

  useEffect(() => {
    const userId = auth.user.id;
    mixpanel.identify(userId ? userId.toString() : 'free_user');
  }, [auth.user.id]);

  return (
    <UserProviderContext.Provider value={contextValue}>{children}</UserProviderContext.Provider>
  );
};

export const useAuth = () => useContext(UserProviderContext);
