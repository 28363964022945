export const msgErrorsValidation = {
  prompt_require: 'Please enter prompt.',
  file_require: 'Please upload image.',
  video_require: 'Please upload video.',
  not_empty: '_ is required.',
};

export enum ValidationKey {
  PROMPT_REQUIRE = 'prompt_require',
  FILE_REQUIRE = 'file_require',
  VIDEO_REQUIRE = 'video_require',
  NOT_EMPTY = 'not_empty',
}

export type MsgErrorsValidation = typeof msgErrorsValidation;
