import React from 'react';

const MaxLengthInput: React.FC<{
  length: number;
  max: number;
  className?: string;
}> = ({ length, max, className }) => {
  return (
    <span className={`text-[12px] text-gray-400 ${className}`}>
      {length}/{max}
    </span>
  );
};

export default MaxLengthInput;
