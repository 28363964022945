import { api } from '@/services';

export interface IGetAssetWithAddress {
  address: string;
  pageSize: number;
  offset: number;
  sort?: string;
  search?: string;
}
export const getAssetWithAddress = async ({
  address,
  pageSize,
  offset,
  search = '',
  sort = 'DESC',
}: IGetAssetWithAddress) => {
  const res = api.get(
    `/assets?size=${pageSize}&offset=${offset}${`&filter=${encodeURIComponent(
      `{"accountAddress":"${address}","nftStatuses":[2], "name": "${search}"}`,
    )}&sort=${encodeURIComponent(`{"createdAt":"${sort}"}`)}`}`,
  );
  return res;
};

export const getAssets = async (size: number, offset: number, sort?: string, filter?: string) => {
  const res = await api.get('/assets', {
    params: { size, offset, sort, filter },
  });

  return res;
};

export const getAssetWithId = async (id: string | number) => {
  const res = await api.get(`/assets/${id}`);
  return res;
};

export interface IUpdateAssetById {
  id: string | number;
  data: {
    name: string;
    description: string;
    modelId: number;
  };
}
export const updateAssetById = async (arg: IUpdateAssetById) => {
  return await api.patch(`/assets/${arg.id}`, arg.data);
};

export interface IUpdateAssetTagsById {
  id: string | number;
  data: {
    tags: { content: string }[];
  };
}

export const updateAssetTagsById = async (arg: IUpdateAssetTagsById) => {
  return api.post(`/assets/${arg.id}/tags`, arg.data);
};
export interface IGetProcessingAsset {
  size: number;
  offset: number;
  sort?: string;
  filter?: string;
}
export const getProcessingAsset = async ({ size, offset, sort, filter }: IGetProcessingAsset) => {
  return api.get(`/processing-assets?size=${size}&offset=${offset}&sort=${sort}&filter=${filter}`);
};

export const getProcessingAssetById = async (id: string | number) => {
  return await api.get(`/processing-assets/${id}`);
};

export interface IUpdateProcessingAssetData {
  name?: string;
  description?: string;
  royalty?: number;
  price?: number;
}
export interface IUpdateProcessingAsset {
  id: string | number;
  data: IUpdateProcessingAssetData;
}

export const updateProcessingAssetById = async (arg: IUpdateProcessingAsset) => {
  return api.post(`/processing-assets/details/${arg.id}`, arg.data);
};

export interface IGenerateFileOrigin {
  prompt?: string;
  assetUrl: string;
  modelId: number;
  mimeType?: string;
  email?: string;
  [key: string]: any;
}

export const generateFileOrigin = async (bodyFormData: FormData) => {
  return api.post('/processing-assets/generate', bodyFormData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const getAssetTransactionHistory = async (
  assetId: number,
  pageSize: number,
  offset: number,
) => {
  const url = `/assets/${assetId}/transactions?size=${pageSize}&offset=${offset}`;
  return api.get(url);
};

export const getAssetPriceHistoryChart = async (
  assetId: number,
  pageSize: number,
  offset: number,
) => {
  const url = `/assets/${assetId}/transactions?size=${pageSize}&offset=${offset}&filter={"events":["buy"]}`;
  return api.get(url);
};

export interface IGetAssetWithCollection {
  collectionId: string | number;
  pageSize: number;
  offset: number;
  sort?: string;
  filter?: {
    name?: string;
  };
}
export const getAssetByCollectionId = async ({
  collectionId,
  pageSize,
  offset,
  filter,
  sort,
}: IGetAssetWithCollection) => {
  const filterUrl = filter
    ? `${encodeURIComponent(JSON.stringify({ ...filter, collectionIds: [collectionId] }))}`
    : `${encodeURIComponent(`{"collectionIds":[${collectionId}]}`)}`;

  return api.get(`/assets?size=${pageSize}&offset=${offset}${`&filter=${filterUrl}&sort=${sort}`}`);
};

export const increaseViewNft = async (body: {
  addrCollection: string;
  tokenId: string;
  network: string;
}) => {
  const res = await api.post('/view/nft/increase-view', body);
  return res.data;
};

export const getViewNft = async (body: {
  addrCollection: string;
  tokenId: string;
  network: string;
}) => {
  const res = await api.post('/view/nft/total-view', body);
  return res.data;
};

export const likeAsset = async (id: number) => {
  const res = await api.post(`/assets/${id}/like`);
  return res.data;
};

export const unLikeAsset = async (id: number) => {
  const res = await api.post(`/assets/${id}/unlike`);
  return res.data;
};

export const postDownloadAsset = async (id: number) => {
  const res = await api.post(`/assets/${id}/download`);
  return res.data;
};

export const getProcessingAssetByIds = async (ids: number[]) => {
  const idsCustom = JSON.stringify(ids);
  const res = await api.get(`/processing-assets/ids?ids=${idsCustom}`);
  return res.data;
};

export const getArtworkTrending = async () => {
  return api.get('/trending/artwork');
};

export const getArtworkRank = async (params: { size: number; offset: number; filter: string }) => {
  return api.get('trending/top-artwork', { params });
};

export interface IMultiMintAsset {
  id: number;
  details: {
    name?: string;
    description?: string;
    royalty: number;
  };
}
export const multiMintAssets = async (body: IMultiMintAsset[]) => {
  return api.post('/processing-assets/multi-mint', { multiMint: body });
};

export const getMultiAssetIdForMint = async (body: IMultiMintAsset[]) => {
  return api.post('/processing-assets/mint/multi-mint-use-wallet', { multiMint: body });
};

export interface IUpdateMultiTags {
  assetId: number;
  tags: { content: string }[];
}
export const updateMultiTags = async (body: IUpdateMultiTags[]) => {
  return api.post('/assets/update-multi-tags', body);
};
export const actionHideArtProcessing = async (id: number) => {
  return api.post(`/processing-assets/hide-art/${id}`);
};

export const updateAssetAfterMint = async (
  id: number,
  body: { name: string; description: string },
) => {
  return api.patch(`/assets/${id}`, body);
};

export const getAssetAsks = async (id: number, size: number, offset: number) => {
  return api.get(`/assets/${id}/asks?size=${size}&offset=${offset}`);
};

export const getHistoryGenerate = async ({ size, offset }: { size: number; offset: number }) => {
  const res = await api.get(`/processing-assets/history?size=${size}&offset=${offset}`);
  return res.data;
};

export const getAssetOwners = async (id: number, size: number, offset: number) => {
  return api.get(`/assets/${id}/owners?size=${size}&offset=${offset}`);
};

export const retryProcessingAsset = async (id: number) => {
  return api.post(`/processing-assets/retry/${id}`);
};

export const changeOwnerArtFree = async (ids: number[], creatorId: number) => {
  return api.post(`/processing-assets/change-owner-art-free`, {
    ids,
    creator: creatorId,
  });
};
