export enum ListSource {
  ai_model = 'ai_model',
  ai_model_carousel = 'ai_model_carousel',
  ai_model_detail = 'ai_model_detail',
  trending_nft = 'trending_nft',
  first_trending_nft = 'first_trending_nft',
  marketplace_nft = 'marketplace_nft',
  marketplace_nft_masonry = 'marketplace_nft_masonry',
  auction_nft = 'auction_nft',
  profile = 'profile',
  collection = 'collection',
  detail_nft = 'detail_nft',
  preview_mint_nft = 'preview_mint_nft',
  similarity_checking = 'similarity_checking',
  nft_checkout_modal = 'nft_checkout_modal',
  staking_nft_modal = 'staking_nft_modal',
  ntf_airdrop = 'ntf_airdrop',
  landing_nft = 'landing_nft',
  processing_asset = 'processing_asset',
  multiple_mint_modal = 'multiple_mint_modal',
  report_nft = 'report_nft',
  profile_prompt = 'profile_prom',
}

export const generateTypeEnable = {
  CANNY: 'canny',
  LINE_ART: 'lineart',
  SOFT_EDGE: 'soft_edge',
  POSE: 'pose',
  DEPTH: 'depth',
  NORMAL: 'normal',
};

export enum COMMENT_TYPE_TINY {
  POST = 1,
  TEMPLATE = 2,
  WORKFLOW = 3,
  MODEL = 4,
}

export type keyGenerateTypeEnable = keyof typeof generateTypeEnable;

export const baseModel = {
  generate_type: 'canny',
  file: null,
  prompt: '',
  steps: 30,
  cfg_scale: 7.5,
  control_weight: 1,
  lora: [],
  seed: -1,
};

export const baseModelQRCode = {
  file: null,
  prompt: '',
  steps: 30,
  cfg_scale: 7.5,
  control_weight: 1.6,
  lora: [],
  seed: -1,
};

export const baseModelAnime = {
  file: null,
  control_weight: 1,
  model: 'meinapastel_v6Pastel',
  modelId: 0,
  prompt: '',
  lora: [],
  negative_prompt: '',
  width: 512,
  height: 512,
  batch_size: 1,
};

export const baseModelLivePortrait = {
  image: null,
  video: null,
  modelId: 0,
};

export const baseModelSticker = {
  file: null,
  prompt: '',
  modelId: 0,
};

export const baseModelIpAdapter = {
  image: null,
  refImage: null,
  prompt: '',
  negative_prompt: '',
  modelId: 0,
  controlType: 'strong style and composition',
  control_module: 'scribble',
};

export const baseDalle3 = {
  prompt: '',
  modelId: 0,
  model: 'dall-e-3',
  quality: 'standard',
  size: '1024x1024',
  style: 'vivid',
};

export const baseSDXL3 = {
  prompt: '',
  modelId: 0,
  model: 'sd3',
  size: '1:1',
  negative_prompt: '',
  seed: 0,
};

export const baseDalle2 = {
  prompt: '',
  modelId: 0,
  model: 'dall-e-2',
  size: '1024x1024',
};

export const baseText2Model = {
  prompt: '',
  modelId: 0,
  model: [
    'CounterfeitV30_v30',
    'mistoonAnime_v20',
    'raemumix_v51',
    'arthemyComics_v50',
    'MoyouArtificial_v10502g',
  ][Math.floor(Math.random() * 5)],
  steps: '35',
  cfg_scale: '7.5',
  lora: [],
  batch_size: 1,
  width: 512,
  height: 512,
  enable_hires: false,
  adetailer: false,
  sampler: 'DPM++ 2M',
  size: '512x512',
  vae: '',
  hr_upscaler: 'Latent',
  hr_scale_by: 1.5,
  hr_denoising_strength: 0.55,
  hr_width: 0,
  hr_height: 0,
  hr_steps: 20,
  embedding: '',
  negative_prompt: '',
  seed: -1,
};

export const baseSdxlModel = {
  prompt: '',
  modelId: 0,
  model: 'dreamshaperXL_lightningDPMSDE',
  steps: '6',
  cfg_scale: '2',
  lora: [],
  batch_size: 1,
  width: 1024,
  height: 1024,
  enable_hires: false,
  sampler: 'DPM++ SDE',
  size: '1024x1024',
  vae: '',
  hr_upscaler: 'Latent',
  hr_scale_by: 1.5,
  hr_denoising_strength: 0.55,
  hr_width: 0,
  hr_height: 0,
  hr_steps: 20,
  embedding: '',
  negative_prompt: '',
  seed: -1,
};

export const baseInpaintModel = {
  prompt: '',
  image: null,
  modelId: 0,
  model: 'dreamshaper_8Inpainting',
  steps: '25',
  cfg_scale: '7.5',
  batch_size: 1,
  negative_prompt: '',
  sampler: 'DPM++ 2M',
  inpaint_mask_mode: 'Inpaint masked',
  inpaint_mask_content: 'original',
  inpaint_area: 'Whole picture',
  vae: '',
  embedding: '',
  lora: [],
  denoising_strength: 0.75,
  seed: -1,
};

export const vae = [
  'Automatic',
  'vae-ft-mse-840000-ema-pruned.ckpt',
  'kl-f8-anime.ckpt',
  'kl-f8-anime2.ckpt',
  'YOZORA.vae.pt',
  'orangemix.vae.pt',
  'blessed2.vae.pt',
  'animevae.pt',
  'ClearVAE.safetensors',
];

export const upscaler = [
  'Latent',
  'Latent (antialiased)',
  'Latent (bicubic)',
  'Latent (bicubic antialiased)',
  'Latent (nearest)',
  'Latent (nearest-exact)',
  'None',
  'Lanczos',
  'Nearest',
  '4x-UltraSharp',
  'LDSR',
  'R-ESRGAN 4x+',
  'R-ESRGAN 4x+ Anime6B',
  'ScuNET GAN',
  'ScuNET PSNR',
  'SwinIR 4x',
];

export const embedding = [
  'face_yolov8s',
  'face_yolov8m',
  'face_yolov8n',
  'hand_yolov8s.pt',
  'person_yolov8n-seg.pt',
  'person_yolov8m-seg.pt',
  'person_yolov8s-seg.pt',
  'mediapipe_face_full',
  'DPM++ 2M SDE',
  'Restart',
  'DPM++ 2M SDE Exponential',
  'DPM++ 2M SDE Heun',
  'DPM++ 2M SDE Heun Exponential',
];

export const controlTypeIPAdapter = [
  'ease in',
  'ease out',
  'ease in-out',
  'reverse in-out',
  'weak input',
  'weak output',
  'weak middle',
  'strong middle',
  'style transfer',
  'composition',
  'strong style transfer',
  'style and composition',
  'strong style and composition',
];

export const controlModuleIPAdapter = [
  'canny',
  'scribble',
  'lineart',
  'faceid',
  'tile',
  'brightness',
];

export const listSampler = [
  'DPM++ 2M',
  'DPM++ SDE',
  'DPM++ 2M SDE Exponential',
  'DPM++ 2M SDE',
  'Euler a',
  'Euler',
  'LMS',
  'Heun',
  'DPM2',
  'DPM2 a',
  'DPM++ 2S a',
  'DPM++ 2M',
  'DPM++ SDE',
  'DPM++ 2M SDE',
  'DPM++ 2M SDE Heun',
  'DPM++ 2M SDE Heun',
  'DPM++ 2M SDE Heun Exponential',
  'DPM++ 3M SDE',
  'DPM++ 3M SDE',
  'DPM++ 3M SDE Exponential',
  'DPM fast',
  'DPM adaptive',
  'LMS',
  'DPM2',
  'DPM2 a',
  'DPM++ 2S a',
  'Restart',
  'DDIM',
  'PLMS',
  'UniPC',
];

export const inpaintMaskMode = ['Inpaint masked', 'Inpaint not masked'];

export const inpaintMaskContent = ['original', 'fill', 'latent noise', 'latent nothing'];

export const inpaintArea = ['Whole picture', 'Only masked'];

export const onlyOneBatchSizeInpaint = ['Whole picture'];

export const sizeOfImage = [
  { label: '512x512', value: 'low' },
  { label: '768x768', value: 'medium' },
  { label: '1024x1024', value: 'high' },
];
export const sizeOfImageDallE2 = [
  { text: '256x256', ratio: '[1:1]', shape: 'Square' },
  { text: '512x512', ratio: '[1:1]', shape: 'Square' },
  { text: '1024x1024', ratio: '[1:1]', shape: 'Square' },
];
export const sizeOfImageDallE3 = [
  { text: '1024x1024', ratio: '[1:1]', shape: 'Square' },
  { text: '1792x1024', ratio: '[16:9]', shape: 'Portrait' },
  { text: '1024x1792', ratio: '[9:16]', shape: 'Landscape' },
];
export const sizeOfImageChatGenerator = [
  { text: '771x1024', width: 771, height: 1024, ratio: '[3:4]', shape: 'Portrait' },
  { text: '1024x771', width: 1024, height: 771, ratio: '[4:3]', shape: 'Landscape' },
  { text: '1024x1024', width: 1024, height: 1024, ratio: '[1:1]', shape: 'Square' },
  { text: 'custom size', width: null, height: null, ratio: 'custom', shape: 'Custom' },
];

export const livePortraitConfig = [
  {
    fieldName: 'image',
    fieldDisplayName: 'Image',
    nodeId: '',
    type: 'image',
    item: {},
    nodeName: '',
  },
  {
    fieldName: 'video',
    fieldDisplayName: 'Video',
    nodeId: '',
    type: 'video',
    item: {},
    nodeName: '',
    suggest: 'Video must be cropped to focus on the face',
  },
];

export const fluxConfig = [
  {
    fieldName: 'prompt',
    fieldDisplayName: 'Prompt',
    nodeId: '',
    type: 'text',
    item: {
      placeholder: 'Write a detailed description of how you want your artwork to look like.',
    },
    nodeName: '',
  },
  {
    fieldName: 'size',
    fieldDisplayName: 'Size',
    nodeId: '',
    type: 'size',
    item: {},
    nodeName: '',
  },
];

export const sizeText2Image = [
  { text: '512x512', width: 512, height: 512, ratio: '[1:1]', shape: 'Square' },
  { text: '771x1024', width: 771, height: 1024, ratio: '[3:4]', shape: 'Portrait' },
  { text: '683x1024', width: 683, height: 1024, ratio: '[2:3]', shape: 'Portrait' },
  { text: '575x1024', width: 575, height: 1024, ratio: '[9:16]', shape: 'Portrait' },
  { text: '1024x771', width: 1024, height: 771, ratio: '[4:3]', shape: 'Landscape' },
  { text: '1024x683', width: 1024, height: 683, ratio: '[3:2]', shape: 'Landscape' },
  { text: '1024x575', width: 1024, height: 575, ratio: '[16:9]', shape: 'Landscape' },
  { text: 'custom size', width: null, height: null, ratio: 'custom', shape: 'Custom' },
];

export const sizeSdxl3 = [
  { text: '', width: null, height: null, ratio: '[1:1]', shape: 'Square', ratio_value: '1:1' },
  { text: '', width: null, height: null, ratio: '[16:9]', shape: 'Landscape', ratio_value: '16:9' },
  { text: '', width: null, height: null, ratio: '[21:9]', shape: 'Landscape', ratio_value: '21:9' },
  { text: '', width: null, height: null, ratio: '[2:3]', shape: 'Portrait', ratio_value: '2:3' },
  { text: '', width: null, height: null, ratio: '[3:2]', shape: 'Landscape', ratio_value: '3:2' },
  { text: '', width: null, height: null, ratio: '[4:5]', shape: 'Portrait', ratio_value: '4:5' },
  { text: '', width: null, height: null, ratio: '[5:4]', shape: 'Landscape', ratio_value: '5:4' },
  { text: '', width: null, height: null, ratio: '[9:21]', shape: 'Portrait', ratio_value: '9:21' },
  { text: '', width: null, height: null, ratio: '[9:16]', shape: 'Portrait', ratio_value: '9:16' },
];

export const sizeSdxl = [
  { text: '1024x1024', width: 1024, height: 1024, ratio: '[1:1]', shape: 'Square' },
  { text: '771x1024', width: 771, height: 1024, ratio: '[3:4]', shape: 'Portrait' },
  { text: '683x1024', width: 683, height: 1024, ratio: '[2:3]', shape: 'Portrait' },
  { text: '575x1024', width: 575, height: 1024, ratio: '[9:16]', shape: 'Portrait' },
  { text: '1024x771', width: 1024, height: 771, ratio: '[4:3]', shape: 'Landscape' },
  { text: '1024x683', width: 1024, height: 683, ratio: '[3:2]', shape: 'Landscape' },
  { text: '1024x575', width: 1024, height: 575, ratio: '[16:9]', shape: 'Landscape' },
  { text: 'custom size', width: null, height: null, ratio: 'custom', shape: 'Custom' },
];
export type SizeOfModel = typeof sizeText2Image;
export type RatioOfModel = typeof sizeSdxl3;

export const typeGlobal = ['video', 'image', 'audio'];

export const loraSdxl = [
  {
    lora_type: 'SDXL',
    alias: 'nft_collection',
    description: '<p>First release version</p>',
    download_url: [],
    file_name: '',
    name: 'aiRight LoRA PFPs 001',
    original_url: 'https://civitai.com/models/186400/rich-mens-rug-pull-nft-art-style',
    preview: [
      'https://data.airight.io/lora/images/ee16e917-4dbf-4a2e-9fac-6f0b1e20f415.png',
      'https://data.airight.io/lora/images/1260f422-c99f-4b08-99b9-19dfa95db135.png',
    ],
    trigger_words: ['nft style'],
  },
];

export const typeAll = [
  'png',
  'jpg',
  'jpeg',
  'mp3',
  'mp4',
  'audio/mpeg',
  'audio/wav',
  'audio/ogg',
  'm4v',
  'bmp',
  'gif',
  'avi',
  'mpg',
  'json',
];

export const sizeOfFile = {
  audio: 20,
  image: 30,
  video: 200,
  application: 20,
};

export const regexIsEmail = new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$');

export const actionsBurnAiri = {
  phase1: {
    name: 'phase1',
    action: 'BURN_AIG_RECEIVE_AIRI_PHASE1',
  },
  phase2: {
    name: 'phase2',
    action: 'BURN_AIG_RECEIVE_AIRI_PHASE2',
  },
  phase3: {
    name: 'phase3',
    action: 'BURN_AIG_RECEIVE_AIRI_PHASE3',
  },
};

export const MIXPANEL_TRACK = {
  GENERATE_OPEN: 'Generate Open',
  GENERATE_RUN: 'Generate Run',
  UPGRADE_PRICING: 'Upgrade Pricing',
  PUSHLISH_ART: 'Pushlish Art',
  SUBSCRIBE_NOW: 'Subscribe Now',
  REMIX: 'REMIX',
  DOWNLOAD_TEMPLATE: 'Download Template',
};
