'use client';

import type { QueryKey, UseMutationOptions, UseQueryOptions } from '@tanstack/react-query';
import { useMutation, useQuery } from '@tanstack/react-query';

export const useSMutation = <
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
>(
  args: UseMutationOptions<TData, TError, TVariables, TContext>,
) => {
  const { onError: onErrorCustom, onSuccess: onSuccessCustom, ...rest } = args;
  const context = useMutation({
    ...rest,
    onError: (...args) => {
      if (onErrorCustom) {
        onErrorCustom(...args);
      }
      // todo common
    },
    onSuccess: (...args) => {
      if (onSuccessCustom) {
        onSuccessCustom(...args);
      }
      // todo common
    },
  });
  return { ...context };
};

export const useSQuery = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  args: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
) => {
  const context = useQuery({ ...args, retry: false });
  return { ...context };
};
