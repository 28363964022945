'use client';

import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import type { DefaultEventsMap } from 'socket.io/dist/typed-events';
import type { Socket } from 'socket.io-client';
import { io } from 'socket.io-client';

import { ToastEarn } from '@/components';
import { configs } from '@/configs';
import { useAuth } from '@/provider/UserProvider';
import { isJSON } from '@/utils';

type SocketProviderProps = {
  children: React.ReactNode;
};

export interface SocketState {
  socket: Socket<DefaultEventsMap, DefaultEventsMap> | undefined;
  setSocket: React.Dispatch<
    React.SetStateAction<Socket<DefaultEventsMap, DefaultEventsMap> | undefined>
  >;
}

export const initialState: SocketState = {
  socket: undefined,
  setSocket: () => {},
};

const SocketProviderContext = createContext(initialState);

export const SocketProvider: React.FC<SocketProviderProps> = ({ children }) => {
  // const router = useRouter();
  const [socket, setSocket] = useState<Socket>();
  const { auth, setAuth } = useAuth();

  const updateUserCredit = (point: number) => {
    setAuth((prev) => ({ ...prev, user: { ...prev.user, credit: prev.user.credit + point } }));
  };

  useEffect(() => {
    if (auth.accessToken && !socket?.connected) {
      const socket = io(configs.socketUrl || '', {
        path: '/airight-social-service/socket.io',
        transports: ['websocket'],
        rejectUnauthorized: false,
        agent: false,
      });
      socket.connect();
      socket.on('connect', () => {
        setSocket(socket);
      });

      socket.on('error', (error: any) => {
        console.log(error, 'error');
      });

      // socket.on(`earn-aig-${auth.user.publicAddress}`, async (message: any) => {
      //   if (message) {
      //     if (isJSON(message)) {
      //       toast(<ToastEarn point={JSON.parse(message)} />, {
      //         className: 'toast-earn-aig',
      //         autoClose: 5000,
      //         onClick: () => router.push('/leaderboard'),
      //       });
      //     }
      //   }
      // });
      socket.on(`earn-credit-${auth.user.publicAddress}`, async (message: any) => {
        if (message) {
          if (isJSON(message)) {
            updateUserCredit(JSON.parse(message)?.point || 0);
            toast(
              <ToastEarn
                point={JSON.parse(message)}
                type="credit"
                iconClassName="scale-[1.8] mr-3"
              />,
              {
                className: 'toast-earn-aig',
                autoClose: 5000,
              },
            );
          }
        }
      });
    } else if (socket?.connected) {
      // socket.off(`earn-aig-${auth.user.publicAddress}`);
      socket.off(`earn-credit-${auth.user.publicAddress}`);
      socket.disconnect();
      setSocket(undefined);
    }
  }, [auth.accessToken]);

  const contextValue: SocketState = useMemo(() => {
    return {
      socket,
      setSocket,
    };
  }, [socket, setSocket]);

  return (
    <SocketProviderContext.Provider value={contextValue}>{children}</SocketProviderContext.Provider>
  );
};

export const useSocket = () => useContext(SocketProviderContext);
